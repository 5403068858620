import React, {Component} from "react";
import {Card, Col, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import loadDate from "../../util/datesToString";

class HeaderOrder extends Component {
    render() {
        let order = this.props.order;
        return(
            <Card bg={this.props.color} text={"white"}>
                <Card.Body>
                    <Row>
                        <FormGroup as={Col} sm={3} controlId={"validationDate"}>
                            <FormLabel>Fecha de creación:</FormLabel>
                            <FormControl readOnly defaultValue={loadDate(order.date)} className={"text-center"}/>
                        </FormGroup>
                        <FormGroup as={Col} sm={3} controlId={"validationStatus"}>
                            <FormLabel>Estado:</FormLabel>
                            <FormSelect
                                name="status"
                                value={order.status}
                                onChange={(event) => this.changeStatusOrder(event)}
                            >
                                {this.getStatus()}
                            </FormSelect>
                        </FormGroup>
                        {this.props.loads?(
                            <FormGroup as={Col} sm={3} controlId={"validationPeriod"}>
                                <FormLabel>P. Cargue:</FormLabel>
                                <FormSelect
                                    name="load_id"
                                    defaultValue={order.load_id}
                                    onChange={(event) => this.changeLoadOrder(event)}
                                    disabled={(!(order.status === "active" || order.status === "process"))}
                                    value={order.load_id}
                                >
                                    {this.getLoads()}
                                </FormSelect>
                            </FormGroup>
                        ): null}
                        <FormGroup as={Col} sm={3} controlId={"validationPeriod"}>
                            <FormLabel>Forma de Pago:</FormLabel>
                            <FormSelect
                                value={order.payment_method}
                                onChange={(event) => this.changePaymentMethodOrder(event)}
                                disabled={(!(order.status === "active" || order.status === "process"))}
                            >
                                <option value={""}></option>
                                <option value={"CONTADO"}>CONTADO</option>
                                <option value={"30DIAS"}>30 DIAS</option>
                                <option value={"15DIAS"}>15 DIAS</option>
                                <option value={"8DIAS"}>8 DIAS</option>
                                <option value={"OTRA"}>OTRA</option>
                            </FormSelect>
                        </FormGroup>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    changeStatusOrder = (event) => {
        let value = event.target.value;
        let order = this.props.order;
        order.status = value;
        this.props.setOrder(order, "Cambió estado a " + value);
    }

    changePaymentMethodOrder = (event) => {
        let value = event.target.value;
        let order = this.props.order;
        order.payment_method = value;
        this.props.setOrder(order, "Actualizó PM a " + value);
    }

    changeLoadOrder = (event) => {
        let value = event.target.value;
        let order = this.props.order;
        let load = ""
        for(let i in this.props.loads){
            let load1 = this.props.loads[i]
            if(load1.id === value){
                load = load1.name
            }
        }
        if (value === "") {
            order.status = "active";
        }
        order.load = load;
        order.load_id = value;
        this.props.setOrder(order, "Actualizó cargue: " + load + " ID:"+value);
    }

    getStatus = () => {
        let status = []
        let actual_status = this.props.order.status;
        let option_actual_status = this.props.status[actual_status];
        status.push(<option key={actual_status} value={actual_status}>{option_actual_status['name']}</option>);

        for(let state_index in option_actual_status['next']){
            let state = option_actual_status['next'][state_index]
            let option = this.props.status[state];
            if(!(!this.props.order.load && state==="process") && !option['hidden'] ){
                status.push(<option key={state} value={state}>{option['name']}</option>)
            }
        }
        return status;
    }

    getLoads = () => {
        let loads = []
        loads.push(<option value="">Seleccionar</option>)
        if(this.props.order.load_id){
            loads.push(<option value={this.props.order.load_id}>{this.props.order.load}</option>)
        }
        for(let load in this.props.loads){
            let option = this.props.loads[load]
            if(!this.props.order.load_id || this.props.order.load_id !== option.id){
                loads.push(<option key={load} value={option.id}>{option.name}</option>)
            }
        }
        return loads;
    }
}
export default HeaderOrder;