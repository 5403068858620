import React, {Component} from "react";
import {Alert, Button, Card, OverlayTrigger, Placeholder, Table, Tooltip} from "react-bootstrap";
import {GrAdd} from "react-icons/gr";
import {NavLink} from "react-router-dom";
import {FiEdit} from "react-icons/fi";

class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            categories: [],
        }
    }

    componentDidMount() {
        this.downloadActiveCategories()
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Categorías
                    <NavLink to={"/admin/new/category"}>
                        <Button className={"float-end"} size="sm" variant={"primary"}>
                            <i><GrAdd/></i> Crear Nueva
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.ready ? (
                        <>
                            {this.state.error ? (
                                <Alert variant={"danger"}>
                                    <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                    <p>
                                        {this.state.error}
                                    </p>
                                    <hr/>
                                    <Button onClick={() => this.downloadActiveCategories()} variant={"dark"}>
                                        Intentar nuevamente
                                    </Button>
                                </Alert>
                            ) : (
                                <div>
                                    <h5>Todas las categorías de productos</h5>
                                    <Table striped hover responsive>
                                        <thead>
                                        <tr className={"text-center"}>
                                            <th>Categoría</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.categories.map((category, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{category.name}</td>
                                                    <td className={"text-center"}>
                                                        <OverlayTrigger
                                                        key={{index}+"tooltip"}
                                                        placement={"bottom"}
                                                        overlay={
                                                            <Tooltip id={"tooltip-1"}>
                                                                Ver Categoría
                                                            </Tooltip>
                                                        }
                                                        >
                                                            <NavLink to={"/admin/edit/category/"+category.id}>
                                                                <Button size={"sm"}>
                                                                    <i><FiEdit/></i>
                                                                </Button>
                                                            </NavLink>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>

                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={12} />
                                <Placeholder xs={10} />
                                <Placeholder xs={10} />
                                <Placeholder xs={12} />
                                <Placeholder xs={10} />
                                <Placeholder xs={10} />
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
            </Card>
        );
    }

    downloadActiveCategories = () => {
        this.props.loadGet("/categories?type=product", this.loadContent, this.loadError);
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            categories: data
        })
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }
}

export default Categories;
