import {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {connect} from "react-redux";
import CommentOrder from "./CommentOrder";

class CommentsOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ""
        }
    }

    render() {
        let order = this.props.order;
        return (
            <Card>
                <Card.Header>
                    Comentarios:
                </Card.Header>
                <Card.Body>
                    {order.comments && order.comments.length > 0 ? (
                        <Row className={"mb-1"}>
                            <Col>
                                {order.comments.map((comment, key) => {
                                    return (<CommentOrder {...this.props}
                                                          comment={comment}
                                                          setMessage={this.setMessage}
                                    />);
                                })}
                            </Col>
                        </Row>
                    ) : null}
                    <Row className={"mb-3"}>
                        <Col>
                            <FormGroup controlId="exampleForm.ControlTextarea1">
                                <FormLabel>Agregar comentario: </FormLabel>
                                <FormControl as="textarea" rows={3} value={this.state.message}
                                             onChange={(event) => {
                                                 this.setState({
                                                     message: event.target.value
                                                 });
                                             }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.message ? (
                        <Row className={"text-end"}>
                            <Col>
                                <Button onClick={() => this.saveNewComments()}
                                        disabled={!this.state.message}
                                >
                                    Agregar Comentario
                                </Button>
                            </Col>
                        </Row>
                    ) : null}
                </Card.Body>
            </Card>
        )
    }

    saveNewComments = () => {
        let comment = {
            message: this.state.message,
            author: this.props.user.name ? this.props.user.name : this.props.user.email
        }
        let order = this.props.order;
        if (order.comments) {
            order.comments.push(comment);
        } else {
            order.comments = [comment]
        }
        this.setState({
            message: ""
        });
        this.props.setOrder(order, "Creó comentario: "+this.state.message);
    }

    setMessage = (comment_ref, new_message) => {
        let order = this.props.order;
        if (order.comments) {
            for (let comment of order.comments) {
                if (comment.date === comment_ref.date && comment.message === comment_ref.message
                    && comment.author === comment_ref.author) {
                    comment.message = new_message;
                    comment.was_updated = true;
                    comment.date = 0;
                    comment.author = this.props.user.name ? this.props.user.name : this.props.user.email
                    break;
                }
            }
        }
        this.props.setOrder(order, "Actualizó comentario a " + new_message);
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, {})(CommentsOrder);