import React, {Component} from "react";
import {clearRefreshToken, clearToken, saveRefreshToken, saveToken, setUser} from "../initializers/actions";
import {connect} from "react-redux";
import {Card, Container, Spinner} from "react-bootstrap";
import axios from "axios";
import {Redirect} from "react-router-dom";
import properties from "../properties";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            isError: false,
            error: "",
            isErrorRole: false,
        }
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search)
        this.loadingToken(params.get("code"))
    }

    render() {
        if (this.state.isLogin) {
            return (
                <Redirect
                    to={"/admin/home"}
                />
            )
        } else {
            return (
                <div //style={{backgroundColor: "#909090"}}
                    >
                    {this.state.isError ? (
                        <Container>
                            <Card className={"text-center"} border={"danger"}>
                                <Card.Header>
                                    Error
                                </Card.Header>
                                <Card.Body>
                                    <h4><b>Algo salio mal </b>: {this.state.error}</h4>
                                    <Card.Link
                                        href={properties.url_login}
                                    >
                                        Si eres administrador, Inicie sesión aquí
                                    </Card.Link>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    {this.state.isErrorRole ? (
                                        <Card.Link
                                            href={"https://pedidos.lidercamargo.com"}
                                            className={"btn btn-primary"}
                                        >
                                            Si eres cliente, Inicia sesión aquí
                                        </Card.Link>
                                    ): null}
                                </Card.Body>
                                <Card.Footer></Card.Footer>
                            </Card>
                        </Container>
                    ):(
                        <div
                            style={{height:"100vh", verticalAlign: "center"}}
                            className={"text-center"}
                        >
                            <p>Cargando...</p>
                            <h1>Por favor espere!</h1>
                            <h4>Estamos preparando todo...</h4>
                            <Spinner animation={"grow"} style={{top: "50%", position: "absolute"}} variant={"success"}/>
                        </div>
                    )}
                </div>
            );
        }
    }

    loadingToken = (code) => {
        if (code === "" || code == null) {
            this.setState({
                isError: true,
                error: "No existe el parametro Code"
            })
        } else {
            axios({
                url: properties.url_token,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': properties.basic,
                },
                params: {
                    code
                },
                data: ''
            }).then(
                (response) => {
                    if (response.data) {
                        let data = response.data
                        this.props.saveToken(data.access_token)
                        this.props.saveRefreshToken(data.refresh_token)
                        this.getUserInfo()
                    } else {
                        this.setState({
                            isError: true,
                            error: JSON.stringify(response)
                        })
                    }
                }
            ).catch((error) => {
                if (error.response) {
                    this.setState({
                        isError: true,
                        error: JSON.stringify(error.response.data)
                    });
                } else {
                    this.setState({
                        isError: true,
                        error: "Error no Identificado"
                    })
                }

            })
        }
    }

    getUserInfo = () => {
        axios({
            url: properties.url_user_inf,
            method: "GET",
            headers: {
                'Authorization': "Bearer " + this.props.token
            }
        }).then((response) => {
            if (response.data) {
                if(response.data['custom:role_v2'] && response.data['custom:role_v2'] === "admin"){
                    this.props.setUser(response.data)
                    setTimeout( () => this.setState({
                        isLogin: true
                    }), 200);
                }
                else {
                    this.setState({
                        isError: true,
                        isErrorRole: true,
                        error: "No tienes acceso a este sitio, por favor ir a lidercamargo.com"
                    })
                }
            } else {
                this.setState({
                    isError: true,
                    error: JSON.stringify(response)
                })
            }
        }).catch((error) => {
            if (error.response) {
                this.setState({
                    isError: true,
                    error: JSON.stringify(error.response.data)
                });
            } else {
                this.setState({
                    isError: true,
                    error: "Error no Identificado"
                })
            }
        })
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        user: state.user,
        refresh: state.refresh
    }
}

const mapDispatchToProps = {
    saveToken,
    saveRefreshToken,
    setUser,
    clearToken,
    clearRefreshToken
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);