import Orders from "./Orders";

const PROCESS = "PROCESS"
class ProcessOrders extends Orders {
    loadTableOrders = () => {
        this.props.loadGet("/orders?status=process", this.loadContent, this.loadError)
    }

    loadColor = () => {
        return "warning"
    }

    loadColorTable = () => {
        return this.loadColor()
    }

    loadType = () => {
        return PROCESS
    }
}
export default ProcessOrders;