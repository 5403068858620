import {View, Text} from "@react-pdf/renderer";
import React from "react";

function TablePDF({data, columns, title, style, headerStyle, rowStyle, cellStyle, headerCellStyle, children}) {
  return (
      <View style={{
          display: "table",
          width: "auto",
          borderStyle: "solid",
          borderWidth: 0,
          borderTopWidth: 1,
          borderRightWidth: 0,
          borderBottomWidth: 0,
      }}>
            <View style={{
                flexDirection: "row",
                borderBottomWidth: 1,
                borderLeftWidth: 1,
                backgroundColor: "#E4E4E4",
            }}>
                {columns.map((column, index) => (
                    <View key={index} style={{
                        width: column.width,
                        borderRightWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#383636",
                        padding: 4,
                        fontSize: 9
                    }}>
                        <Text style={[headerCellStyle, {fontWeight: "bold"}]}>{column.title}</Text>
                    </View>
                ))}
            </View>
            {data.map((row, index) => (
                <View key={index} style={{
                    flexDirection: "row",
                    borderBottomWidth: 1,
                    borderLeftWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#000000",
                }}>
                    {columns.map((column, index) => (
                        <View key={index} style={{
                            width: column.width,
                            borderRightWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#000000",
                            padding: 1,
                            paddingHorizontal: 3,
                            fontSize: 10
                        }}>
                            <Text style={[cellStyle, column.cellStyle, {textOverflow: "ellipsis", maxLines: 1}]}>{row[column.field]}</Text>
                        </View>
                    ))}
                </View>
            ))}
      </View>
  );
}

export default TablePDF;
