import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import store from './initializers/store';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/light-bootstrap-dashboard-pro-react.css"
import "./assets/css/demo.css"
import "./assets/css/pe-icon-7-stroke.css"

import App from './App';


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
