import React, {Component} from "react";
import {Alert, Button, Card, Placeholder} from "react-bootstrap";
import PeriodCardDasboard from "./PeriodCardDasboard";

class PeriodDashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            periods: [],
        }
    }

    componentDidMount() {
        this.downloadActivePeriods()
    }

    render() {
        if(this.state.ready){
            if(this.state.error){
                return (
                    <Alert variant={"danger"}>
                        <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                        <p>
                            {this.state.error}
                        </p>
                        <hr/>
                        <Button variant={"dark"}
                                onClick={
                                    () => this.downloadActivePeriods()
                                }
                        >
                            Intentar nuevamente
                        </Button>
                    </Alert>
                )
            } else{
                return this.state.periods.map( (period) => {
                    return(
                        <PeriodCardDasboard {...this.props} period={period}/>
                    )
                })
            }
        }
        else {
            return (
                <>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={12} size={"lg"} bg={"success"}/>
                    </Placeholder>
                    <Placeholder as={Card.Text} animation={"glow"}>
                        <Placeholder xs={12} bg={"success"}/>
                        <Placeholder xs={12} bg={"success"}/>
                        <Placeholder xs={12} bg={"success"}/>
                    </Placeholder>
                </>
            )
        }
    }

    downloadActivePeriods = () => {
        if(this.props.type === "sales"){
            this.props.loadGet("/sale_periods?status=active", this.loadContent, this.loadError)
        }
        if(this.props.type === "loads"){
            this.props.loadGet("/loads?status=active", this.loadContent, this.loadError)
        }
        if(this.props.type === "purchases"){
            this.props.loadGet("/purchase_periods?status=active", this.loadContent, this.loadError)
        }

    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            periods: data
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }
}
export default PeriodDashboard;