import {FieldArray, Form, Formik} from "formik";
import * as Yup from "yup";
import {object} from "yup";
import {Button, Card, Col, FormCheck, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import ImageSource from "./ImageSource";
import {AiFillDelete, AiFillSave, AiOutlineClose} from "react-icons/ai";
import {GrAdd} from "react-icons/gr";
import React, {useEffect} from "react";
import CategoriesInProduct from "./CategoriesInProducts";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    code: Yup.number().required('Requedido'),
    weight: Yup.number(),
    cost: Yup.number(),
    iva: Yup.number().min(0, "Minimo 0").max(100, "Maximo 100"),
    iva_mandatory: Yup.boolean(),
    amount: Yup.number().min(0, "Minimo 0"),
    reserved: Yup.number(),
    unity: Yup.string(),
    sales_list: Yup.array().of(
        object({
            value: Yup.number().min(0, "Minimo 0"),
        })
    ),
    sales_list_default: Yup.number(),
    providers: Yup.array(),
    url_image: Yup.string(),
    categories: Yup.array(),
});

function FormEditProduct(props) {
    const product = props.product;
    const [ivaMandatory, setIvaMandatory] = React.useState(product.iva_mandatory);

    if (product) {
        return (
            <Formik
                initialValues={{
                    name: product.name ? product.name.toUpperCase() : '',
                    code: product.code ? product.code : 0,
                    weight: product.weight ? product.weight : 0,
                    cost: product.cost ? product.cost : 0,
                    iva: product.iva ? product.iva : 0,
                    iva_mandatory: product.iva_mandatory ? product.iva_mandatory : false,
                    amount: product.amount ? product.amount : 0,
                    reserved: product.reserved ? product.reserved : 0,
                    unity: product.unity ? product.unity : '',
                    sales_list_default: product.sales_list_default ? product.sales_list_default : 0,
                    sales_list: product.sales_list ? product.sales_list : [],
                    providers: product.providers ? product.providers : [],
                    status: product.status ? product.status : "active",
                    in_store: product.in_store ? product.in_store : false,
                    url_image: product.url_image ? product.url_image : "",
                    categories: product.categories ? product.categories : [],
                }}
                validationSchema={schema}
                onSubmit={values => {
                    props.saveProduct(values, product.id);
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row className={"mb-3"}>
                            <FormGroup as={Col} md={"3"} controlId="validationCode">
                                <FormLabel>Código:</FormLabel>
                                <FormControl
                                    type="number"
                                    name="code"
                                    value={values.code}
                                    onChange={handleChange}
                                    isInvalid={!!errors.code}
                                    placeholder={"Código"}
                                    style={{textAlign: 'right'}}
                                    disabled={true}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.code}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup as={Col} md={"6"} controlId="validationName">
                                <FormLabel>Nombre del producto:</FormLabel>
                                <FormControl
                                    type="text"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.name}
                                    placeholder={"Nombre"}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.name}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup as={Col} md={"3"} controlId="isInStore">
                                <FormLabel>Disponible en tienda:</FormLabel>
                                <FormCheck
                                    type="switch"
                                    name={"in_store"}
                                    label="Disponible"
                                    checked={props.inStore}
                                    value={values.in_store}
                                    onChange={(event) => {
                                        values.in_store = !props.inStore;
                                        props.setInStore(!props.inStore);
                                    }}
                                />
                            </FormGroup>
                        </Row>
                        <Row className={"mb-3"}>
                            <FormGroup as={Col} md={"2"} controlId="validationWeight">
                                <FormLabel>Peso (kg):</FormLabel>
                                <FormControl
                                    type="number"
                                    name="weight"
                                    value={values.weight}
                                    onChange={handleChange}
                                    isInvalid={!!errors.weight}
                                    placeholder={"0.0"}
                                    style={{textAlign: 'right'}}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.weight}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup as={Col} md={"3"} controlId="validationCost">
                                <FormLabel>Costo antes de IVA:</FormLabel>
                                <FormControl
                                    type="number"
                                    name="cost"
                                    value={values.cost}
                                    onChange={handleChange}
                                    isInvalid={!!errors.cost}
                                    placeholder={"1900"}
                                    style={{textAlign: 'right'}}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.cost}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup as={Col} md={"2"} controlId="validationIva">
                                <FormLabel>Iva:</FormLabel>
                                <FormControl
                                    type="number"
                                    name="iva"
                                    value={values.iva}
                                    onChange={handleChange}
                                    isInvalid={!!errors.iva}
                                    placeholder={"19"}
                                    style={{textAlign: 'right'}}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.iva}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup as={Col} md={"2"} controlId="validationIvaMandatory">
                                <FormLabel>Iva Obligatorio:</FormLabel>
                                <FormCheck
                                    type="switch"
                                    name={"iva_mandatory"}
                                    label="Obligatorio"
                                    checked={ivaMandatory}
                                    value={values.iva_mandatory}
                                    onChange={(event) => {
                                        values.iva_mandatory = !ivaMandatory;
                                        setIvaMandatory(!ivaMandatory);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup as={Col} md={"3"} controlId="validationStatus">
                                <FormLabel>Estado:</FormLabel>
                                <FormSelect
                                    name="status"
                                    value={values.status}
                                    onChange={handleChange}
                                    isInvalid={!!errors.status}
                                >
                                    <option value={"active"}>Activo</option>
                                    <option value={"inactive"}>Inactivo</option>
                                </FormSelect>
                                <FormControl.Feedback type="invalid">
                                    {errors.status}
                                </FormControl.Feedback>
                            </FormGroup>
                        </Row>
                        <Row className={"mb-3"}>
                            <FormGroup as={Col} md={"2"} controlId="validationAmount">
                                <FormLabel>Reservados:</FormLabel>
                                <FormControl
                                    type="number"
                                    name="reserved"
                                    value={values.reserved}
                                    onChange={handleChange}
                                    isInvalid={!!errors.reserved}
                                    placeholder={"0"}
                                    style={{textAlign: 'right'}}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup as={Col} md={"3"} controlId="validationAmount">
                                <FormLabel>Inventario:</FormLabel>
                                <FormControl
                                    type="number"
                                    name="amount"
                                    value={values.amount}
                                    onChange={handleChange}
                                    isInvalid={!!errors.amount}
                                    placeholder={"0"}
                                    style={{textAlign: 'right'}}
                                />
                                <FormControl.Feedback type="invalid">
                                    {errors.amount}
                                </FormControl.Feedback>
                            </FormGroup>
                            <FormGroup as={Col} md={"2"} controlId="validationAvailable">
                                <FormLabel>Disponibles:</FormLabel>
                                <FormControl
                                    type="number"
                                    value={props.available}
                                    placeholder={"0"}
                                    style={{textAlign: 'right'}}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroup as={Col} md={"2"} controlId="buttonViewReserved">
                                <FormLabel>Pedidos:</FormLabel>
                                <Row>
                                    <Col>
                                        <Button disabled={values.reserved <= 0} style={{width: "100%"}}
                                                onClick={() => props.loadOrdersAndShowModal()}
                                        >
                                            Ver Pedidos
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup as={Col} md={"3"} controlId="validationUnity">
                                <FormLabel>Unidad de medida:</FormLabel>
                                <FormSelect
                                    name="unity"
                                    value={values.unity}
                                    onChange={handleChange}
                                    isInvalid={!!errors.unity}
                                    defaultValue={"Unidades"}
                                >
                                    <option>Unidades</option>
                                    <option>Kilogramos</option>
                                    <option>Libra</option>
                                    <option>Arroba</option>
                                    <option>Bulto</option>
                                    <option>Cajas</option>
                                    <option>Bloques</option>
                                    <option>Rollos</option>
                                </FormSelect>
                                <FormControl.Feedback type="invalid">
                                    {errors.unity}
                                </FormControl.Feedback>
                            </FormGroup>
                        </Row>
                        <Row className={"mb-3"}>
                            <FormGroup as={Col}>
                                <Row>
                                    <FormLabel>Categorías:</FormLabel>
                                </Row>
                                <CategoriesInProduct {...props} categories={product.categories}
                                                     setCategories={(categories) => {
                                                         values.categories = categories;
                                                     }}
                                />
                            </FormGroup>
                        </Row>
                        {props.inStore ? (
                            <Row className={"mb-3"}>
                                <FormLabel><b>Foto del producto:</b></FormLabel>
                                <ImageSource
                                    image={props.imageSource}
                                    setSourceImage={image => props.setSourceImage(image)}
                                    setBaseImage={image_base => props.setBaseImage(image_base)}
                                    size={512}
                                    preload={false}
                                />
                                {props.imageBase && !props.hasImageSaved ? (
                                    <Card className={"shadow p-3 bg-white rounded"} style={{marginBottom: '0px'}}>
                                        <Button variant={"success"}
                                                onClick={() => props.sendImageURL()}
                                                disabled={!props.readyPost}
                                        >
                                            Guardar Imagen
                                        </Button>
                                    </Card>
                                ) : null}
                            </Row>
                        ) : null}
                        <Row className={"mb-3"}>
                            <FormLabel><b>Lista de ventas: </b>
                                <small className={"text-muted"}>
                                    *Se debe ingresar el valor base del producto antes de IVA
                                </small>
                            </FormLabel>
                            <FieldArray name={"sales_list"}>
                                {({insert, remove, push}) => (
                                    <div>
                                        {values.sales_list.length > 0 && values.sales_list.map(
                                            (sale_item, key) => (
                                                <Row className={"align-items-center border-top"} key={key}>
                                                    <FormLabel column sm={2}>Valor de Venta {key + 1}:</FormLabel>
                                                    <Col sm={3} className={"my-1"}>
                                                        <FormControl
                                                            type={"number"}
                                                            value={values.sales_list[key].value}
                                                            placeholder={"0"}
                                                            name={`sales_list.${key}.value`}
                                                            onChange={handleChange}
                                                            style={{textAlign: 'right'}}
                                                        />
                                                    </Col>
                                                    <Col sm={4}>
                                                        <FormCheck
                                                            type={"radio"}
                                                            label={"Marcar Valor de venta por defecto"}
                                                            value={key}
                                                            onChange={handleChange}
                                                            name={`sales_list_default`}
                                                            defaultChecked={values.sales_list_default === key}
                                                            id={`sales-list-${key}`}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            variant={"outline-danger"}
                                                            onClick={() => remove(key)}
                                                        >
                                                            <i><AiOutlineClose/></i> Quitar valor
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )
                                        )}
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={() => push({value: ''})}
                                                    className={"buttons-with-all-width"}
                                                >
                                                    <i><GrAdd/></i> Agregar valor de venta
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </FieldArray>
                        </Row>
                        <Row className={"mb-3"}>
                            <FormLabel><b>Proveedores:</b></FormLabel>
                            <FieldArray name={"providers"}>
                                {({insert, remove, push}) => (
                                    <div>
                                        {values.providers.length > 0 && values.providers.map(
                                            (provider, key) => (
                                                <Row className={"align-items-center border-top"} key={key}>
                                                    <Col sm={2} className={"my-1"}>
                                                        <FormControl
                                                            type={"text"}
                                                            value={values.providers[key].identification}
                                                            name={`providers.${key}.identification`}
                                                            plaintext={true}
                                                        />
                                                    </Col>
                                                    <Col sm={3} className={"my-1"}>
                                                        <FormControl
                                                            type={"text"}
                                                            value={values.providers[key].name}
                                                            name={`providers.${key}.name`}
                                                            plaintext={true}
                                                        />
                                                    </Col>
                                                    <Col sm={4} className={"my-1"}>
                                                        <FormControl
                                                            type={"text"}
                                                            value={values.providers[key].establishment_name}
                                                            name={`providers.${key}.establishment_name`}
                                                            plaintext={true}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            variant={"outline-danger"}
                                                            onClick={() => {
                                                                remove(key);
                                                                props.removeFromState(provider)
                                                            }}
                                                        >
                                                            <i><AiOutlineClose/></i>Quitar Proveedor
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )
                                        )}
                                        <br/>
                                        <Row>
                                            <Col>
                                                <Button
                                                    onClick={() => props.openModalProvidersSearch(push)}
                                                    className={"buttons-with-all-width"}
                                                >
                                                    <i><GrAdd/></i> Agregar Proveedor
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </FieldArray>
                        </Row>
                        <Row>
                            <Col md={"6"}>
                                <Button type={"submit"} variant={"success"} disabled={!props.readyPost}
                                        className={"buttons-with-all-width"}
                                >
                                    <i>
                                        <AiFillSave/>
                                    </i> Guardar Producto
                                </Button>
                            </Col>
                            <Col md={"6"}>
                                <Button variant={"danger"} className={"buttons-with-all-width"}
                                        onClick={() => props.checkDelete(product)}
                                >
                                    <i><AiFillDelete/></i> Eliminar Producto
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    } else {
        return (
            <p>
                No hay datos disponibles
            </p>
        );
    }
}

export default FormEditProduct;
