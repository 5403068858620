import React, {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, FormSelect, Row, Spinner} from "react-bootstrap";

import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import {AiFillSave} from "react-icons/ai";
import {FiUsers} from "react-icons/fi";
import {NavLink, Redirect} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    last_name: Yup.string().required('Requerido'),
    email: Yup.string().email('Correo Invalido').required('Requerido'),
    identification: Yup.number(),
    password: Yup.string().required("Requerido"),
    role: Yup.string().required("Requerido"),
    //terms: Yup.bool().required().oneOf([true], "Los términos y condiciones deben ser aceptados")
});

class NewUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: null,
            modal: null,
            error: false,
            ready: true
        }
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Usuario Nuevo
                    <NavLink to={"/admin/users"}>
                        <Button className={"float-end"} size="sm" variant={"secondary"}>
                            <i><FiUsers/></i> Todos los usuarios
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.content}
                    <Formik
                        initialValues={{
                            name: '',
                            last_name: '',
                            email: '',
                            identification: '',
                            password: '',
                            role: 'client',
                        }}
                        validationSchema={schema}
                        onSubmit={values => {
                            this.saveUser(values)
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <FormGroup as={Col} md={"6"} controlId="validationName">
                                        <FormLabel>Nombres:</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                            placeholder={"Nombres"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.name}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"6"} controlId="validationLastName">
                                        <FormLabel>Apellidos:</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="last_name"
                                            value={values.last_name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.last_name}
                                            placeholder={"Apellidos"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.last_name}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className="mb-3">
                                    <FormGroup as={Col} md={"6"} controlId="validationEmail">
                                        <FormLabel>Correo Electronico:</FormLabel>
                                        <FormControl
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                            placeholder={"Correo electronico"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.email}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"6"} controlId="validationIdentification">
                                        <FormLabel>Numero de Identificación:</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="identification"
                                            value={values.identification}
                                            onChange={handleChange}
                                            isInvalid={!!errors.identification}
                                            placeholder={"000000000000"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.identification}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className="mb-3">
                                    <FormGroup as={Col} md={"6"} controlId="validationContraseña">
                                        <FormLabel>Contraseña:</FormLabel>
                                        <FormControl
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={!!errors.password}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.password}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"6"} controlId="validationRole">
                                        <FormLabel>Rol:</FormLabel>
                                        <FormSelect
                                            name="role"
                                            value={values.role}
                                            onChange={handleChange}
                                            isInvalid={!!errors.role}
                                        >
                                            <option value={"admin"}>Administrador</option>
                                            <option value={"client"}>Cliente</option>
                                        </FormSelect>
                                        <FormControl.Feedback type="invalid">
                                            {errors.role}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                {
                                    /**
                                     <FormGroup className={"mb-5"}>
                                     <FormCheck
                                     required
                                     name={"terms"}
                                     label={"Acepta términos y condiciones"}
                                     onChange={handleChange}
                                     isInvalid={!!errors.terms}
                                     feedback={errors.terms}
                                     feedbackType="invalid"
                                     id="validationTerms"
                                     />
                                     </FormGroup>
                                     */
                                }
                                <Row className={"mb-3"}>
                                    <Col>
                                        <Button type="submit" variant={"success"} disabled={!this.state.ready}
                                                className={"buttons-with-all-width"}
                                        >
                                            <i>
                                                <AiFillSave/>
                                            </i> Guardar Usuario
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        )
    }

    redirectToEdit = (user) => {
        if (user) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    showConfirm={false}
                    closeOnClickOutside={false}
                >
                    Usuario guardado
                </SweetAlert>
            )
            this.setState({
                content: (<Redirect to={"/admin/edit/users/" + user.id}/>)
            });
        } else {
            this.loadError("Error al crear el usuario")
        }
    };

    loadError = (onerror) => {
        this.setState({ready: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    saveUser = (values) => {
        this.setState({
            ready: false,
            modal: (
                <SweetAlert
                    custom
                    title="Guardando!"
                    showConfirm={false}
                    closeOnClickOutside={false}
                    customIcon={(
                        <div className={"text-center"}>
                            <Spinner animation={"border"} variant={"success"}/>
                        </div>
                    )}
                >
                    Estamos guardando el usuario en la base de datos
                </SweetAlert>
            )
        });
        this.props.loadPost("/users", values, this.redirectToEdit, this.loadError);
    }


    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }
}

export default NewUser;