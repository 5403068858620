import React, {Component} from "react";

import logo2 from "../assets/img/logo192.png"
import avatar from "../assets/img/default-avatar.png"
import HeaderLinks from "./AdminNavbarLinks";
import routes from "../routes";
import {NavLink} from "react-router-dom";

import {setMini} from "../initializers/actions";
import {connect} from "react-redux";
import {Collapse} from "react-bootstrap";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.getCollapseStates(routes),
            openAvatar: false,
            isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
            width: window.innerWidth
        }
    }

    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.name.toLowerCase()]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };

    getCollapseInitialState = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    createLinks = routes => {
        return routes.map(
            (prop, key) => {
                if (prop.redirect) {
                    return null;
                }
                if (prop.hidden) {
                    return null;
                }
                if (prop.collapse) {
                    let st = {}
                    st[prop.name.toLowerCase()] = !this.state[prop.name.toLowerCase()]
                    return (
                        <li className={this.getCollapseInitialState(prop.views) ? "active": ""} key={key}
                            style={{width: '100%'}}
                        >
                            <a
                                href={'#'+prop.name.toLowerCase()}
                                onClick={ event => {
                                    event.preventDefault()
                                    this.setState(st)
                                }}
                                className={"nav-link"}
                                data-bs-toggle="collapse"
                            >
                                <i><prop.icon/></i>
                                <p className={"dropdown-toggle"}>
                                    {prop.name}
                                </p>
                            </a>

                            <Collapse
                                in={this.state[prop.name.toLowerCase()]}
                                id={prop.name.toLowerCase()}
                            >
                                <ul className={"nav collapse"}>
                                    {this.createLinks(prop.views)}
                                </ul>
                            </Collapse>
                        </li>
                    )
                }
                let params = prop.params ? prop.params: "";
                return (
                    <li className={this.activeRoute(prop.layout + prop.path) + " sidebar-item"} key={key}>
                        <NavLink
                            to={prop.layout + prop.path + params }
                            className={"nav-link"}
                            activeClassName={"active"}
                        >
                            {
                                prop.icon ? (
                                    <>
                                        <i><prop.icon/></i> <p>{prop.name}</p>
                                    </>
                                ) : (
                                    <>
                                        <span className="sidebar-mini">{prop.mini}</span>
                                        <span className="sidebar-normal">{prop.name}</span>
                                    </>
                                )
                            }
                        </NavLink>
                    </li>
                )
            }
        )
    }

    componentDidUpdate() {
        if (navigator.platform.indexOf("Win") > -1) {
            setTimeout(() => {
                //ps.update()
            }, 350)
        }
    }

    componentDidMount() {
        this.updateDimensions();
        // add event listener for windows resize
        window.addEventListener("resize", this.updateDimensions.bind(this));
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            /**
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
             */
        }
    }

    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        if (navigator.platform.indexOf("Win") > -1) {
            //ps.destroy();
        }
    }

    render() {
        return (
            <div
                className={"sidebar"}
                data-color={this.props.color}
                data-image={this.props.image}
            >
                {this.props.hasImage ? (
                    <div
                        className={"sidebar-background"}
                        style={{backgroundImage: "url(" + this.props.image + ")"}}
                    />
                ) : ("")}
                <div className={"logo"}>
                    <a
                        href={"/"}
                        className={"simple-text logo-mini"}
                    >
                        <div className={"logo-img"}>
                            <img src={logo2} alt={"Lider Camargo Logo"}/>
                        </div>
                    </a>
                    <a
                        href={"/"}
                        className={"simple-text logo-normal"}
                    >
                        LIDER CAMARGO
                    </a>
                </div>
                <div className={"sidebar-wrapper"}>
                    <div className={"user"}>
                        <div className={"photo"}>
                            <img src={avatar} alt={"Avatar"}/>
                        </div>
                        <div className={"info"}>
                            <NavLink to={"/admin/edit/users/"+this.props.user.username}
                                     onClick={e => {
                                         this.setState({openAvatar: !this.state.openAvatar});
                                     }}
                            >
                                <span>
                                    {this.props.user['custom:name'] ? this.props.user['custom:name']: this.props.user.email}
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    <ul className={"nav"}>
                        {this.createLinks(routes)}
                    </ul>
                    {this.state.width <= 992 ? <HeaderLinks/> : null}
                </div>
            </div>
        );
    }


    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }
}

const mapStateToProps = (state) => {
    return {
        user : state.user,
        mini: state.mini
    }
}

const mapDispatchToProps = {
    setMini
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);