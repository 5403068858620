import {Component} from "react";
import {Button, Card, FormControl} from "react-bootstrap";
import loadDateAndTime from "../../util/dateAndTimeToString";
import {FaPen, FaSave} from "react-icons/fa";

class CommentOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: props.comment?.message,
            is_editing: false
        }
    }

    render() {
        let comment = this.props.comment;
        return (
            <Card className={"mb-2 bg-white rounded"} style={{marginBottom: '0px'}}>
                <Card.Header style={{padding: "0.1rem 1rem"}}>
                    {comment.was_updated? (
                        <small className={"text-muted"}><b>{comment.author}</b> actualizó el {loadDateAndTime(comment.date)}
                        </small>
                        ):(
                        <small className={"text-muted"}><b>{comment.author}</b> comentó el {loadDateAndTime(comment.date)}
                        </small>
                        )}
                    <div className={"float-end"}>
                        {this.state.is_editing ? (
                            <Button size={"sm"} variant={"light"}
                                    onClick={
                                        () => {
                                            this.setState({is_editing: false})
                                            this.props.setMessage(comment, this.state.message);
                                        }
                                    }
                            >
                                <i><FaSave/> Guardar</i>
                            </Button>
                        ) : (
                            <Button size={"sm"} variant={"light"}
                                    onClick={() => this.setState({is_editing: true})}
                            >
                                <i><FaPen/></i>
                            </Button>
                        )}
                    </div>
                </Card.Header>
                <Card.Body style={{padding: "0.5rem 1rem", whiteSpace: "pre-wrap"}}>
                    {this.state.is_editing ? (
                        <FormControl as="textarea" rows={5}
                                     value={this.state.message}
                                     onChange={(event) => {
                                         this.setState({
                                             message: event.target.value
                                         });
                                     }}

                        />
                    ) : (
                        comment.message
                    )}
                </Card.Body>
            </Card>
        )
    }
}

export default CommentOrder;