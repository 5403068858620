import React, {Component} from "react";
import {Card, Col, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import loadDate from "../../util/datesToString";

class HeaderPurchase extends Component{
    render() {
        let purchase = this.props.purchase;
        return(
            <Card>
                <Card.Body>
                    <Row>
                        <FormGroup as={Col} sm={3} controlId={"validationDate"}>
                            <FormLabel>Fecha de creación:</FormLabel>
                            <FormControl readOnly
                                         defaultValue={loadDate(purchase.date)}
                                         className={"text-center"}/>
                        </FormGroup>
                        <FormGroup as={Col} sm={3} controlId={"validationStatus"}>
                            <FormLabel>Estado:</FormLabel>
                            <FormSelect
                                name="status"
                                value={purchase.status}
                                onChange={(event) => this.changeStatusOrder(event)}
                            >
                                {this.getStatus()}
                            </FormSelect>
                        </FormGroup>
                        {this.props.periods ? (
                            <FormGroup as={Col} sm={3} controlId={"validationPeriod"}>
                                <FormLabel>Periodo de Compra:</FormLabel>
                                <FormSelect
                                    name="period_id"
                                    defaultValue={purchase.period_id}
                                    onChange={(event) => this.changePeriodPurchase(event)}
                                    disabled={purchase.status !== "active"}
                                >
                                    {this.getPurchasesPeriodsOptions()}
                                </FormSelect>
                            </FormGroup>
                        ) : null}
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    changePeriodPurchase = (event) => {
        let value = event.target.value;
        let purchase = this.props.purchase;
        let period = ""
        for (let i in this.props.periods) {
            let load1 = this.props.periods[i]
            if (load1.id === value) {
                period = load1.name
            }
        }
        if (value === "") {
            purchase.status = "active";
        }
        purchase.purchase_period = period;
        purchase.period_id = value;
        this.props.setPurchase(purchase);
    }

    getPurchasesPeriodsOptions = () => {
        let loads = []
        loads.push(<option value="">Seleccionar</option>)
        if(this.props.purchase.period_id){
            loads.push(<option value={this.props.purchase.period_id}>{this.props.purchase.purchase_period}</option>)
        }
        for (let load in this.props.periods) {
            let option = this.props.periods[load];
            if(!this.props.purchase.period_id || this.props.purchase.period_id !== option.id){
                loads.push(<option key={load} value={option.id}>{option.name}</option>)
            }
        }
        return loads;
    }

    getStatus = () => {
        let status = []
        let actual_status = this.props.purchase.status;
        let option_actual_status = this.props.status[actual_status];
        status.push(<option key={actual_status} value={actual_status}>{option_actual_status['name']}</option>);

        for(let state_index in option_actual_status['next']){
            let state = option_actual_status['next'][state_index]
            let option = this.props.status[state];
            if(!(!this.props.purchase.period_id && state==="process") && !option['hidden'] ){
                status.push(<option key={state} value={state}>{option['name']}</option>)
            }
        }
        return status;
    }

    changeStatusOrder = (event) => {
        let value = event.target.value;
        let purchase = this.props.purchase;
        purchase.status = value;
        this.props.setPurchase(purchase);
    }
}
export default HeaderPurchase;