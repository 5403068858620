import {Page, Document, Text, View} from "@react-pdf/renderer";
import React from "react";
import TablePDF from "../../util/TablePDF";

function AllPurchasesFromPeriodPDF({orders, name, id}) {
    const total = orders.reduce((total, order) => {
        if (order.status !== "cancelled") {
            return total + order.total;
        }
        return total;
    }, 0);

    const columns = [
        {title: "Código", field: "code", width: '9.5%'},
        {title: "Producto", field: "name", width: '45%'},
        {title: "Cost IvaIncl", field: "price", width: '11%', cellStyle: {textAlign: 'right'}},
        {title: "Iva", field: "iva", width: '4%', cellStyle: {textAlign: 'right'}},
        {title: "Cant", field: "quantity", width: '7.5%', cellStyle: {textAlign: 'right'}},
        {title: "Total IvaIncl", field: "total", width: '13%', cellStyle: {textAlign: 'right'}},
        {title: "Obsers.", field: "", width: '10%'}
    ];

    const date = new Date();

    return (
        <Document title={"Compras " + name} author={"SGP LIDER CAMARGO SAS"}>
            <Page size={"A4"} wrap style={{paddingTop: 35, paddingBottom: 30, paddingHorizontal: 25}}>
                <View fixed>
                    <Text style={{textAlign: 'center', fontSize: 14}}>COMPRAS PERIODO {name.toUpperCase()}</Text>
                    <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5}}>
                        <View style={{flexDirection: 'column'}}>
                            <Text style={{fontWeight: 'thin', fontSize: 10}}>
                                <Text style={{fontWeight: "thin"}}>ID: </Text>
                                <Text style={{fontFamily: 'Helvetica-Bold', color: "blue"}}>{id}</Text>
                            </Text>
                        </View>
                        <View style={{flexDirection: 'column'}}>
                            <Text style={{fontWeight: 'thin', fontSize: 10, textAlign: 'center'}}>
                                Fecha: {date.toLocaleDateString() + " " + date.toLocaleTimeString('es-US')}
                            </Text>
                        </View>
                    </View>
                </View>

                {orders.map((order, index) => {
                    if (order.status !== "cancelled") {

                        const productsPDF = order.products.map(product => {
                            return {
                                code : product.code,
                                name: product.name,
                                quantity: new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.amount),
                                price: "$"+ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.value),
                                iva: new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.iva),
                                total: "$"+ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.value * product.amount)
                            }
                        });

                        return (
                            <View wrap={false} style={{marginBottom: 12}} key={index}>
                                <View style={{
                                    display: "table",
                                    width: "auto",
                                    borderStyle: "solid",
                                    borderTopWidth: 1,
                                    borderLeftWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 0,
                                    backgroundColor: "#cbdcfa",
                                }}>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{flexDirection: 'column', width: '13%', paddingTop: 4, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Proveedor: </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '62%', paddingTop: 4, paddingHorizontal: 4}}>
                                            <Text style={{fontWeight: "thin", fontSize: 10, textOverflow: "ellipsis", maxLines: 1}}>{order.provider.name ? order.provider.name.toUpperCase() : "NO TIENE"}</Text>
                                        </View>

                                        <View style={{flexDirection: 'column', width: '5%',  paddingTop: 4, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Id: </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '20%',  paddingTop: 4, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', color: "red", fontSize: 10, textAlign: "center"}}>{order.id}</Text>
                                        </View>
                                    </View>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{flexDirection: 'column', width: '13%', paddingTop: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Dir/Ciudad:</Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '62%', paddingTop: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontWeight: "thin", fontSize: 10, textOverflow: "ellipsis", maxLines: 1}}>
                                                {order.provider.address} - {order.provider.department ? order.provider.department.toUpperCase() : ""}/
                                                {order.provider.city ? order.provider.city.toUpperCase() : ""}
                                            </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '5%',  paddingTop: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Nit: </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '20%',  paddingTop: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontWeight: "thin", fontSize: 10}}>{order.provider.identification}</Text>
                                        </View>
                                    </View>

                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{flexDirection: 'column', width: '13%', padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Establ:</Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '45%', padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontWeight: "thin", fontSize: 10, textOverflow: "ellipsis", maxLines: 1}}>
                                                {order.provider.establishment_name}
                                            </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '5%', padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Tels: </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '37%', padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontWeight: "thin", fontSize: 10}}>{order.provider.phone}</Text>
                                        </View>
                                    </View>
                                </View>

                                <TablePDF columns={columns} data={productsPDF}/>

                                <View style={{
                                    display: "table",
                                    width: "auto",
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderLeftWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    backgroundColor: "#E4E4E4",
                                }}>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{flexDirection: 'column', width: '40%', padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Pedido por: </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '40%', padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Recibido por: </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '10%',  padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontSize: 10}}>TOTAL: </Text>
                                        </View>
                                        <View style={{flexDirection: 'column', width: '24%',  padding: 2, paddingHorizontal: 4, textAlign: "left"}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10, textAlign: "left"}}>
                                                ${ new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(order.total)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={{
                                    display: "table",
                                    width: "auto",
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderLeftWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                }}>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{flexDirection: 'column', width: '100%', padding: 2, paddingHorizontal: 4}}>
                                            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>Obsers.: </Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                        )
                    }
                    return null;
                })}


                <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 5}}>
                    <View style={{flexDirection: 'column'}}>
                        <Text style={{fontWeight: 'thin', fontSize: 10}}>Total Compras: </Text>
                    </View>
                    <View style={{flexDirection: 'column'}}>
                        <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 10}}>$ {new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(total)}</Text>
                    </View>
                </View>

                <View style={{position: 'absolute', bottom: 20, left: 30, right: 30, textAlign: 'center'}} fixed>
                    <Text style={{fontWeight: 'thin', fontSize: 10, textAlign: 'center'}} render={({ pageNumber, totalPages }) => (
                        `Página ${pageNumber} de ${totalPages}`
                    )}/>
                </View>
            </Page>
        </Document>
    )
}

export default AllPurchasesFromPeriodPDF;
