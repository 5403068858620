import React, {Component} from "react";
import {Alert, Button, ButtonGroup, Card, Col, Placeholder, Row, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FiArchive, FiPrinter} from "react-icons/fi";
import BackButton from "../../util/backButton";

class PrintOrder extends Component {
    id = this.props.match.params.id;
    isOrder = (this.props.match.params.isOrder === "order");

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            ready: false,
            error: false,
            order: null,
            update_on: null,
            create_on: null,
        }
    }

    componentDidMount() {
        this.loadOrder();
    }

    render() {
        return (
            <>
                <Card>
                    <Row className={"component-for-print text-center"}>
                        <Col>
                            <ButtonGroup>
                                <BackButton/>
                                {this.state.order ? (
                                    <>
                                        <NavLink to={"/admin/edit/orders/" + this.id}
                                                 className={"btn btn-info"}
                                        >
                                            <i><FiArchive/></i> Ver Orden
                                        </NavLink>
                                        <Button variant={"success"} onClick={() => window.print()}>
                                            <i><FiPrinter/></i> Imprimir
                                        </Button>
                                    </>
                                ) : null}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Card.Header style={{padding: "0.1rem 1rem"}}>
                        <Row>
                            <Col xs={4}>
                                {this.isOrder ? (
                                    <b>ORDEN DE PEDIDO </b>
                                ) : (
                                    <b className={"text-success"}>ORDEN DE CARGUE </b>
                                )}
                            </Col>
                            <Col xs={8}>
                                {this.state.order && this.state.order.load ? (
                                    <small className="text-muted overflow-hidden">
                                        Cargue:
                                        <b className={"text-info overflow-hidden"}> {this.state.order.load}</b>
                                    </small>
                                ) : null}
                            </Col>
                            <Col>
                                <div style={{position: "absolute", top: "-8px", right: "0px"}}>
                                    <small className="text-muted">N.: </small>
                                    <b className="text-danger"
                                       style={{fontFamily: "Courier New, monospace", fontSize: "32px"}}
                                    >
                                        {this.id}
                                    </b>
                                    {this.state.order && this.state.order.order ? (
                                        <>
                                            |<b className={"text-success"} style={{
                                            fontFamily: "Courier New, monospace",
                                            fontSize: "30px"
                                        }}>{this.state.order.order} </b>
                                        </>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                </Card>
                {this.state.ready ? (
                    <>{this.state.error ? (
                        <Alert variant={"danger"}>
                            <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                            <p>
                                {this.state.error}
                            </p>
                            <hr/>
                            <Button onClick={() => this.loadOrder()} variant={"dark"}>
                                Intentar nuevamente
                            </Button>
                        </Alert>
                    ) : (
                        <div style={{fontSize: "12px"}}>
                            <Card>
                                <Card.Body style={{padding: "0.1rem 1rem"}}>
                                    <Table>
                                        <tr>
                                            <th>Cliente:</th>
                                            <td className={"overflow-hidden"} colSpan={3}>{this.state.order.client.name ? this.state.order.client.name.toUpperCase() : ""}
                                            </td>
                                            <th>Ciudad:</th>
                                            <td>{this.state.order.client.department ? this.state.order.client.department.toUpperCase() : ""}/
                                                {this.state.order.client.city ? this.state.order.client.city.toUpperCase() : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Dirección:</th>
                                            <td colSpan={3}>{this.state.order.client.address}</td>
                                            <th>Teléfono:</th>
                                            <td>{this.state.order.client.phone}</td>
                                        </tr>
                                        <tr>
                                            <th>Estab.:</th>
                                            <td colSpan={3}>{this.state.order.client.establishment_name}</td>
                                            <th>Contácto:</th>
                                            <td>{this.state.order.client.contact_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Nit:</th>
                                            <td>{this.state.order.client.identification}</td>
                                            <th>F. Pago:</th>
                                            <td>{this.state.order.payment_method ? this.state.order.payment_method.toUpperCase() : ""}</td>
                                            <th>Asesor:</th>
                                            <td>{this.state.order.advisory ? this.state.order.advisory.toUpperCase() : ""}</td>
                                        </tr>
                                    </Table>
                                </Card.Body>
                            </Card>
                            {this.state.order.products && this.state.order.products.length > 0 ? (
                                <Card>
                                    <Card.Body style={{fontSize: "12px", padding: "0rem 0rem"}}>
                                        <Table bordered  hover
                                               className={"table-sm-custom"}
                                        >
                                            <thead>
                                            <tr className={"text-center"}>
                                                <th></th>
                                                <th>Código</th>
                                                <th>Descripción</th>

                                                {!this.isOrder ? (
                                                    <>
                                                        <th colSpan="2">
                                                            Peso
                                                            <small className={"text-muted"}>
                                                                (Kg)
                                                            </small>
                                                        </th>
                                                        <th>Cant</th>
                                                        <th>Alistado</th>
                                                        <th>Cargado</th>
                                                    </>

                                                ) : <>
                                                    <th>Cant</th>
                                                    <th>V.U.</th>
                                                    <th>Valor T.</th>
                                                </>}
                                            </tr>
                                            </thead>
                                            {this.state.order.products.length > 25 && (
                                                <tfoot>
                                                <tr>
                                                    <td colSpan={6} className={"text-end"}>
                                                        _:
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            )}
                                            <tbody>
                                            {this.state.order.products.map((product, key) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className={"text-center"}>{product.option ? ("1") : "2"}</td>
                                                            <td className={"text-center"}>{product.code}</td>
                                                            <td>{product.name}</td>

                                                            {!this.isOrder ? (
                                                                <>
                                                                    <td style={{
                                                                        textAlign: 'right',
                                                                        paddingRight: "0.3rem"
                                                                    }}>
                                                                        {new Intl.NumberFormat('de-DE', {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(product.weight)}
                                                                    </td>
                                                                    <td style={{
                                                                        textAlign: 'right',
                                                                        paddingRight: "0.3rem"
                                                                    }}>
                                                                        {new Intl.NumberFormat('de-DE', {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(product.weight * product.amount)}
                                                                    </td>
                                                                    <td style={{
                                                                        textAlign: 'right',
                                                                        paddingRight: "0.3rem"
                                                                    }}>
                                                                        {new Intl.NumberFormat('de-DE').format(product.amount)}
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </>
                                                            ) : <>
                                                                <td style={{
                                                                    textAlign: 'right',
                                                                    paddingRight: "0.3rem"
                                                                }}>
                                                                    {new Intl.NumberFormat('de-DE').format(product.amount)}
                                                                </td>
                                                                <td style={{
                                                                    textAlign: 'right',
                                                                    paddingRight: "0.3rem"
                                                                }}>
                                                                    ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.value)}
                                                                </td>
                                                                <td style={{
                                                                    textAlign: 'right',
                                                                    paddingRight: "0.3rem"
                                                                }}>
                                                                    ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.total)}
                                                                </td>
                                                            </>}

                                                        </tr>
                                                        {product.discount && this.isOrder ? (
                                                            <tr>
                                                                <td className={"text-center"} colspan="5">
                                                                    <small className={"text-muted"}>
                                                                        DESCUENTO APLICADO :
                                                                    </small>
                                                                </td>
                                                                {!this.isOrder ? (
                                                                    <>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </>
                                                                ) : null}
                                                                <td style={{textAlign: 'right'}}>
                                                                    <small className={"text-muted"}>
                                                                        ({product.discount}%)
                                                                        -
                                                                        ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(this.calculateDiscount(product.amount, product.value, product.iva, product.discount))}
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                        ) : null}
                                                    </>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                    <Card.Footer style={{padding: "0.0rem 1rem"}}>
                                        <Table>
                                            <tr>
                                                {!this.isOrder ? (
                                                    <>
                                                        <td>Peso:</td>
                                                        <th className={"text-danger"} style={{minWidth: "80px"}}>
                                                            {new Intl.NumberFormat('de-DE', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }).format(this.state.order.weight)} Kg
                                                        </th>
                                                        <th>
                                                            Alistado por:
                                                        </th>
                                                        <td style={{minWidth: "100px"}}></td>
                                                        <th>
                                                            Cargado por:
                                                        </th>
                                                        <td style={{minWidth: "100px"}}></td>
                                                        <td>TOTAL:</td>
                                                        <th style={{minWidth: "80px"}}
                                                            className={"text-secondary"}>${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(this.state.order.total)}</th>
                                                    </>
                                                ) : (
                                                    <>
                                                        <th>Subtotal:</th>
                                                        <td>
                                                            ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(this.state.order.subtotal)}
                                                        </td>
                                                        <th>Desc:</th>
                                                        <td>
                                                            <small className={"text-muted"}>
                                                                ({this.state.order.discount}%)
                                                            </small>
                                                            -
                                                            ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(this.state.order.total_discount)}
                                                        </td>
                                                        <th>TOTAL:</th>
                                                        <th>${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(this.state.order.total)}</th>
                                                    </>
                                                )}

                                            </tr>
                                        </Table>
                                    </Card.Footer>
                                </Card>
                            ) : null}
                            {this.state.order.comments && this.state.order.comments.length > 0 ? (
                                <Card>
                                    <Card.Header style={{padding: "0rem 1rem"}}>
                                        <small className="text-muted">Comentarios:</small>
                                    </Card.Header>
                                    <Card.Body style={{padding: "0.2rem 1rem", fontSize: "12px"}}>
                                        {this.state.order.comments.map((comment) => {
                                            return (
                                                <div style={{ whiteSpace: "pre-wrap"}}>
                                                    <small className="text-muted">
                                                        <b>{comment.author}</b>
                                                    </small>
                                                    : {comment.message}<br/>
                                                </div>
                                            )
                                        })}
                                    </Card.Body>
                                </Card>
                            ) : null}
                        </div>
                    )}</>
                ) : (
                    <>
                        <Placeholder as={Card.Text} animation={"glow"}>
                            <Placeholder xs={12} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} size={"lg"} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} size={"lg"} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Text} animation={"glow"}>
                            <Placeholder xs={12} bg={"success"}/>
                            <Placeholder xs={12} bg={"success"}/>
                        </Placeholder>
                    </>
                )}
                <Card>
                    <Card.Footer style={{padding: "0.1rem 1rem", fontSize: "12px"}}>
                        <small className="text-muted">
                            {
                                this.state.ready && !this.state.error ? (
                                    <>
                                        Actualizado el {this.state.update_on}
                                        <custom className={"float-end"}>
                                            Creada el {this.state.create_on} en {""}
                                            {this.state.order?.from_store?(
                                                <b className={"text-danger"}> TIENDA </b>
                                            ): (<b className={"text-secondary"}> ADMIN </b>)}
                                        </custom>
                                    </>
                                ) : null
                            }
                        </small>
                    </Card.Footer>
                </Card>
            </>
        )
    }

    loadOrder = () => {
        //const params = new URLSearchParams(this.props.location.search);
        //const is_load = params.get("load");
        //this.setState({is_load})
        this.props.loadGet("/orders/" + this.id, this.loadContent, this.loadError)
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            update_on: this.loadDate(data['updated']),
            create_on: this.loadDate(data['date']),
            order: data,
            state: data['status'],
        });
        setTimeout(() => window.print(), 500);
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }
    loadDate = (datum) => {
        let date = new Date(datum * 1000).toLocaleDateString("es-US")
        let hour = new Date(datum * 1000).toLocaleTimeString("es-US")
        return date + " " + hour;
    }

    calculateDiscount = (amount, value, iva, discount) => {
        if (amount && value) {
            let total = amount * value;
            let iva_p = 0;
            if (iva) {
                iva_p = total * iva / 100;
            }
            if (discount) {
                total = (total - iva_p) * (discount) / 100;
            }
            return total
        } else {
            return 0;
        }
    }

    calculateTotalWithDiscount = (amount, value, iva, discount) => {
        if (amount && value) {
            let total = amount * value;
            let iva_p = 0;
            if (iva) {
                iva_p = total * iva / 100;
            }
            if (discount) {
                total = (total - iva_p) * (100 - discount) / 100;
            }
            return total
        } else {
            return 0;
        }
    }
}

export default PrintOrder;