import React, {Component} from "react";
import {Alert, Button, Card, Col, FormControl, FormGroup, FormLabel, Placeholder, Row, Spinner} from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import {NavLink, Redirect} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {AiFillDelete, AiFillSave} from "react-icons/ai";
import SweetAlert from "react-bootstrap-sweetalert";

const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"), description: Yup.string(),
})

class EditCategory extends Component {

    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            category: null,
            error: null,
            ready_post: true,
            redirect: null,
        }
    }

    componentDidMount() {
        this.loadCategory();
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Editar Categoría
                    <NavLink to={"/admin/categories"}>
                        <Button className={"float-end"} size="sm" variant={"secondary"}>
                            <i><FiSearch/></i> Categorias de Producto
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.redirect}
                    {this.state.ready? (
                        this.state.error ? (
                            <Alert severity="danger">
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadCategory()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <Formik
                            initialValues={{
                                name: this.state.category?.name,
                                description: this.state.category?.description,
                            }}
                            validationSchema={schema}
                            onSubmit={values => {
                                this.saveCategory(values, this.state.category.id);
                            }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                  }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Row className={"mb-3"}>
                                            <FormGroup as={Col} controlId="validationName">
                                                <FormLabel>Nombre de la categoría:</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                    placeholder={"Nombre de la categoría"}
                                                />
                                            </FormGroup>
                                        </Row>
                                        <Row className={"mb-3"}>
                                            <FormGroup as={Col} controlId="validationDescription">
                                                <FormLabel>Descripción:</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.description}
                                                    placeholder={"Descripción"}
                                                />
                                            </FormGroup>
                                        </Row>
                                        <Row className={"mb-3"}>
                                            <Col md={6}>
                                                <Button type={"submit"} variant={"success"} disabled={!this.state.ready_post}
                                                        className={"buttons-with-all-width"}
                                                >
                                                    <i>
                                                        <AiFillSave/>
                                                    </i> Guardar Categoría
                                                </Button>
                                            </Col>
                                            <Col md={6}>
                                                <Button variant={"danger"} className={"buttons-with-all-width"}
                                                        onClick={() => this.checkDelete(this.state.category)}
                                                >
                                                    <i><AiFillDelete/></i> Eliminar Categoría
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        )
                    ) : (
                        <>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder.Button variant="success" xs={6}/>
                            <Placeholder.Button variant="danger" xs={6}/>
                        </>
                    )}
                </Card.Body>
            </Card>
        )
    }

    loadCategory = () => {
        this.props.loadGet("/categories/"+this.id, this.loadContent, this.loadError)
    }

    loadContent = (category) => {
        this.setState({ready: true, category: category});
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        });
    }

    saveCategory = (values, id) => {
        this.setState({ready_post: false});
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando en la base de datos
            </SweetAlert>
        );
        this.props.loadPut("/categories/"+id, values, this.successSaveCategory, this.loadErrorPost);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    successSaveCategory = (category) => {
        if (category) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    onConfirm={() => this.setAlert(null)}
                    timeout={2000}
                >
                    Categoría guardada
                </SweetAlert>
            )
            this.setState({
                ready_post: true,
                category: category,
            });
        } else {
            this.loadErrorPost("Error al guardar la categoría")
        }
    }

    loadErrorPost = (onerror) => {
        this.setState({ready_post: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    checkDelete = (category) => {
        const title = "Quiere eliminar la categoría " + category.name + "?";
        this.setAlert(
            <SweetAlert
                warning
                title={title}
                onConfirm={() => this.loadDeleteAlert(category)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El categoría no se puede recuperar despues de eliminada
            </SweetAlert>
        )
    }

    loadDeleteAlert = (category) => {
        const tittle_alert = "Eliminando la categoráa " + category.name + "!";
        this.setAlert(
            <SweetAlert
                custom
                title={tittle_alert}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado la categoría de la base de datos
            </SweetAlert>
        );
        this.props.loadDelete("/categories/"+category.id, this.reloadCategories, this.loadErrorPost);
    }

    reloadCategories = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Categoría borrada en la base de datos
                </SweetAlert>
            );
            this.setState({
                redirect: (<Redirect to={"/admin/categories"}/>)
            });
        } else {
            this.loadError("Error al borrar la categoría de la base de datos");
        }
    }
}

export default EditCategory;
