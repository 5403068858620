import React, {Component} from "react";
import {
    Button, ButtonGroup, Card, Col, FormCheck, FormControl, FormGroup, FormLabel, FormSelect, ProgressBar, Row, Table
} from "react-bootstrap";
import {clearProducts, setProducts} from "../../initializers/actions";
import {connect} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import formatNumberString from "../../util/formatNumberString";
import {AiFillCalendar, AiFillSave, AiOutlineFilePdf} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import ModalViewProductInOrders from "./ModalViewProductInOrders";
import InventoryPDF from "./InventoryPDF";
import { PDFViewer } from "@react-pdf/renderer";
import {BsTable} from "react-icons/bs";

class EditAllInventory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: this.props.products,
            modal_error: null,
            inventory: [],
            saving: null,
            providers: [],
            provider: "",
            negative_inventory: false,
            no_available: false,
            reserved: false,
            updated: false,
            modal_orders: false,
            orders: null,
            ready_orders: false,
            product: null,
            viewWeb : true,
        }
    }

    componentDidMount() {
        this.getAllProviders();
        this.downloadAllProducts();
        this.sortProducts();
    }

    render() {
        return (<Card>
            <Card.Header className={"no-print-component"}>
                Editar todo el inventario
                <ButtonGroup className={"float-end"}>
                    {
                        /*
                       <Button size={"sm"} variant={"secondary"} onClick={() => window.print()}>
                        <i><FiPrinter/></i> Imprimir
                    </Button>
                        */
                    }
                    {
                        !this.state.viewWeb ? (
                            <Button size={"sm"} variant={"primary"} onClick={() => this.viewWeb()}>
                                <i><BsTable/></i> Ver Formulario
                            </Button>
                        ) : (
                            <Button size={"sm"} variant={"outline-danger"} onClick={() => this.viewPDF()}>
                                <i><AiOutlineFilePdf/></i> Ver PDF
                            </Button>
                        )
                    }
                    {
                        /*
                        <PDFDownloadLink
                        document={<InventoryPDF products={this.state.products}/> }
                        fileName={"inventario.pdf"}
                    >
                        <Button size={"sm"} variant={"outline-warning"}>
                            <i><BiSolidDownload/></i> Descargar PDF
                        </Button>
                    </PDFDownloadLink>
                         */
                    }
                    <Button size="sm" variant={"success"}
                            onClick={() => this.sendInventory()}
                            disabled={this.state.inventory.length === 0}
                    >
                        <i><AiFillSave/></i> Guardar Inventario
                    </Button>
                    {this.state.no_available && this.state.updated && this.state.inventory.length === 0 ? (
                        <NavLink to={"/admin/pre-purchases"}
                                 className={"btn btn-sm btn-primary"}
                        >
                            <i><AiFillCalendar/></i> Ver Precompras
                        </NavLink>
                    ) : null}
                </ButtonGroup>
            </Card.Header>
            {
                this.state.viewWeb ? (
                    <Card.Body className={"print-component"}>
                        {this.state.modal_error}
                        <Row className={"mb-3 no-print-component"}>
                            {this.state.saving}
                        </Row>

                        <ModalViewProductInOrders
                            {...this.props} show={this.state.modal_orders}
                            onHide={() => this.setModalShowOrders(false)}
                            product={this.state.product}
                            orders={this.state.orders}
                            ready={this.state.ready_orders}
                        />

                        <p className={"text-center no-print-component"}>Ingresar paramentros de busqueda de productos para
                            actualizar ver y
                            actualizar el inventario</p>
                        <Row className={"mb-3 no-print-component"}>
                            <FormGroup as={Col} md={3} controlId={"validationProvider"}>
                                <FormLabel>Proveedor:</FormLabel>
                                <FormSelect
                                    name={"provider"}
                                    value={this.state.provider}
                                    onChange={(event) => {
                                        this.setState({
                                            provider: event.target.value
                                        });
                                    }}
                                >
                                    {this.getProvidersOptions()}
                                </FormSelect>
                            </FormGroup>
                            <FormGroup as={Col} md={2} controlId={"validationIsZeroInventory"}>
                                <FormLabel>Inventario:</FormLabel>
                                <FormCheck
                                    type="switch"
                                    id="validationIsZeroInventory"
                                    label="Inventario negativo"
                                    checked={this.state.negative_inventory}
                                    onChange={event => {
                                        this.setState({
                                            negative_inventory: !this.state.negative_inventory
                                        });
                                    }}
                                />
                            </FormGroup>
                            <FormGroup as={Col} md={2} controlId={"validationIsZeroAvailable"}>
                                <FormLabel>Disponiblidad:</FormLabel>
                                <FormCheck
                                    type="switch"
                                    id="validationIsZeroAvailable"
                                    label="No Disponible"
                                    checked={this.state.no_available}
                                    onChange={event => {
                                        this.setState({
                                            no_available: !this.state.no_available
                                        })
                                    }}
                                />
                            </FormGroup>
                            <FormGroup as={Col} md={2} controlId={"validationIsZeroAvailable"}>
                                <FormLabel>Reservados:</FormLabel>
                                <FormCheck
                                    type="switch"
                                    id="validationIsReserved"
                                    label="Reservados"
                                    checked={this.state.reserved}
                                    onChange={event => {
                                        this.setState({
                                            reserved: !this.state.reserved
                                        })
                                    }}
                                />
                            </FormGroup>
                            <Col md={3}>
                                <Button
                                    onClick={() => this.searchProductsWithParams()}
                                >
                                    <i><FiSearch/></i> Buscar Productos
                                </Button>
                            </Col>
                        </Row>
                        <h3 className={"text-center"}>Productos del Inventario</h3>
                        <Table size={"sm"} hover striped responsive className={"table-sm-custom"}>
                            <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Primer Proveedor</th>
                                <th className={"text-center"}>Inventario</th>
                                <th className={"text-center"}>Reservado</th>
                                <th className={"text-center"}>Conteo</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.products.map((product) => (<RowProduct id={product.id}
                                                                               code={product.code}
                                                                               name={product.name}
                                                                               providers={product.providers}
                                                                               amount={product.amount}
                                                                               reserved={product.reserved}
                                                                               setInventory={this.setInventory}
                                                                               is_ready={this.state.ready_orders}
                                                                               product={product}
                                                                               setProduct={this.setProduct}
                            />))}
                            </tbody>
                        </Table>
                    </Card.Body>
                ): (
                    <PDFViewer style={{ width: "100%", height: "100vh" }}>
                        <InventoryPDF products={this.state.products} />
                    </PDFViewer>
                )
            }
        </Card>)
    }

    downloadAllProducts = () => {
        if (!this.props.products || this.props.products.length === 0) {
            this.setState({
                saving: (<>
                    <ProgressBar animated now={30}/>
                    <p>Descargado todo el inventario de productos, por favor espere</p>
                </>)
            });
            this.props.loadGet("/products?status=active", this.loadContentAllProduct, this.loadError)
        }
    }

    viewWeb = () => {
        this.setState({
            viewWeb : true,
        })
    }

    viewPDF = () => {
        this.setState({
            viewWeb : false,
        });
    }

    searchProductsWithParams = () => {
        this.setState({
            saving: (<>
                <ProgressBar animated now={30}/>
                <p>Descargado inventario de productos, por favor espere</p>
            </>),
            updated: false,
            ready_orders: false
        });
        let params = "status=active"
        if (this.state.provider) {
            params += "&provider=" + this.state.provider
        }
        if (this.state.negative_inventory) {
            params += "&negative_inventory=" + (!!this.state.negative_inventory)
        }
        if (this.state.no_available) {
            params += "&no_available=" + (!!this.state.no_available)
        }
        if (this.state.reserved) {
            params += "&reserved=" + (!!this.state.reserved)
        }
        this.props.loadGet("/products?" + params, this.loadContent, this.loadError);
    }

    loadContentAllProduct = (data) => {
        this.loadContent(data, true)
    }

    loadContent = (data, is_all) => {
        this.props.loadGet("/orders?status=active,process", this.loadOrdersActiveAndProcess, this.loadError);
        this.setState({
            saving: (<>
                <ProgressBar animated now={85}/>
            </>)
        })
        this.sortProducts2(data, is_all);
    }

    loadOrdersActiveAndProcess = (orders) => {
        this.setState({
            ready_orders: true,
            orders
        });
    }

    loadError = (onerror) => {
        this.setAlert(<SweetAlert
            danger
            title="Error!"
            onConfirm={() => this.setAlert(null)}
        >
            {onerror}
        </SweetAlert>)
    }

    setAlert = (param) => {
        this.setState({
            modal_error: param
        })
    }

    sortProducts = () => {
        let products = this.state.products;
        products.sort((a, b) => {
            return a.code - b.code
        });
        this.setProvidersToProducts(products)
        this.setState({
            products: products
        });
    }

    setProvidersToProducts = (products) => {
        if (this.state.providers && this.state.providers.length > 0) {
            this.addProvidersToProducts(products);
            this.setState({
                products: products
            });
        } else {
            setTimeout(() => {
                this.setProvidersToProducts(products);
            }, 1000);
        }
    }

    sortProducts2 = (products, is_all) => {
        products.sort((a, b) => {
            return a.code - b.code
        });
        this.addProvidersToProducts(products);
        this.setState({
            products: products,
            saving: (<>
                <ProgressBar animated now={100} variant={"success"}/>
                <p>Se descargaron {products.length} productos</p>
            </>)
        });
        if (is_all) {
            this.props.setProducts(products);
        }
        setTimeout(() => {
            this.setState({
                saving: null,
            })
        }, 2000);
    }

    addProvidersToProducts = (products) => {
        for (let product of products) {
            let new_providers = []
            for (let provider of product.providers) {
                provider = this.searchProviderById(provider.id);
                if (provider) {
                    new_providers.push(provider)
                }
            }
            product.providers = new_providers
        }
        products.sort((product_a, product_b) => {
            let provider_a = ""
            let provider_b = ""
            if (product_a.providers && product_a.providers.length > 0 && product_a.providers[0].establishment_name) {
                provider_a = product_a.providers[0].establishment_name.toUpperCase();
            }
            if (product_b.providers && product_b.providers.length > 0 && product_b.providers[0].establishment_name) {
                provider_b = product_b.providers[0].establishment_name.toUpperCase();
            }
            if (provider_a < provider_b) {
                return -1;
            }
            if (provider_a > provider_b) {
                return 1;
            }
            return 0;
        })
        return products;
    }

    searchProviderById = (id) => {
        for (const provider of this.state.providers) {
            if (provider.id === id) {
                return provider;
            }
        }
        return null;
    }

    setInventory = (code, value) => {
        let inventory = this.state.inventory;
        let update = false
        for (const inventoryKey in inventory) {
            if (inventory[inventoryKey].id === code) {
                update = true
                inventory[inventoryKey].value = parseInt(value)
            }
        }
        if (!update) {
            inventory.push({
                value: parseInt(value), id: code
            })
        }
        let products = this.state.products;
        for (const product of products) {
            if (product.id === code) {
                product.amount = parseInt(value)
            }
        }

        this.setState({
            inventory: inventory,
            products
        });
    }

    setProduct = (product) => {
        this.setState({
            product
        });
        this.setModalShowOrders(true);

    }

    sendInventory = () => {
        let products = {
            products: this.state.inventory
        }
        this.setState({
            saving: (<>
                <ProgressBar animated now={30}/>
                <p>Actualizando {this.state.inventory.length} productos</p>
            </>),
            updated: false
        })
        this.props.loadPut("/products/inventory", products, this.loadContentUpdate, this.loadErrorUpdate)
    }

    loadContentUpdate = (data) => {
        this.setState({
            saving: (<>
                <ProgressBar animated now={100} variant={"success"}/>
                <p className={"text-center text-success"}>Se actualizaron {data.products_updated.length} productos
                    correctamente</p>
                {data.products_failed.length > 0 ? (
                    <p className={"text-danger"}>No se actualizaron {data.products_failed.length} productos
                        correctamente</p>) : null}
            </>),
            updated: true,
            inventory: []
        });
        setTimeout(() => {
            this.setState({
                saving: null,
            })
        }, 5000);
    }

    loadErrorUpdate = (error) => {
        this.loadError(error);
        this.setState({
            saving: (<ProgressBar animated now={100} variant={"danger"}/>),
            updated: false,
        })
    }

    getAllProviders = () => {
        this.props.loadGet("/providers/", this.loadProviders, this.loadError)
    }

    loadProviders = (providers) => {
        providers.sort((a, b) => {
            const nameA = a.establishment_name.toUpperCase();
            const nameB = b.establishment_name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
        this.setState({
            providers: providers
        });
        setTimeout(() => {
            let products = this.addProvidersToProducts(this.state.products);
            this.setState({
                products
            });
        }, 1000);

    }

    getProvidersOptions = () => {
        let options = []

        options.push(<option value="" key={"empty"}>Seleccionar</option>)
        for (let provider of this.state.providers) {
            options.push(<option value={provider.id}
                                 key={provider.id}>{provider.establishment_name} - {provider.name}</option>)
        }
        return options;
    }

    setModalShowOrders(modal_orders) {
        this.setState({
            modal_orders
        })
    }
}

function RowProduct(props) {
    const setInventoryLocal = value => {
        if (value !== "" && value >= 0) {
            props.setInventory(props.code, value)
        } else {
            props.setInventory(props.code, 0)
        }
    }

    return (<tr key={props.id}>
        <td>
            {props.code}
        </td>
        <td>
            {props.name}
        </td>
        <td>{props.providers && props.providers.length > 0 ? props.providers[0].establishment_name : ""}</td>
        <td style={{textAlign: 'right'}}>{formatNumberString(props.amount)}</td>
        <td style={{textAlign: 'right'}}>
            {props.is_ready && props.reserved && props.reserved > 0 ? (
                <Button variant={"link"} className={"text-primary print-component text-decoration-none"}
                        onClick={() => props.setProduct(props.product)}
                >
                    {formatNumberString(props.reserved)}
                </Button>
            ) : formatNumberString(props.reserved)}
        </td>
        <td>
            <FormControl
                type={"number"}
                value={props.amount}
                style={{textAlign: 'right'}}
                onChange={(event) => setInventoryLocal(event.target.value)}
                className={"no-print-component"}
                onWheel={
                    (event) => {
                        event.preventDefault();
                        event.target.blur();
                        event.stopPropagation();
                        setTimeout(() => {
                            event.target.focus()
                        }, 0);
                    }
                }
            />
        </td>
    </tr>);
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
    }
}

const mapDispatchToProps = {
    setProducts, clearProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAllInventory);