import React, {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, FormSelect, Row, Spinner} from "react-bootstrap";
import * as Yup from "yup";
import {AiFillSave} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {NavLink, Redirect} from "react-router-dom";
import {Formik, Form} from 'formik';
import cities from "../clients/cities";
import SweetAlert from "react-bootstrap-sweetalert";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    identification: Yup.string().required('Requedido'),
    phone: Yup.string(),
    city: Yup.string(),
    department: Yup.string(),
    address: Yup.string(),
    establishment_name: Yup.string(),
});

class NewProvider extends Component{
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            modal: null,
            error: false,
            ready: true
        }
    }

    render() {
        return(
            <Card>
                <Card.Header>
                    Nuevo Proveedor
                    <NavLink to={"/admin/providers"}>
                        <Button className={"float-end"} size="sm" variant={"secondary"}>
                            <i><FiSearch/></i> Buscar Proveedores
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.content}
                    <Formik
                        initialValues={{
                            name: '',
                            identification: '',
                            phone: '',
                            city: '',
                            department: '--',
                            address: '',
                            establishment_name: '',
                        }}
                        onSubmit={ values => {
                            this.saveProvider(values)
                        }}
                        validationSchema={schema}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors
                          }) => (
                              <Form onSubmit={handleSubmit}>
                                  <Row className={"mb-3"}>
                                      <FormGroup as={Col} md={"6"} controlId="validationIdentification">
                                          <FormLabel>Identificación del proveedor (Nit o CC):</FormLabel>
                                          <FormControl
                                              type="text"
                                              name="identification"
                                              value={values.identification}
                                              onChange={handleChange}
                                              isInvalid={!!errors.identification}
                                              placeholder={"Nit o Número de Identificación"}
                                          />
                                          <FormControl.Feedback type="invalid">
                                              {errors.identification}
                                          </FormControl.Feedback>
                                      </FormGroup>
                                      <FormGroup as={Col} md={"6"} controlId="validationName">
                                          <FormLabel>Nombre del proveedor (Razon Social):</FormLabel>
                                          <FormControl
                                              type="text"
                                              name="name"
                                              value={values.name}
                                              onChange={handleChange}
                                              isInvalid={!!errors.name}
                                              placeholder={"Nombre"}
                                          />
                                          <FormControl.Feedback type="invalid">
                                              {errors.name}
                                          </FormControl.Feedback>
                                      </FormGroup>
                                  </Row>
                                  <Row className={"mb-3"}>
                                      <FormGroup as={Col} md={"4"} controlId="validationPhone">
                                          <FormLabel>Número telefonico (Celular):</FormLabel>
                                          <FormControl
                                              type="text"
                                              name="phone"
                                              value={values.phone}
                                              onChange={handleChange}
                                              isInvalid={!!errors.phone}
                                              placeholder={"Teléfono o Celular"}
                                          />
                                          <FormControl.Feedback type="invalid">
                                              {errors.phone}
                                          </FormControl.Feedback>
                                      </FormGroup>
                                      <FormGroup as={Col} md={"4"} controlId="validationDepartments">
                                          <FormLabel>Departamento:</FormLabel>
                                          <FormSelect
                                              name="department"
                                              value={values.department}
                                              onChange={handleChange}
                                              isInvalid={!!errors.department}
                                          >
                                              {this.getDepartments()}
                                          </FormSelect>
                                          <FormControl.Feedback type="invalid">
                                              {errors.department}
                                          </FormControl.Feedback>
                                      </FormGroup>
                                      <FormGroup as={Col} md={"4"} controlId="validationCities">
                                          <FormLabel>Ciudad:</FormLabel>
                                          <FormSelect
                                              name="city"
                                              value={values.city}
                                              onChange={handleChange}
                                              isInvalid={!!errors.city}
                                          >
                                              <option>--</option>
                                              {
                                                  cities[values.department].map((city, key) => (
                                                      <option key={key}>{city}</option>
                                                  ))
                                              }
                                          </FormSelect>
                                          <FormControl.Feedback type="invalid">
                                              {errors.city}
                                          </FormControl.Feedback>
                                      </FormGroup>
                                  </Row>
                                  <Row className={"mb-3"}>
                                      <FormGroup as={Col} md={"6"} controlId="validationAddress">
                                          <FormLabel>Dirección:</FormLabel>
                                          <FormControl
                                              type="text"
                                              name="address"
                                              value={values.address}
                                              onChange={handleChange}
                                              isInvalid={!!errors.address}
                                              placeholder={"Dirección"}
                                          />
                                          <FormControl.Feedback type="invalid">
                                              {errors.address}
                                          </FormControl.Feedback>
                                      </FormGroup>
                                      <FormGroup as={Col} md={"6"} controlId="validationEstablishmentName">
                                          <FormLabel>Nombre del establecimiento:</FormLabel>
                                          <FormControl
                                              type="text"
                                              name="establishment_name"
                                              value={values.establishment_name}
                                              onChange={handleChange}
                                              isInvalid={!!errors.establishment_name}
                                              placeholder={"Establecimiento"}
                                          />
                                          <FormControl.Feedback type="invalid">
                                              {errors.establishment_name}
                                          </FormControl.Feedback>
                                      </FormGroup>
                                  </Row>
                                  <Row>
                                      <Col>
                                          <Button type={"submit"} variant={"success"} disabled={!this.state.ready}
                                                  className={"buttons-with-all-width"}
                                          >
                                              <i>
                                                  <AiFillSave/>
                                              </i> Guardar Proveedor
                                          </Button>
                                      </Col>
                                  </Row>
                              </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>
        )
    }

    saveProvider = (values) => {
        values['name'] = values.name.toUpperCase();
        values['identification'] = values.identification.toUpperCase();
        values['address'] = values.address.toUpperCase();
        values['establishment_name'] = values.establishment_name.toUpperCase();
        this.setState({
            ready: false,
        });
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el proveedor en la base de datos
            </SweetAlert>
        );
        this.props.loadPost("/providers", values, this.redirectToEdit, this.loadError);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    redirectToEdit = (provider) => {
        if (provider) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    showConfirm={false}
                    closeOnClickOutside={false}
                >
                    Proveedor guardado
                </SweetAlert>
            )
            this.setState({
                content: (<Redirect to={"/admin/edit/providers/" + provider.id}/>)
            });
        } else {
            this.loadError("Error al crear el proveedor")
        }
    };

    loadError = (onerror) => {
        this.setState({ready: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    getDepartments = () => {
        let deps = [];
        for (let department in cities) {
            deps.push(<option key={department}>{department}</option>)
        }
        return deps;
    }
}
export default NewProvider;