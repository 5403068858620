import React, {Component} from "react";
import {Card} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import PeriodDashboard from "./PeriodDashboard";

class SalesDashboard extends Component{
    render() {
        return(
            <Card>
                <Card.Header>
                    Resumen
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        Periodos de Venta <br/>
                        <NavLink to={"/admin/sale_periods"}>
                            <small className={"text-muted"}>Ver todos los periodos de venta</small>
                        </NavLink>
                    </Card.Title>
                    <hr/>
                    <PeriodDashboard {...this.props} type={"sales"}/>
                    <Card.Title>
                        Periodos de Cargue <br/>
                        <NavLink to={"/admin/loads"}>
                            <small className={"text-muted"}>Ver todos los periodos de cargue</small>
                        </NavLink>
                    </Card.Title>
                    <hr/>
                    <PeriodDashboard {...this.props} type={"loads"}/>
                    <Card.Title>
                        Periodos de Compra <br/>
                        <NavLink to={"/admin/purchase_periods"}>
                            <small className={"text-muted"}>Ver todos los periodos de compra</small>
                        </NavLink>
                    </Card.Title>
                    <hr/>
                    <PeriodDashboard {...this.props} type={"purchases"}/>
                </Card.Body>
                <Card.Footer></Card.Footer>
            </Card>
        )
    }
}
export default SalesDashboard;