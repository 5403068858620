import Periods from "./Periods";

class LoadsPeriods extends Periods {
    classPeriod = "loads";
    description = "En los periodos de cargue solo se muestran las ordenes asociadas a cada uno de los cargues y una " +
        "orden de pedido no puede estar asociada a varios cargues."
    loadTablePeriods = () => {
        this.props.loadGet("/loads", this.loadContent, this.loadError)
    }

    postPeriod = (values) => {
        this.props.loadPost("/loads", values, this.loadSuccessPost, this.loadErrorPost);
    }

    putPeriod = (id, values) => {
        this.props.loadPut("/loads/" + id, values, this.loadSuccessPut, this.loadErrorPost);
    }

    closePeriodPut = (id, values) => {
        this.props.loadPut("/loads/" + id + "/close", values, this.loadSuccessPut, this.loadErrorPost);
    }

    deletePeriod = (id) => {
        this.props.loadDelete("/loads/" + id, this.loadSuccessDelete, this.loadErrorPost);
    }
}

export default LoadsPeriods;