import React, {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, Row, Spinner, Table} from "react-bootstrap";
import {clearProducts, setProducts} from "../../initializers/actions";
import {connect} from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import RowFormProductPurchase from "./RowFormProductPurchase";
import {GrAdd} from "react-icons/gr";
import utilizeFocus from "../../util/focusElement";

class ProductsInformationPurchase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal_error: null,
            products: this.props.products,
            purchase_products: this.props.purchase.products ? this.props.purchase.products : []
        }
        this.buttonAddProducts = utilizeFocus()
        this.inputCodeProducts = []
        this.inputCantProducts = []
        this.inputValuesProducts = []

        for (let key in this.props.purchase.products) {
            this.inputCodeProducts.push(utilizeFocus());
            this.inputCantProducts.push(utilizeFocus());
            this.inputValuesProducts.push(utilizeFocus());
        }
    }

    componentDidMount() {
        this.downloadAllProducts();
        this.buttonAddProducts.setFocus();
    }

    render() {
        let products = this.state.purchase_products ? this.state.purchase_products : [];
        return (
            <Card>
                <Card.Header>
                    Productos
                </Card.Header>
                <Card.Body>
                    {this.state.modal_error}
                    {products.length > 0 ? (
                        <Table size={"sm"} hover striped responsive>
                            <th className={"text-center"}>
                                Código
                            </th>
                            <th>
                                Descripción
                            </th>
                            <th>
                                Inv
                            </th>
                            <th className={"text-center"}>
                                Cant
                            </th>
                            <th className={"text-center"}>
                                Valor U.
                            </th>
                            <th className={"text-center"}>
                                IVA
                            </th>
                            <th className={"text-center"}>
                                Valor T.
                            </th>
                            <th className={"text-center"}>
                                Acciones
                            </th>
                            <tbody>
                            {this.state.purchase_products.map((product) => {
                                return (
                                    <RowFormProductPurchase {...this.props}
                                                            productsStorage={this.state.products}
                                                            product={product}
                                                            inputCodeProducts={this.inputCodeProducts}
                                                            inputCantProducts={this.inputCantProducts}
                                                            inputValuesProducts={this.inputValuesProducts}
                                                            deleteItem={this.deleteItem}
                                                            deleteItemEmpty={this.deleteItemEmpty}
                                                            sentValuesLocal={this.sentValuesLocal}
                                                            key={product.id}
                                    />
                                );
                            })}
                            </tbody>
                        </Table>
                    ) : null}
                    {this.props.enabled ? (
                        <Row>
                            <Button variant={"outline-primary"}
                                    disabled={!this.props.enabled}
                                    ref={this.buttonAddProducts.ref}
                                    onClick={() => {
                                        this.addNewRowProduct()
                                    }}
                            >
                                <i><GrAdd/></i> Agregar productos
                            </Button>
                        </Row>
                    ) : null}

                </Card.Body>
                <Card.Footer>
                    {products.length > 0?(
                        <Row >
                            <FormGroup as={Col} sm={4} controlId={"validationDate"}>
                                <FormLabel>Subtotal:</FormLabel>
                                <FormControl readOnly
                                             value={this.getValueInMoney(this.props.purchase.subtotal)}
                                             style={{textAlign: 'right'}}
                                />
                            </FormGroup>
                            <FormGroup as={Col} sm={4} controlId={"validationDate"}>
                                <FormLabel><b>Total:</b></FormLabel>
                                <FormControl readOnly
                                             value={this.getValueInMoney(this.props.purchase.total)}
                                             style={{textAlign: 'right'}}
                                />
                            </FormGroup>
                        </Row>
                    ): null}
                </Card.Footer>
            </Card>
        )
    }

    downloadAllProducts = () => {
        if(!this.props.products || this.props.products.length === 0){
            this.props.loadGet("/products/", this.loadContent, this.loadError)
        }
    }

    loadContent = (data) => {
        this.setState({
            products: data
        });
        this.props.setProducts(data);
    }

    loadError = (onerror) => {
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal_error: param
        })
    }

    addNewRowProduct = () => {
        this.inputCodeProducts.push(utilizeFocus());
        this.inputCantProducts.push(utilizeFocus());
        this.inputValuesProducts.push(utilizeFocus());
        setTimeout(
            () => {
                this.inputCodeProducts[this.inputCodeProducts.length - 1].setFocus();
            },
            300
        );
        let purchase_products = this.state.purchase_products;
        purchase_products.push({code: '', id: purchase_products.length})
        this.setState({
            purchase_products: purchase_products
        })
    }

    deleteItemEmpty = (key) => {
        let products = this.state.order_products;
        products.splice(key, 1)
        this.setState({
            order_products: products
        });
    }

    deleteItem = (item, key) => {
        let title_var = "Quiere eliminar el producto " + (item.code) + " " + (item.name) + " de la orden?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeleteAlert(item, key)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El producto no se puede recuperar despues de eliminado
            </SweetAlert>
        )
    }

    loadDeleteAlert = (product, key) => {
        let title_var = "Eliminando el producto " + (product.code) + " " + (product.name) + "!"
        this.setAlert(
            <SweetAlert
                custom
                title={title_var}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado el producto dentro de la orden
            </SweetAlert>
        );
        this.deleteItemFromOrder(key);
    }

    deleteItemFromOrder = (key) => {
        let purchase = this.props.purchase;
        let newProducts = []
        let counter = 0
        for (let product of purchase.products) {
            if(product.id !== key) {
                product.id = counter
                newProducts.push(product)
                counter++
            }
        }

        this.setState({
            purchase_products: newProducts
        });
        purchase.products = newProducts
        this.props.setPurchase(purchase);

        this.setAlert(
            <SweetAlert
                success
                title="Eliminado!"
                closeOnClickOutside={false}
                timeout={2000}
                onConfirm={() => this.setAlert(null)}
            >
                Producto borrado
            </SweetAlert>
        )
        setTimeout(
            () => {
                window.location.reload();
            }, 600
        )
    }

    sentValuesLocal = (key, code, amount, value, iva, name, weight) => {
        if (code && amount && value ) {
            let product = {
                id: key,
                code: code,
                amount: amount,
                value: value,
                iva: iva,
                name: name,
                weight: weight
            }
            let purchase = this.props.purchase;
            let isUpdated = true;
            if (purchase.products) {
                if (purchase.products[key]) {
                    let product_saved = purchase.products[key]
                    if (product_saved.id === product.id && product_saved.amount === product.amount &&
                        product_saved.value === product.value) {
                        isUpdated = false
                    } else {
                        purchase.products[key] = product
                    }
                    if (this.state.purchase_products[key + 1]) {
                        this.inputCodeProducts[key + 1].setFocus();
                    } else {
                        setTimeout(() => this.addNewRowProduct(), 300);
                    }
                } else {
                    purchase.products.push(product);
                    setTimeout(() => this.addNewRowProduct(), 300);
                }
            } else {
                purchase.products = [product]
                setTimeout(() => this.addNewRowProduct(), 300);
            }
            if (isUpdated) {
                this.props.setPurchase(purchase);
            }
        }
    }

    getValueInMoney = (value) => {
        let number = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(value)
        return '$' + number
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
    }
}

const mapDispatchToProps = {
    setProducts,
    clearProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsInformationPurchase);