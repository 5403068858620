import Orders from "./Orders";

const INACTIVE = "INACTIVE"
class ClosedOrders extends Orders {
    loadTableOrders = () => {
        this.props.loadGet("/orders?status=inactive", this.loadContent, this.loadError)
    }

    loadColor = () => {
        return "secondary"
    }

    loadColorTable = () => {
        return this.loadColor()
    }

    loadType = () => {
        return INACTIVE
    }
}
export default ClosedOrders;