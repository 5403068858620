import React, {Component} from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "./layouts/AdminLayout";
import Login from "./layouts/Login";
import Logout from "./layouts/Logout";
window.Buffer = window.Buffer || require("buffer").Buffer;

class App extends Component{

    render() {
        return(
            <BrowserRouter>
                <Switch>
                    <Route path="/admin" render={props => <AdminLayout {...props}/>}/>
                    <Route path={"/login"} render={props => <Login {...props} />} />
                    <Route path={"/logout"} render={props => <Logout {...props}/>}/>
                    <Redirect to={"/admin/home"}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
