import React, {Component} from "react";
import {Alert, Button, Card, Placeholder, Spinner, Tab, Tabs} from "react-bootstrap";
import {GrAdd} from "react-icons/gr";
import ModalNewPeriod from "./ModalNewPeriod";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalEditPeriod from "./ModalEditPeriod";
import TablePeriods from "./TablePeriods";

class Periods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            periods: null,
            modal: null,
            modal_new: false,
            modal_edit: false,
            ready_post: true,
            period_edit: null
        }
    }

    componentDidMount() {
        this.loadTablePeriods();
    }

    classPeriod="";
    description="Default description";

    render() {
        return (
            <Card>
                <Card.Header>
                    Listado de periodos
                    <Button className={"float-end"} size="sm" variant={"primary"}
                            onClick={() => this.loadModalNewPeriod()}>
                        <i><GrAdd/></i> Crear Nuevo Periodo
                    </Button>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    <ModalNewPeriod {...this.props} show={this.state.modal_new}
                                    onHide={() => this.setModalShow(false)}
                                    loadNewPeriod={this.loadNewPeriod}
                    />
                    <ModalEditPeriod {...this.props} show={this.state.modal_edit}
                                     onHide={() => this.setModalShowEdit(false)}
                                     PeriodEdit={this.state.period_edit}
                                     loadEditPeriod={this.loadEditPeriod}
                    />
                    {this.state.ready ? (
                        this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadTablePeriods()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            this.paintPeriods(this.state.periods)
                        )
                    ) : (
                        <>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
            </Card>
        )
    }

    loadTablePeriods = () => {
    }
    postPeriod = (values) => {
    }
    putPeriod = (id, values) => {
    }
    closePeriodPut = (id, values) => {
    }
    deletePeriod = (id) => {}

    loadContent = (data) => {
        this.setState({
            ready: true,
            periods: data
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    paintPeriods = (data) => {
        if (data && data.length > 0) {
            return (
                <>
                    <Alert
                        variant={"info"}
                    >
                        {this.description}
                    </Alert>
                    <Tabs id={"tab-period-id"} className={"mb-3"}>
                        <Tab eventKey={"actives"} title={"Periodos Activos"}>
                            <TablePeriods
                                type={"active"}
                                periods={data}
                                editPeriodModalOpen={this.editPeriodModalOpen}
                                closePeriod={this.closePeriod}
                                checkDelete={this.checkDelete}
                                classPeriod={this.classPeriod}
                            />
                        </Tab>
                        <Tab eventKey={"closed"} title={"Periodos Cerrados"}>
                            <TablePeriods
                                type={"closed"}
                                periods={data}
                                editPeriodModalOpen={this.editPeriodModalOpen}
                                closePeriod={this.closePeriod}
                                openPeriod={this.openPeriod}
                                classPeriod={this.classPeriod}
                            />
                        </Tab>
                    </Tabs>
                </>
            )
        } else {
            return (
                <p>No hay datos disponibles para mostrar</p>
            )
        }
    }

    loadModalNewPeriod = () => {
        this.setModalShow(true);
    }

    loadNewPeriod = (values) => {
        this.setState({ready_post: false});
        this.setAlertSaving();
        this.postPeriod(values);
    }

    loadEditPeriod = (values) => {
        this.setState({ready_post: false});
        this.setAlertSaving();
        this.putPeriod(this.state.period_edit.id, values)
    }

    setModalShow = (show) => {
        this.setState({modal_new: show})
    }

    setModalShowEdit = (show) => {
        this.setState({modal_edit: show})
    }

    loadSuccessPost = (period) => {
        if (period) {
            this.loadSuccessPostAlert()
            this.loadTablePeriods();
        } else {
            this.loadErrorPost("Error al crear el periodo")
        }
    }

    loadSuccessPut = (period) => {
        if (period) {
            this.loadSuccessPostAlert()
            this.loadTablePeriods();
        } else {
            this.loadErrorPost("Error al guardar el periodo")
        }
    }

    loadSuccessDelete = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Periodo borrado
                </SweetAlert>
            )
            this.loadTablePeriods();
        } else {
            this.loadErrorPost("Error al eliminar el periodo")
        }
    }


    loadErrorPost = (onError) => {
        this.setState({ready_post: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onError}
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    editPeriodModalOpen = (period) => {
        this.setModalShowEdit(true);
        this.setState({
            period_edit: period
        });
    }

    closePeriod = (period) => {
        let title_var = "Quiere cerrar el periodo " + (period.name) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadClosePeriod(period)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="warning"
                confirmBtnText="Cerrar"
            >

            </SweetAlert>
        )
    }

    loadClosePeriod = (period) => {
        this.setState({ready_post: false});
        this.setAlertSaving();
        this.closePeriodPut(period.id)
    }

    openPeriod = (period) => {
        let title_var = "Quiere reabrir el periodo " + (period.name) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadReOpenPeriod(period)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="warning"
                confirmBtnText="Abrir"
            >

            </SweetAlert>
        )
    }

    loadReOpenPeriod = (period) => {
        this.setState({ready_post: false});
        this.setAlertSaving();
        this.putPeriod(period.id, {close_date:0, status:"active"})
    }

    checkDelete = (period) => {
        let title_var = "Quiere borrar el periodo " + (period.name) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeletePeriod(period)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="warning"
                confirmBtnText="Borrar"
            >

            </SweetAlert>
        )
    }

    loadDeletePeriod = (period) => {
        this.setState({ready_post: false});
        this.setAlertSaving();
        this.deletePeriod(period.id)
    }

    setAlertSaving = () => {
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el periodo en la base de datos
            </SweetAlert>
        )
    }

    loadSuccessPostAlert = () => {
        this.setAlert(
            <SweetAlert
                success
                title="Guardado!"
                showConfirm={false}
                timeout={2000}
                onConfirm={() => this.setAlert(null)}
            >
                Periodo Guardado
            </SweetAlert>
        );
    }
}

export default Periods;