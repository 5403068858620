import React, {Component} from "react";
import {Container} from "react-bootstrap";
import {Switch} from "react-router-dom";

class MainPanel extends Component {

    render() {
        return(
            <div className={"main-content"}>
                <Container fluid>
                    <Switch>
                        {this.props.route}
                    </Switch>
                </Container>
            </div>
        );
    }
}
export default MainPanel;