import React, {Component} from "react";
import {Alert, Col, Modal, Row, Spinner, Table} from "react-bootstrap";
import formatNumberString from "../../util/formatNumberString";
import {NavLink} from "react-router-dom";

class ModalViewProductInOrders extends Component{


    render() {
        return(
            <Modal
                {...this.props}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    {this.props.product?null:(
                        <Alert variant={"danger"}>No se ha seleccionado ningun producto</Alert>
                    )}
                    {this.props.ready && this.props.orders && this.props.product?(
                        this.getOrdersWhereExistProduct()
                    ):(
                        <Row>
                            <Col className={"text-center"}>
                                <Spinner animation={"border"}/>
                            </Col>
                        </Row>
                        )}
                </Modal.Body>
            </Modal>
        )
    }

    getOrdersWhereExistProduct = () => {
        if(this.props.orders.length>0){
            let not_exist_almost_one= true;
            let total_amount = 0;
            let orders = this.props.orders.map( (order, key) => {
                if(order.products && order.products.length>0){
                    let total_amount_by_order = 0;
                    for (let product of order.products) {
                        if(product.code === this.props.product.code){
                            not_exist_almost_one = false;
                            total_amount +=product.amount;
                            total_amount_by_order +=product.amount;
                        }
                    }
                    if(total_amount_by_order){
                        return (
                            <tr>
                                <td className={"text-center"}>
                                    <NavLink to={"/admin/edit/orders/" + order.id}>
                                        {order.id}
                                    </NavLink>
                                </td>
                                <td style={{textAlign: "right"}}>
                                    {formatNumberString(total_amount_by_order)}
                                </td>
                            </tr>
                        )
                    }
                }
                return null;
            })
            if (not_exist_almost_one){
                return <Alert variant={"danger"}>
                    El producto no esta en ninguna de las {this.props.orders.length} ordenes activas o en proceso.
                    <br/>
                    <small>Por favor contactar el administrador para corregir en base de datos la cantidad de productos reservados</small>
                </Alert>
            }
            return (
                <>
                    <h4>Ordenes donde aparece el producto <b>{this.props.product.code}</b></h4>
                    <Table bordered hover size={"sm"} responsive striped>
                        <thead>
                        <tr>
                            <th className={"text-center"}>Orden</th>
                            <th className={"text-center"}>Cantidad</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders}
                        <tr>
                            <th className={"text-center"}>
                                TOTAL
                            </th>
                            <th style={{textAlign: "right"}}>
                                {formatNumberString(total_amount)}
                            </th>
                        </tr>
                        <tr className={"text-primary"}>
                            <td className={"text-center"}>
                                RESERV.
                            </td>
                            <td style={{textAlign: "right"}}>
                                {formatNumberString(this.props.product.reserved)}
                            </td>
                        </tr>
                        {total_amount < this.props.product.reserved?(
                            <tr className={"text-danger"}>
                                <td className={"text-center"}>
                                    FALTANTES
                                </td>
                                <td style={{textAlign: "right"}}>
                                    {formatNumberString(this.props.product.reserved - total_amount)}
                                </td>
                            </tr>

                        ):null}
                        {total_amount > this.props.product.reserved?(
                            <tr className={"text-danger"}>
                                <td className={"text-center"}>
                                    SOBRANTES
                                </td>
                                <td style={{textAlign: "right"}}>
                                    {formatNumberString(total_amount - this.props.product.reserved)}
                                </td>
                            </tr>
                        ):null}
                        </tbody>
                    </Table>
                    {total_amount !== this.props.product.reserved ? (
                        <Alert variant={"danger"}>Existe diferencia de productos
                            <br/>
                            <small>Por favor contactar el administrador para corregir en base de datos la cantidad de productos reservados</small>
                        </Alert>
                    ): null}
                </>
            );
        }
        else {
            return <Alert variant={"warning"}>
                No hay ordenes disponibles
                <br/>
                <small>Por favor contactar el administrador para corregir en base de datos la cantidad de productos reservados</small>
            </Alert>
        }
    }
}
export default ModalViewProductInOrders;