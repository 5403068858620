import React, {Component} from "react";
import {Nav} from "react-bootstrap";
import properties from "../properties";
import {AiOutlineLogout} from "react-icons/ai";

class HeaderLinks extends Component{
    render() {
        return(
            <>
                <Nav className="me-auto">
                </Nav>
                <Nav>
                    <Nav.Link
                        href={properties.url_logout}
                    >
                        <i><AiOutlineLogout /></i><p>Salir</p>
                    </Nav.Link>
                </Nav>
            </>
        )
    }
}

export default HeaderLinks;