import React, {Component} from "react";
import {Button, FormControl, Spinner} from "react-bootstrap";
import ModalSearchProduct from "../orders/ModalSearchProduct";
import utilizeFocus from "../../util/focusElement";
import {AiFillDelete} from "react-icons/ai";
import {BiCheckDouble} from "react-icons/bi";
import SweetAlert from "react-bootstrap-sweetalert";

class RowFormProductPurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: this.props.product ? this.props.product.code : 0,
            name: this.props.product ? this.props.product.name : '',
            iva: this.props.product ? this.props.product.iva : 0,
            weight: this.props.product ? this.props.product.weight : 0,
            value: this.props.product ? this.props.product.value : 0,
            amount: this.props.product ? this.props.product.amount : 0,
            saved: !!(this.props.product && this.props.product.code),
            was_modify: false,
            ready_load_inventory: false,
            load_inventory: 0,
            modal_error: null,
            modalSearch: false,
        }
        this.inputSearch = utilizeFocus()
    }

    render() {
        let key = this.props.product.id;
        let refInputCode = this.props.inputCodeProducts[key] ? this.props.inputCodeProducts[key].ref : null
        let refInputAmount = this.props.inputCantProducts[key] ? this.props.inputCantProducts[key].ref : null
        let refInputValue = this.props.inputValuesProducts[key] ? this.props.inputValuesProducts[key].ref : null

        return (
            <tr>
                <ModalSearchProduct {...this.props} show={this.state.modalSearch}
                                    onHide={() => this.setModalSearchShow(false)}
                                    setProductSelected={this.setProductSelected}
                                    focusSearch={this.inputSearch}
                />
                <td style={{minWidth: "100px"}}>
                    <FormControl
                        type="number"
                        size={"sm"}
                        value={this.state.code}
                        ref={refInputCode}
                        onChange={
                            event => {
                                this.searchProductLocal(event.target.value)
                            }
                        }
                        placeholder={"123"}
                        id={`product.${key}.code`}
                        disabled={!this.props.enabled}
                        style={{textAlign: 'center'}}
                        onKeyPress={
                            (event) => {
                                if (event.key === "Enter") {
                                    if (this.state.code && this.state.name) {
                                        this.props.inputCantProducts[key].setFocus();
                                    } else {
                                        this.setModalSearchShow(true);
                                        setTimeout(
                                            () => this.inputSearch.setFocus(), 300
                                        )
                                    }
                                }
                            }
                        }
                        onFocus={e => e.target.select()}
                    />
                </td>
                <td style={{minWidth: "150px"}}>
                    {this.state.name ? (
                        <div className={"text-overflow"}>
                            {this.state.name}
                        </div>
                    ) : (
                        <Button
                            size={"sm"}
                            onClick={() => {
                                this.setModalSearchShow(true);
                                setTimeout(
                                    () => this.inputSearch.setFocus(), 300
                                )
                            }}
                        >
                            Buscar
                        </Button>
                    )}
                </td>
                <td className={"text-center"} style={{minWidth: "65px"}}>
                    {this.state.was_modify ? (
                        this.state.ready_load_inventory ? (
                            <FormControl
                                type="text"
                                value={new Intl.NumberFormat('de-DE').format(
                                    this.state.load_inventory
                                )}
                                readOnly
                                style={{textAlign: 'right'}}
                            />
                        ) : (
                            <Spinner animation={"border"}/>
                        )
                    ) : null}
                </td>
                <td style={{minWidth: "100px"}}>
                    <FormControl
                        type="number"
                        size={"sm"}
                        value={this.state.amount}
                        onChange={event => {
                            this.setState({
                                amount: event.target.value
                            });
                        }}
                        placeholder={"0"}
                        disabled={!this.props.enabled}
                        style={{textAlign: 'right'}}
                        id={`product.${key}.amount`}
                        ref={refInputAmount}
                        onKeyPress={
                            (event) => {
                                if (event.key === "Enter") {
                                    this.props.inputValuesProducts[key].setFocus();
                                }
                            }
                        }
                        onFocus={e => e.target.select()}
                    />
                </td>
                <td style={{minWidth: "120px"}}>
                    <FormControl
                        type="number"
                        size={"sm"}
                        id={`product.${key}.value`}
                        value={this.state.value}
                        onChange={event => {
                            this.setState({
                                value: event.target.value,
                            });
                        }}
                        placeholder={"0"}
                        disabled={!this.props.enabled}
                        style={{textAlign: 'right'}}
                        ref={refInputValue}
                        onKeyPress={
                            (event) => {
                                if (event.key === "Enter") {
                                    this.props.sentValuesLocal(key,
                                        this.state.code,
                                        this.state.amount,
                                        this.state.value,
                                        this.state.iva,
                                        this.state.name,
                                        this.state.weight
                                    )
                                    this.setState({
                                        saved: true
                                    });
                                }
                            }
                        }
                        onFocus={e => e.target.select()}
                    />
                </td>
                <td style={{minWidth: "45px", textAlign: 'right'}}>
                    {this.state.iva}
                </td>
                <td style={{minWidth: "120px"}}>
                    <div className={"text-overflow"} style={{textAlign: 'right'}}>
                        ${new Intl.NumberFormat('de-DE').format(
                        this.state.amount * this.state.value
                    )}
                    </div>
                </td>
                <td className={"text-center"}>
                    {this.props.enabled ? (
                        this.isDeletable(key) ? (
                            <Button
                                variant={"outline-danger"}
                                onClick={() => {
                                    this.props.deleteItem(this.props.product, key)
                                }}
                                size={"sm"}
                                disabled={!this.props.enabled}
                            >
                                <i><AiFillDelete/></i>
                            </Button>
                        ) : (
                            <Button
                                variant={"outline-info"}
                                onClick={() => {
                                    this.props.deleteItemEmpty(key)
                                }}
                                size={"sm"}
                            >
                                <i><AiFillDelete/></i>
                            </Button>
                        )
                    ) : null}
                    {this.state.saved ? (
                        <i variant={"outline-success"}><BiCheckDouble/></i>
                    ) : null}
                </td>
            </tr>
        )
    }

    searchProductLocal = (value) => {
        this.setState({
            code: value,
            saved: false,
            ready_load_inventory: false
        });
        let product = this.searchLocal(value);
        if (product) {
            this.setState({
                name: product.name,
                value: product.cost * (1 + product.iva/100),
                iva: product.iva,
                weight: product.weight,
                was_modify: true
            });
            this.loadProductInventory(product.code)
        } else {
            this.setState({
                name: "",
                value: 0,
                iva: 0,
                weight: 0,
                was_modify: false
            })
        }
    }

    searchLocal = (id) => {
        for (const valueKey in this.props.productsStorage) {
            if (this.props.productsStorage[valueKey].id === id) {
                if (this.props.purchase.provider) {
                    let providers = this.props.productsStorage[valueKey].providers;
                    for (let providersKey in providers) {
                        if (providers[providersKey].id === this.props.purchase.provider.id) {
                            return this.props.productsStorage[valueKey]
                        }
                    }
                } else {
                    return this.props.productsStorage[valueKey]
                }
            }
        }
        return false
    }

    setModalSearchShow = (modalSearch) => {
        this.setState({modalSearch})
    }

    setProductSelected = (product) => {
        this.setState({
            code: product.code,
            saved: false,
            name: product.name,
            value: product.cost *(1 + product.iva/100),
            iva: product.iva,
            weight: product.weight,
            was_modify: true,
            ready_load_inventory: true,
            load_inventory: product.amount,
        });
        let key = this.props.product.id;
        setTimeout(
            () =>this.props.inputCantProducts[key].setFocus(), 300
        );
    }

    isDeletable = (key) => {
        let purchase = this.props.purchase;
        let product_search = false
        for (const id in purchase.products) {
            if (purchase.products[id].id === key) {
                product_search = purchase.products[id]
            }
        }
        if (product_search && product_search.code && product_search.name) {
            return true
        }
        return false
    }

    loadProductInventory = (product_id) => {
        this.setState({
            ready_load_inventory: false,
            load_inventory: 0,
        });
        if (product_id) {
            this.props.loadGet("/products/" + product_id, this.loadSuccess, this.loadError);
        }
    }

    loadSuccess = (product) => {
        this.setState({
            ready_load_inventory: true,
            load_inventory: product.amount,
        })
    }

    loadError = (onerror) => {
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }
}

export default RowFormProductPurchase;