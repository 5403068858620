import React, {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import ModalSearchProvider from "./ModalSearchProvider";

class ProviderInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        }
    }

    render() {
        const provider = this.props.purchase.provider;
        return (
            <Card>
                <Card.Header>
                    Información del Proveedor:
                    <Button className={"float-end"} size="sm" variant={"info"} disabled={!this.props.enabled}
                            onClick={() => this.setModalShow(true)}
                    >
                        Cambiar Proveedor
                    </Button>
                </Card.Header>
                <Card.Body>
                    <ModalSearchProvider {...this.props} show={this.state.modal}
                                       onHide={() => this.setModalShow(false)}
                    />
                    {provider ? (
                        <>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={3} controlId={"validationIdentification"}>
                                    <FormLabel>Indentificación (NIT):</FormLabel>
                                    <FormControl readOnly value={provider.identification}
                                                 className={"text-center"}/>
                                </FormGroup>
                                <FormGroup as={Col} md={5} controlId={"validationName"}>
                                    <FormLabel>Nombre:</FormLabel>
                                    <FormControl readOnly value={provider.name}/>
                                </FormGroup>
                                <FormGroup as={Col} md={4} controlId={"validationCity"}>
                                    <FormLabel>Ciudad/Departamento:</FormLabel>
                                    <FormControl readOnly value={provider.city + " - " + provider.department}/>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup as={Col} md={4} controlId={"validationEstablishment"}>
                                    <FormLabel>Establecimiento:</FormLabel>
                                    <FormControl readOnly value={provider.establishment_name}/>
                                </FormGroup>
                                <FormGroup as={Col} md={4} controlId={"validationPhone"}>
                                    <FormLabel>Teléfono:</FormLabel>
                                    <FormControl readOnly value={provider.phone}/>
                                </FormGroup>
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <h4>No hay información disponible del proveedor</h4>
                        </Row>
                    )}
                </Card.Body>
            </Card>
        );
    }

    setModalShow = (show) => {
        this.setState({modal: show})
    }
}

export default ProviderInformation;