let host = "https://login.lidercamargo.com"
let api_host = "https://api.lidercamargo.com"
let client_id = "5rvt1ii8k729ms0f6b40jl6p4t"
let h_login = "https://admin.lidercamargo.com/login"
let h_logout = "https://admin.lidercamargo.com/logout"
let basic = "Basic NXJ2dDFpaThrNzI5bXMwZjZiNDBqbDZwNHQ6MTIzNGExcmlhZHNuMnNvcHZwY24xczc1czJrOGRnNnF0MHVyczRtcDlocm9waG81a3A2NQ=="
let bucket_host = 'https://co-lider-camargo-documents.s3.amazonaws.com'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    host = "https://authtest.lidercamargo.com"
    api_host = "https://apitest.lidercamargo.com"
    client_id = "fasbs80fanbkgk77uslohts5j"
    h_login = "http://localhost:3000/login"
    h_logout = "http://localhost:3000/logout"
    basic = "Basic ZmFzYnM4MGZhbmJrZ2s3N3VzbG9odHM1ajoxNzdhaG5qdjdrcWk1MHFlN3M0aWp1MG9xaHMxNGRmbHE0Y3JnOWxpNGV1dWxwbmRsZzRy"
}

if (document.location.hostname === "admin-lider-camargo.web.app" || document.location.origin === "https://admin-lider-camargo.web.app") {
    host = "https://lidercamargo.auth.us-east-1.amazoncognito.com"
    api_host = "https://uw6d6ndalj.execute-api.us-east-1.amazonaws.com/Admin"
    h_login = "https://admin-lider-camargo.web.app/login"
    h_logout = "https://admin-lider-camargo.web.app/logout"
}

let properties = {
    url_login : host + "/login?client_id=" + client_id + "&response_type=code&redirect_uri=" + h_login,
    url_token : host + "/oauth2/token?grant_type=authorization_code&redirect_uri=" + h_login,
    url_token_r : host +"/oauth2/token?grant_type=refresh_token&client_id="+client_id,
    url_logout : host+ "/logout?client_id=" + client_id + "&logout_uri=" + h_logout,
    url_user_inf : host + "/oauth2/userInfo",
    url_revoke: host + "/oauth2/revoke",
    basic,
    api_host,
    bucket_host
}
export default properties;