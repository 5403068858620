import {Component} from "react";
import {Card} from "react-bootstrap";

class SearchIn extends Component{
    render() {
        return(
            <Card>
                <Card.Header>
                    Resultados de busqueda
                </Card.Header>
                <Card.Body>

                </Card.Body>
            </Card>
        )
    }
}
export default SearchIn;