import {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, Row, Spinner, Table} from "react-bootstrap";
import cities from "../clients/cities";
import {FiCheck, FiRefreshCw, FiX, FiXOctagon} from "react-icons/fi";


const LOADING = "LOADING"
const PROCESSING = "PROCESSING"
const SENDING = "SENDING"

class Migration extends Component {
    fileReader = new FileReader();

    constructor(props) {
        super(props);
        this.state = {
            status: false,
            array: [],
            data: [],
            rowSending: [],
            providers: []
        }
    }

    headersBefore = []
    headerAfter = []
    url = ""
    enable = false
    update = false
    name = ""

    render() {
        return (
            <Card>
                <Card.Header>
                    Migración de datos de {this.name}
                </Card.Header>
                <Card.Body>
                    <h5>Importacíon de datos de un archivo CSV</h5>
                    <Row>
                        <FormGroup controlId="formFile" className="mb-3" as={Col} md={6}>
                            <FormControl type="file" accept={".csv"}
                                         onChange={event => {
                                             this.setState({
                                                 file: event.target.files[0],
                                                 data: []
                                             });
                                             this.processFile(event.target.files[0]);
                                         }}
                            />
                        </FormGroup>
                        <Col className={"text-center"} md={3}>
                            <Button variant={"warning"} onClick={() => this.processArrayData()}
                                    disabled={this.state.status !== LOADING}
                            >
                                <i><FiRefreshCw/></i> Procesar Datos
                            </Button>
                        </Col>
                        <Col className={"text-center"} md={3}>
                            {this.enable ? (
                                <Button variant={"danger"} onClick={() => this.sendData()}
                                        disabled={this.state.status !== PROCESSING}

                                >
                                    <i><FiX/></i> Enviar datos
                                </Button>
                            ) : (
                                <Button variant={"info"} onClick={() => this.sendData()}
                                        disabled={this.state.status !== PROCESSING}

                                >
                                    <i><FiX/></i> Envio de Prueba
                                </Button>
                            )}

                        </Col>
                    </Row>
                    {this.state.array.length > 0 && this.state.status === LOADING ? (
                        <Table size={"sm"} striped hover responsive bordered style={{fontSize: "10px"}}>
                            <thead>
                            <tr key={"header"}>
                                {this.headersBefore.map((header) => (
                                        <th>{header}</th>
                                    )
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.array.map((item) => (
                                <tr key={item.id}>
                                    {Object.values(item).map((value) => (
                                        <td>{value}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    ) : null}

                    {this.state.data.length > 0 && this.state.status === PROCESSING ? (
                        <Table size={"sm"} striped hover responsive bordered style={{fontSize: "10px"}}>
                            <thead>
                            <tr key={"header"}>
                                {this.headerAfter.map((header) => (
                                        <th>{header}</th>
                                    )
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.data.map((item) => (
                                <tr key={item.id}>
                                    {Object.values(item).map((value) => (
                                        <td>{JSON.stringify(value)}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    ) : null}
                    {this.state.status === SENDING ? (
                        <>
                            <p>Enviando datos...., por favor espere</p>
                            {this.state.rowSending.length > 0 ? (
                                <Table size={"sm"} striped hover responsive bordered style={{fontSize: "9px"}}>
                                    <thead>
                                    <tr key={"header"}>
                                        <th></th>
                                        {this.headerAfter.map((header) => (
                                                <th>{header}</th>
                                            )
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.rowSending.map((item) => (
                                        <RowSending {...this.props}
                                                    item={item}
                                                    url={this.url}
                                                    enable={this.enable}
                                                    update={this.update}
                                        />
                                    ))}
                                    </tbody>
                                </Table>
                            ) : null}
                        </>
                    ) : null}

                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        )
    }

    processFile = (file) => {
        if (file) {
            this.fileReader.onload = (event) => {
                const text = event.target.result;
                this.csvToArrayData(text);
            }
            this.fileReader.readAsText(file);
        }
    }

    csvToArrayData = (string) => {
        let csvRows = string.split("\n");
        const array = csvRows.map(row => {
            let values = row.split(";");
            const obj = this.headersBefore.reduce((object, header, index) => {
                object[header] = values[index];
                return object
            }, {});
            return obj;
        });
        this.setState({
            array: array,
            status: LOADING
        });
    }

    processArrayData = () => {
    }

    sendData = () => {
        this.setState({
            status: SENDING
        });
        let i = 0
        this.addRow(i);
    }
    addRow = (i) => {
        setTimeout(() => {
            let localData = this.state.rowSending;
            if (i < this.state.data.length) {
                localData.push(this.state.data[i]);
                this.setState({
                    rowSending: localData
                });
                this.addRow(i + 1)
            }
        }, 500);
    }

    searchCityLocalInformation = (ciudad) => {
        let ciudadClean = ciudad.replaceAll(".", "").replaceAll(" ", "").trim().toLocaleLowerCase();
        ciudadClean = ciudadClean.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        for (let department in cities) {
            for (let citi of cities[department]) {
                let citiLocal = citi.replaceAll(" ", "").trim().toLocaleLowerCase();
                citiLocal = citiLocal.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if (Object.is(citiLocal, ciudadClean) ||
                    citiLocal.includes(ciudadClean)
                ) {
                    return citi;
                }
            }
        }
        return ""
    }

    searchDepartmentLocalInformation = (deparment) => {
        let departmentClean = deparment.replaceAll(".", "").replaceAll(" ", "").trim().toLocaleLowerCase();
        departmentClean = departmentClean.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        for (let department in cities) {
            let departmentLocal = department.replaceAll(" ", "").trim().toLocaleLowerCase();
            departmentLocal = departmentLocal.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (Object.is(departmentLocal, departmentClean) ||
                departmentLocal.includes(departmentClean)
            ) {
                return department;
            }
        }
        return ""
    }

    searchDepartmentLocalInformationWithCiti = (ciudad) => {
        let ciudadClean = ciudad.replaceAll(".", "").replaceAll(" ", "").trim().toLocaleLowerCase();
        ciudadClean = ciudadClean.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        for (let department in cities) {
            for (let citi of cities[department]) {
                let citiLocal = citi.replaceAll(" ", "").trim().toLocaleLowerCase();
                citiLocal = citiLocal.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if (Object.is(citiLocal, ciudadClean) ||
                    citiLocal.includes(ciudadClean)
                ) {
                    return department;
                }
            }
        }
        return ""
    }
}

class RowSending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false
        }
    }

    componentDidMount() {
        this.sendItem()
    }

    render() {
        return (
            <tr>
                <td>
                    {this.state.ready ? (
                        this.state.error ? (
                            <Button variant={"danger"} size={"sm"}>
                                <i><FiXOctagon/></i>
                            </Button>
                        ) : (
                            <Button variant={"success"} size={"sm"}>
                                <i><FiCheck/></i>
                            </Button>
                        )
                    ) : (
                        <Spinner animation={"border"}/>
                    )}
                </td>
                {Object.values(this.props.item).map((value) => (
                    <td>{JSON.stringify(value)}</td>
                ))}
            </tr>
        )
    }

    sendItem = () => {
        if (this.props.enable) {
            if (this.props.update) {
                this.props.loadPut(this.props.url + "/" + this.props.item.code, this.props.item, this.loadSuccess, this.loadFail);
            } else {
                this.props.loadPost(this.props.url, this.props.item, this.loadSuccess, this.loadFail);
            }
        } else {
            setTimeout(() => this.loadSuccess(), 1000);
        }

    }

    loadSuccess = () => {
        this.setState({
            ready: true
        });
    }
    loadFail = () => {
        this.setState({
            ready: true,
            error: true,
        });
    }
}

export default Migration;