import React, {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import ModalSearchClient from "./ModalSearchClient";
import advisoriesList from "../../util/advisoriesList";

class ClientInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        }
    }

    render() {
        const client = this.props.order.client;
        return (
            <Card>
                <Card.Header>
                    Información del cliente:
                    <Button className={"float-end"} size="sm" variant={"info"} disabled={!this.props.enabled}
                            onClick={() => this.setModalShow(true)}
                    >
                        Cambiar Cliente
                    </Button>
                </Card.Header>
                <Card.Body>
                    <ModalSearchClient {...this.props} show={this.state.modal}
                                       onHide={() => this.setModalShow(false)}
                    />
                    {client ? (
                        <>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={3} controlId={"validationIdentification"}>
                                    <FormLabel>Indentificación (NIT):</FormLabel>
                                    <FormControl readOnly value={client.identification}
                                                 className={"text-center"}/>
                                </FormGroup>
                                <FormGroup as={Col} md={5} controlId={"validationName"}>
                                    <FormLabel>Nombre:</FormLabel>
                                    <FormControl readOnly value={client.name}/>
                                </FormGroup>
                                <FormGroup as={Col} md={4} controlId={"validationCity"}>
                                    <FormLabel>Ciudad/Departamento:</FormLabel>
                                    <FormControl readOnly value={client.city + " - " + client.department}/>
                                </FormGroup>
                            </Row>
                            <Row>
                                <FormGroup as={Col} md={4} controlId={"validationEstablishment"}>
                                    <FormLabel>Establecimiento:</FormLabel>
                                    <FormControl readOnly value={client.establishment_name}/>
                                </FormGroup>
                                <FormGroup as={Col} md={4} controlId={"validationPhone"}>
                                    <FormLabel>Teléfono:</FormLabel>
                                    <FormControl readOnly value={client.phone}/>
                                </FormGroup>
                                <FormGroup as={Col} sm={4} controlId={"validationAdvisory"}>
                                    <FormLabel><b>Asesor del Cliente:</b></FormLabel>
                                    <FormSelect
                                        value={this.props.order.advisory?this.props.order.advisory:""}
                                        onChange={(event) => this.changeAdvisoryOrder(event)}
                                        disabled={!this.props.enabled}
                                    >
                                        <option value={""}></option>
                                        {advisoriesList.map( (advisory) => (
                                            <option value={advisory}>{advisory}</option>
                                        ))}
                                    </FormSelect>
                                </FormGroup>
                            </Row>
                        </>
                    ) : (
                        <Row>
                            <h4>No hay información disponible del cliente</h4>
                        </Row>
                    )}
                </Card.Body>
            </Card>
        );
    }

    setModalShow = (show) => {
        this.setState({modal: show})
    }

    changeAdvisoryOrder = (event) => {
        let value = event.target.value;
        let order = this.props.order;
        order.advisory = value;
        this.props.setOrder(order, "Actualizó vendedor: " + value);
    }
}

export default ClientInformation;