import React, {Component} from "react";

class Footer extends Component {
    render() {
        return (
            <footer
                className={"footer bg-info"}
            >
                <div
                    className={"container"}
                >
                    <nav className="">
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/company/about">Nosotros</a>
                            </li>
                            <li>
                                <a href="#company/policy">Privacidad</a>
                            </li>
                            <li>
                                <a href="#company/terms">Terminos</a>
                            </li>
                        </ul>
                    </nav>
                    <div className={"float-end"}>
                        <p className="copyright float-right">
                            &copy; {1900 + new Date().getYear()}{" "}
                            <a href="https://andiazher.com" target="_blank" rel="noopener noreferrer">andiazher Inc</a>
                        </p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;