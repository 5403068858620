import React, {Component} from "react";
import {Alert, Badge, Button, ButtonGroup, Card, Dropdown, Placeholder, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FiPrinter} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import loadDate from "../../util/datesToString";
import formatNumberString from "../../util/formatNumberString";
import capitalizeString from "../../util/capitalizeString";

class Purchases extends Component{
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            purchases: null,
            modal: null
        }
    }

    componentDidMount() {
        this.loadPurchases()
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Compras
                    <NavLink to={"/admin/new/purchases"}>
                        <Button className={"float-end"} size="sm" variant={"primary"}>
                            <i><GrAdd/></i> Crear Nuevo
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.ready ? (
                        <>
                            {this.state.error ? (
                                <Alert variant={"danger"}>
                                    <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                    <p>
                                        {this.state.error}
                                    </p>
                                    <hr/>
                                    <Button onClick={() => this.loadTableOrders()} variant={"dark"}>
                                        Intentar nuevamente
                                    </Button>
                                </Alert>
                            ): this.state.purchases }
                        </>
                    ):(
                        <>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
                <Card.Footer/>
            </Card>
        )
    }

    loadPurchases = () => {}

    loadContent = (data) => {
        this.setState({
            ready: true,
            purchases: this.paintPurchases(data)
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    paintPurchases = (data) => {
        if(data && data.length > 0) {
            return (
                <Table striped hover size={"sm"} responsive  bordered
                       variant={this.loadColorTable()}
                >
                    <thead //className={"lidercamargo-background"
                    >
                    <tr className={"text-center"}>
                        <th>id</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Ciudad</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                    </thead>
                    {this.paintRowsPurchases(data)}
                </Table>
            )
        } else {
            return (<p>No hay ordenes disponibles</p>)
        }
    }

    paintRowsPurchases = (data) => {
        const rows = data.map( (purchase, key) =>
            <tr key={key}>
                <td className={"text-center"}>
                    <NavLink to={"/admin/edit/purchases/" + purchase.id}>
                        <Badge bg={this.loadColor()}>
                            {purchase.id}
                        </Badge>
                    </NavLink>
                </td>
                <td className={"text-center"}>{loadDate(purchase.date)}</td>
                <td>{capitalizeString(purchase.provider.name)}</td>
                <td>{purchase.provider.city} ({purchase.provider.department})</td>
                <td style={{textAlign: 'right'}}>${formatNumberString(purchase.total)}</td>
                <td className={"text-center"}>
                    <Dropdown as={ButtonGroup} drop={"down"}>
                        <NavLink to={"/admin/edit/purchases/" + purchase.id}
                                 className={"btn btn-primary btn-sm"}
                        >
                            <>Editar</>
                        </NavLink>
                        <Dropdown.Toggle split id={"dropdown-split-orders-" + key} size={"sm"}/>
                        <Dropdown.Menu>
                            <NavLink to={"/admin/print/purchases/" + purchase.id }
                                     className={"dropdown-item"}
                            >
                                <><i><FiPrinter/></i> Imprimir Compra</>
                            </NavLink>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        )
        return (
            <tbody>{rows}</tbody>
        )
    }

    loadColorTable = () => {
        return ""
    }

    loadColor = () => {
        return "success"
    }
}
export default Purchases;