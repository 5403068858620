import React, {Component} from "react";
import {
    Alert,
    Button,
    Card,
    FormControl,
    FormFloating,
    OverlayTrigger,
    Placeholder,
    Table,
    Tooltip
} from "react-bootstrap";
import {FiEdit} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import {NavLink} from "react-router-dom";
import {Formik, Form} from 'formik';
import * as Yup from "yup";

const schema = Yup.object().shape({
    q: Yup.string()
});

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: true,
            error: false,
            products: null,
            modal: null,
            query: ''
        }
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Productos
                    <NavLink to={"/admin/new/products"}>
                        <Button className={"float-end"} size="sm" variant={"primary"}>
                            <i><GrAdd/></i> Crear Nuevo
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    <Formik
                        initialValues={{
                            q: ''
                        }}
                        onSubmit={values => {
                            this.searchProduct(values)
                        }}
                        validationSchema={schema}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              errors
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormFloating className={"mb-3"}>
                                    <FormControl
                                        type={"text"}
                                        id={"query"}
                                        placeholder={"Buscar producto"}
                                        name={"q"}
                                        onChange={handleChange}
                                        value={values.q}
                                    />
                                    <label htmlFor={"query"}>Término de busqueda</label>
                                </FormFloating>
                            </Form>
                        )}
                    </Formik>
                    {this.state.ready ? (
                        <>
                            {
                                this.state.error ? (
                                    <Alert variant={"danger"}>
                                        <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                        <p>
                                            {this.state.error}
                                        </p>
                                        <hr/>
                                        <Button onClick={() => this.searchProduct(this.state.query)} variant={"dark"}>
                                            Intentar nuevamente
                                        </Button>
                                    </Alert>
                                ) :(
                                    <>
                                        {this.state.products}
                                    </>
                                )
                            }
                        </>
                    ): (
                        <>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={11} />
                                <Placeholder xs={10} />
                                <Placeholder xs={9} />
                                <Placeholder xs={10} />
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
                <Card.Footer/>
            </Card>
        )
    }

    searchProduct = (values) => {
        this.setState({
            query: values
        })
        let query = values.q.toUpperCase();
        if(query){
            this.setState({
                ready: false
            });
            this.props.loadGet("/products?q="+query, this.loadContent, this.loadError)
        } else {
            this.setState({
                ready: true,
                products: null,
                error: false
            })
        }
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            products: this.paintProducts(data)
        })
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }


    paintProducts = (data) => {
        if (data && data.length > 0) {
            return (
                <div>
                    <h5 className={"text-center"}>Mostrando resultados de busqueda:</h5>
                    <Table striped hover size={"sm"} responsive>
                        <thead>
                        <tr className={"text-center"}>
                            <th>Código</th>
                            <th>Descripción</th>
                            <th>Costo</th>
                            <th>Iva</th>
                            <th>Peso</th>
                            <th>Cantidad</th>
                            <th>Reservados</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        {this.paintRowsProducts(data)}
                    </Table>
                </div>
            )
        } else{
            return(
                <h5 className={"text-center"}>No se encontraron resultados</h5>
            )
        }
    }

    paintRowsProducts = (data) => {
        const rows = data.map( (product, key) => (
            <tr key={key}>
                <td className={"text-center"}>{product.code}</td>
                <td>{product.name}</td>
                <td className={"text-end"}>${new Intl.NumberFormat('de-DE').format(product.cost)}</td>
                <td className={"text-end"}>{product.iva_mandatory? "*": null} {product.iva}% </td>
                <td className={"text-end"}>{new Intl.NumberFormat('de-DE').format(product.weight)}kg</td>
                <td className={"text-end"}>{new Intl.NumberFormat('de-DE').format(product.amount)}</td>
                <td className={"text-end"}>{new Intl.NumberFormat('de-DE').format(product.reserved)}</td>
                <td className={"text-center"}>
                    <OverlayTrigger
                        key={{key}+"tooltip"}
                        placement={"bottom"}
                        overlay={
                            <Tooltip id={"tooltip"}>
                                Ver Producto
                            </Tooltip>
                        }
                    >
                        <NavLink to={"/admin/edit/products/"+product.id}>
                            <Button size={"sm"}>
                                <i><FiEdit/></i>
                            </Button>
                        </NavLink>
                    </OverlayTrigger>
                </td>
            </tr>
        ));
        return (
            <tbody>{rows}</tbody>
        )
    }
}

export default Products;