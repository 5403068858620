import {useHistory} from "react-router-dom";
import {Button} from "react-bootstrap";
import React from "react";
import {BiArrowBack} from "react-icons/bi";

function BackButton(props) {
    let history = useHistory();
    return (
        <Button variant={"secondary"}
                size={props.size}
                onClick={e => {
                    e.stopPropagation();
                    history.goBack();
                }}
        >
            <i><BiArrowBack/></i> Atras
        </Button>
    )
}
export default BackButton;