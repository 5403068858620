let cities = {
    '--': [],
    "Bogotá DC": [
        "Bogotá DC"
    ],
    "Amazonas": [
        "Leticia",
        "El Encanto",
        "La Chorrera",
        "La Pedrera",
        "La Victoria",
        "Puerto Arica",
        "Puerto Nariño",
        "Puerto Santander",
        "Tarapacá",
        "Puerto Alegría",
        "Miriti Paraná"
    ],
    "Antioquia":[
        "Medellín",
        "Abejorral",
        "Abriaquí",
        "Alejandría",
        "Amagá",
        "Amalfi",
        "Andes",
        "Angelópolis",
        "Angostura",
        "Anorí",
        "Anza",
        "Apartadó",
        "Arboletes",
        "Argelia",
        "Armenia",
        "Barbosa",
        "Bello",
        "Betania",
        "Betulia",
        "Ciudad Bolívar",
        "Briceño",
        "Buriticá",
        "Cáceres",
        "Caicedo",
        "Caldas",
        "Campamento",
        "Cañasgordas",
        "Caracolí",
        "Caramanta",
        "Carepa",
        "Carolina",
        "Caucasia",
        "Chigorodó",
        "Cisneros",
        "Cocorná",
        "Concepción",
        "Concordia",
        "Copacabana",
        "Dabeiba",
        "Don Matías",
        "Ebéjico",
        "El Bagre",
        "Entrerrios",
        "Envigado",
        "Fredonia",
        "Giraldo",
        "Girardota",
        "Gómez Plata",
        "Guadalupe",
        "Guarne",
        "Guatapé",
        "Heliconia",
        "Hispania",
        "Itagui",
        "Ituango",
        "Belmira",
        "Jericó",
        "La Ceja",
        "La Estrella",
        "La Pintada",
        "La Unión",
        "Liborina",
        "Maceo",
        "Marinilla",
        "Montebello",
        "Murindó",
        "Mutatá",
        "Nariño",
        "Necoclí",
        "Nechí",
        "Olaya",
        "Peñol",
        "Peque",
        "Pueblorrico",
        "Puerto Berrío",
        "Puerto Nare",
        "Puerto Triunfo",
        "Remedios",
        "Retiro",
        "Rionegro",
        "Sabanalarga",
        "Sabaneta",
        "Salgar",
        "San Francisco",
        "San Jerónimo",
        "San Luis",
        "San Pedro",
        "San Rafael",
        "San Roque",
        "San Vicente",
        "Santa Bárbara",
        "Santo Domingo",
        "El Santuario",
        "Segovia",
        "Sopetrán",
        "Támesis",
        "Tarazá",
        "Tarso",
        "Titiribí",
        "Toledo",
        "Turbo",
        "Uramita",
        "Urrao",
        "Valdivia",
        "Valparaíso",
        "Vegachí",
        "Venecia",
        "Yalí",
        "Yarumal",
        "Yolombó",
        "Yondó",
        "Zaragoza",
        "San Pedro de Uraba",
        "Santafé de Antioquia",
        "Santa Rosa de Osos",
        "San Andrés de Cuerquía",
        "Vigía del Fuerte",
        "San José de La Montaña",
        "San Juan de Urabá",
        "El Carmen de Viboral",
        "San Carlos",
        "Frontino",
        "Granada",
        "Jardín",
        "Sonsón",
    ],
    "Arauca":[
        "Arauquita",
        "Cravo Norte",
        "Fortul",
        "Puerto Rondón",
        "Saravena",
        "Tame",
        "Arauca",
    ],
    "Archipiélago de San Andrés, Providencia y Santa Catalina":[
        "Providencia",
        "San Andres"
    ],
    "Atlántico":[
        "Barranquilla",
        "Baranoa",
        "Candelaria",
        "Galapa",
        "Luruaco",
        "Malambo",
        "Manatí",
        "Piojó",
        "Polonuevo",
        "Sabanagrande",
        "Sabanalarga",
        "Santa Lucía",
        "Santo Tomás",
        "Soledad",
        "Suan",
        "Tubará",
        "Usiacurí",
        "Juan de Acosta",
        "Palmar de Varela",
        "Campo de La Cruz",
        "Repelón",
        "Puerto Colombia",
        "Ponedera",
    ],
    "Bolívar":[
        "Achí",
        "Arenal",
        "Arjona",
        "Arroyohondo",
        "Calamar",
        "Cantagallo",
        "Cicuco",
        "Córdoba",
        "Clemencia",
        "El Guamo",
        "Magangué",
        "Mahates",
        "Margarita",
        "Montecristo",
        "Mompós",
        "Morales",
        "Norosí",
        "Pinillos",
        "Regidor",
        "Río Viejo",
        "San Estanislao",
        "San Fernando",
        "San Juan Nepomuceno",
        "Santa Catalina",
        "Santa Rosa",
        "Simití",
        "Soplaviento",
        "Talaigua Nuevo",
        "Tiquisio",
        "Turbaco",
        "Turbaná",
        "Villanueva",
        "Barranco de Loba",
        "Santa Rosa del Sur",
        "Hatillo de Loba",
        "El Carmen de Bolívar",
        "San Martín de Loba",
        "Altos del Rosario",
        "San Jacinto del Cauca",
        "San Pablo de Borbur",
        "San Jacinto",
        "El Peñón",
        "Cartagena",
        "María la Baja",
        "San Cristóbal",
        "Zambrano",
    ],
    "Boyacá": [
        "Almeida",
        "Aquitania",
        "Arcabuco",
        "Belén",
        "Berbeo",
        "Betéitiva",
        "Boavita",
        "Boyacá",
        "Briceño",
        "Buena Vista",
        "Busbanzá",
        "Caldas",
        "Campohermoso",
        "Cerinza",
        "Chinavita",
        "Chiquinquirá",
        "Chíquiza",
        "Chiscas",
        "Chita",
        "Chitaraque",
        "Chivatá",
        "Chivor",
        "Ciénega",
        "Cómbita",
        "Coper",
        "Corrales",
        "Covarachía",
        "Cubará",
        "Cucaita",
        "Cuítiva",
        "Duitama",
        "El Cocuy",
        "El Espino",
        "Firavitoba",
        "Floresta",
        "Gachantivá",
        "Gameza",
        "Garagoa",
        "Guacamayas",
        "Guateque",
        "Guayatá",
        "Güicán",
        "Iza",
        "Jenesano",
        "Jericó",
        "La Capilla",
        "La Uvita",
        "La Victoria",
        "Labranzagrande",
        "Macanal",
        "Maripí",
        "Miraflores",
        "Mongua",
        "Monguí",
        "Moniquirá",
        "Motavita",
        "Muzo",
        "Nobsa",
        "Nuevo Colón",
        "Oicatá",
        "Otanche",
        "Pachavita",
        "Páez",
        "Paipa",
        "Pajarito",
        "Panqueba",
        "Pauna",
        "Paya",
        "Paz de Río",
        "Pesca",
        "Pisba",
        "Puerto Boyacá",
        "Quípama",
        "Ramiriquí",
        "Ráquira",
        "Rondón",
        "Saboyá",
        "Sáchica",
        "Samacá",
        "San Eduardo",
        "San José de Pare",
        "San Luis de Gaceno",
        "San Mateo",
        "San Miguel de Sema",
        "San Pablo de Borbur",
        "Santa María",
        "Santa Rosa de Viterbo",
        "Santa Sofía",
        "Santana",
        "Sativanorte",
        "Sativasur",
        "Siachoque",
        "Soatá",
        "Socha",
        "Socotá",
        "Sogamoso",
        "Somondoco",
        "Sora",
        "Soracá",
        "Sotaquirá",
        "Susacón",
        "Sutamarchán",
        "Sutatenza",
        "Tasco",
        "Tenza",
        "Tibaná",
        "Tibasosa",
        "Tinjacá",
        "Tipacoque",
        "Toca",
        "Togüí",
        "Tópaga",
        "Tota",
        "Tunja",
        "Tununguá",
        "Turmequé",
        "Tuta",
        "Tutazá",
        "Umbita",
        "Ventaquemada",
        "Villa de Leyva",
        "Viracachá",
        "Zetaquira",
    ],
    "Caldas":[
        "Aguadas",
        "Anserma",
        "Aranzazu",
        "Belalcázar",
        "Chinchiná",
        "Filadelfia",
        "La Dorada",
        "La Merced",
        "Manizales",
        "Manzanares",
        "Marmato",
        "Marquetalia",
        "Marulanda",
        "Neira",
        "Norcasia",
        "Pácora",
        "Palestina",
        "Pensilvania",
        "Riosucio",
        "Risaralda",
        "Salamina",
        "Samaná",
        "San José",
        "Supía",
        "Victoria",
        "Villamaría",
        "Viterbo",
    ],
    "Caquetá":[
        "Albania",
        "Belén de Los Andaquies",
        "Cartagena del Chairá",
        "Curillo",
        "El Doncello",
        "El Paujil",
        "Florencia",
        "La Montañita",
        "Milán",
        "Morelia",
        "Puerto Rico",
        "San José del Fragua",
        "San Vicente del Caguán",
        "Solano",
        "Solita",
        "Valparaíso",
    ],
    "Casanare":[
        "Aguazul",
        "Chámeza",
        "Hato Corozal",
        "La Salina",
        "Maní",
        "Monterrey",
        "Nunchía",
        "Orocué",
        "Paz de Ariporo",
        "Pore",
        "Recetor",
        "Sabanalarga",
        "Sácama",
        "San Luis de Gaceno",
        "Támara",
        "Tauramena",
        "Trinidad",
        "Villanueva",
        "Yopal",
    ],
    "Cauca":[
        "Almaguer",
        "Argelia",
        "Balboa",
        "Bolívar",
        "Buenos Aires",
        "Cajibío",
        "Caldono",
        "Caloto",
        "Corinto",
        "El Tambo",
        "Florencia",
        "Guachené",
        "Guapi",
        "Inzá",
        "Jambaló",
        "La Sierra",
        "La Vega",
        "López",
        "Mercaderes",
        "Miranda",
        "Morales",
        "Padilla",
        "Páez",
        "Patía",
        "Piamonte",
        "Piendamó",
        "Popayán",
        "Puerto Tejada",
        "Puracé",
        "Rosas",
        "San Sebastián",
        "Santa Rosa",
        "Santander de Quilichao",
        "Silvia",
        "Sotara",
        "Suárez",
        "Sucre",
        "Timbío",
        "Timbiquí",
        "Toribio",
        "Totoró",
        "Villa Rica",
    ],
    "Cesar":[
        "Aguachica",
        "Agustín Codazzi",
        "Astrea",
        "Becerril",
        "Bosconia",
        "Chimichagua",
        "Chiriguaná",
        "Curumaní",
        "El Copey",
        "El Paso",
        "Gamarra",
        "González",
        "La Gloria",
        "La Jagua de Ibirico",
        "La Paz",
        "Manaure",
        "Pailitas",
        "Pelaya",
        "Pueblo Bello",
        "Río de Oro",
        "San Alberto",
        "San Diego",
        "San Martín",
        "Tamalameque",
        "Valledupar",
    ],
    "Chocó":[
        "Acandí",
        "Alto Baudo",
        "Atrato",
        "Bagadó",
        "Bahía Solano",
        "Bajo Baudó",
        "Belén de Bajira",
        "Bojaya",
        "Carmen del Darien",
        "Cértegui",
        "Condoto",
        "El Cantón del San Pablo",
        "El Carmen de Atrato",
        "El Litoral del San Juan",
        "Istmina",
        "Juradó",
        "Lloró",
        "Medio Atrato",
        "Medio Baudó",
        "Medio San Juan",
        "Nóvita",
        "Nuquí",
        "Quibdó",
        "Río Iro",
        "Río Quito",
        "Riosucio",
        "San José del Palmar",
        "Sipí",
        "Tadó",
        "Unguía",
        "Unión Panamericana",
    ],
    "Córdoba":[
        "Ayapel",
        "Buenavista",
        "Canalete",
        "Cereté",
        "Chimá",
        "Chinú",
        "Ciénaga de Oro",
        "Cotorra",
        "La Apartada",
        "Lorica",
        "Los Córdobas",
        "Momil",
        "Montelíbano",
        "Montería",
        "Moñitos",
        "Planeta Rica",
        "Pueblo Nuevo",
        "Puerto Escondido",
        "Puerto Libertador",
        "Purísima",
        "Sahagún",
        "San Andrés Sotavento",
        "San Antero",
        "San Bernardo del Viento",
        "San Carlos",
        "San José de Uré",
        "San Pelayo",
        "Tierralta",
        "Tuchín",
        "Valencia",
    ],
    "Cundinamarca":[
        "Agua de Dios",
        "Albán",
        "Anapoima",
        "Anolaima",
        "Apulo",
        "Arbeláez",
        "Beltrán",
        "Bituima",
        "Bojacá",
        "Cabrera",
        "Cachipay",
        "Cajicá",
        "Caparrapí",
        "Caqueza",
        "Carmen de Carupa",
        "Chaguaní",
        "Chía",
        "Chipaque",
        "Choachí",
        "Chocontá",
        "Cogua",
        "Cota",
        "Cucunubá",
        "El Colegio",
        "El Peñón",
        "El Rosal",
        "Facatativá",
        "Fomeque",
        "Fosca",
        "Funza",
        "Fúquene",
        "Fusagasugá",
        "Gachala",
        "Gachancipá",
        "Gachetá",
        "Gama",
        "Girardot",
        "Granada",
        "Guachetá",
        "Guaduas",
        "Guasca",
        "Guataquí",
        "Guatavita",
        "Guayabal de Siquima",
        "Guayabetal",
        "Gutiérrez",
        "Jerusalén",
        "Junín",
        "La Calera",
        "La Mesa",
        "La Palma",
        "La Peña",
        "La Vega",
        "Lenguazaque",
        "Macheta",
        "Madrid",
        "Manta",
        "Medina",
        "Mosquera",
        "Nariño",
        "Nemocón",
        "Nilo",
        "Nimaima",
        "Nocaima",
        "Pacho",
        "Paime",
        "Pandi",
        "Paratebueno",
        "Pasca",
        "Puerto Salgar",
        "Pulí",
        "Quebradanegra",
        "Quetame",
        "Quipile",
        "Ricaurte",
        "San Antonio del Tequendama",
        "San Bernardo",
        "San Cayetano",
        "San Francisco",
        "San Juan de Río Seco",
        "Sasaima",
        "Sesquilé",
        "Sibaté",
        "Silvania",
        "Simijaca",
        "Soacha",
        "Sopó",
        "Subachoque",
        "Suesca",
        "Supatá",
        "Susa",
        "Sutatausa",
        "Tabio",
        "Tausa",
        "Tena",
        "Tenjo",
        "Tibacuy",
        "Tibirita",
        "Tocaima",
        "Tocancipá",
        "Topaipí",
        "Ubalá",
        "Ubaque",
        "Une",
        "Útica",
        "Venecia",
        "Vergara",
        "Vianí",
        "Villa de San Diego de Ubate",
        "Villagómez",
        "Villapinzón",
        "Villeta",
        "Viotá",
        "Yacopí",
        "Zipacón",
        "Zipaquirá",
    ],
    "Guainía":[
        "Barranco Minas",
        "Cacahual",
        "Inírida",
        "La Guadalupe",
        "Mapiripana",
        "Morichal",
        "Pana Pana",
        "Puerto Colombia",
        "San Felipe",
    ],
    "Guaviare":[
        "Calamar",
        "El Retorno",
        "Miraflores",
        "San José del Guaviare",
    ],
    "Huila":[
        "Acevedo",
        "Agrado",
        "Aipe",
        "Algeciras",
        "Altamira",
        "Baraya",
        "Campoalegre",
        "Colombia",
        "Elías",
        "Garzón",
        "Gigante",
        "Guadalupe",
        "Hobo",
        "Iquira",
        "Isnos",
        "La Argentina",
        "La Plata",
        "Nátaga",
        "Neiva",
        "Oporapa",
        "Paicol",
        "Palermo",
        "Palestina",
        "Pital",
        "Pitalito",
        "Rivera",
        "Saladoblanco",
        "San Agustín",
        "Santa María",
        "Suaza",
        "Tarqui",
        "Tello",
        "Teruel",
        "Tesalia",
        "Timaná",
        "Villavieja",
        "Yaguará",
    ],
    "La Guajira":[
        "Albania",
        "Barrancas",
        "Dibula",
        "Distracción",
        "El Molino",
        "Fonseca",
        "Hatonuevo",
        "La Jagua del Pilar",
        "Maicao",
        "Manaure",
        "Riohacha",
        "San Juan del Cesar",
        "Uribia",
        "Urumita",
        "Villanueva",
    ],
    "Magdalena":[
        "Algarrobo",
        "Aracataca",
        "Ariguaní",
        "Cerro San Antonio",
        "Chivolo",
        "Ciénaga",
        "Concordia",
        "El Banco",
        "El Piñon",
        "El Retén",
        "Fundación",
        "Guamal",
        "Nueva Granada",
        "Pedraza",
        "Pijiño del Carmen",
        "Pivijay",
        "Plato",
        "Pueblo Viejo",
        "Remolino",
        "Sabanas de San Angel",
        "Salamina",
        "San Sebastián de Buenavista",
        "San Zenón",
        "Santa Ana",
        "Santa Bárbara de Pinto",
        "Santa Marta",
        "Sitionuevo",
        "Tenerife",
        "Zapayán",
        "Zona Bananera",
    ],
    "Meta":[
        "Acacias",
        "Barranca de Upía",
        "Cabuyaro",
        "Castilla la Nueva",
        "Cubarral",
        "Cumaral",
        "El Calvario",
        "El Castillo",
        "El Dorado",
        "Fuente de Oro",
        "Granada",
        "Guamal",
        "La Macarena",
        "Lejanías",
        "Mapiripán",
        "Mesetas",
        "Puerto Concordia",
        "Puerto Gaitán",
        "Puerto Lleras",
        "Puerto López",
        "Puerto Rico",
        "Restrepo",
        "San Carlos de Guaroa",
        "San Juan de Arama",
        "San Juanito",
        "San Martín",
        "Uribe",
        "Villavicencio",
        "Vista Hermosa",
    ],
    "Nariño":[
        "Albán",
        "Aldana",
        "Ancuyá",
        "Arboleda",
        "Barbacoas",
        "Belén",
        "Buesaco",
        "Chachagüí",
        "Colón",
        "Consaca",
        "Contadero",
        "Córdoba",
        "Cuaspud",
        "Cumbal",
        "Cumbitara",
        "El Charco",
        "El Peñol",
        "El Rosario",
        "El Tablón de Gómez",
        "El Tambo",
        "Francisco Pizarro",
        "Funes",
        "Guachucal",
        "Guaitarilla",
        "Gualmatán",
        "Iles",
        "Imués",
        "Ipiales",
        "La Cruz",
        "La Florida",
        "La Llanada",
        "La Tola",
        "La Unión",
        "Leiva",
        "Linares",
        "Los Andes",
        "Magüí",
        "Mallama",
        "Mosquera",
        "Nariño",
        "Olaya Herrera",
        "Ospina",
        "Pasto",
        "Policarpa",
        "Potosí",
        "Providencia",
        "Puerres",
        "Pupiales",
        "Ricaurte",
        "Roberto Payán",
        "Samaniego",
        "San Andrés de Tumaco",
        "San Bernardo",
        "San Lorenzo",
        "San Pablo",
        "San Pedro de Cartago",
        "Sandoná",
        "Santa Bárbara",
        "Santacruz",
        "Sapuyes",
        "Taminango",
        "Tangua",
        "Túquerres",
        "Yacuanquer",
    ],
    "Norte de Santander":[
        "Abrego",
        "Arboledas",
        "Bochalema",
        "Bucarasica",
        "Cachirá",
        "Cácota",
        "Chinácota",
        "Chitagá",
        "Convención",
        "Cúcuta",
        "Cucutilla",
        "Durania",
        "El Carmen",
        "El Tarra",
        "El Zulia",
        "Gramalote",
        "Hacarí",
        "Herrán",
        "La Esperanza",
        "La Playa",
        "Labateca",
        "Los Patios",
        "Lourdes",
        "Mutiscua",
        "Ocaña",
        "Pamplona",
        "Pamplonita",
        "Puerto Santander",
        "Ragonvalia",
        "Salazar",
        "San Calixto",
        "San Cayetano",
        "Santiago",
        "Sardinata",
        "Silos",
        "Teorama",
        "Tibú",
        "Toledo",
        "Villa Caro",
        "Villa del Rosario",
    ],
    "Putumayo":[
        "Colón",
        "Leguízamo",
        "Mocoa",
        "Orito",
        "Puerto Asís",
        "Puerto Caicedo",
        "Puerto Guzmán",
        "San Francisco",
        "San Miguel",
        "Santiago",
        "Sibundoy",
        "Valle de Guamez",
        "Villagarzón",
    ],
    "Quindío":[
        "Armenia",
        "Buenavista",
        "Calarcá",
        "Circasia",
        "Córdoba",
        "Filandia",
        "Génova",
        "La Tebaida",
        "Montenegro",
        "Pijao",
        "Quimbaya",
        "Salento",
    ],
    "Risaralda":[
        "Apía",
        "Balboa",
        "Belén de Umbría",
        "Dosquebradas",
        "Guática",
        "La Celia",
        "La Virginia",
        "Marsella",
        "Mistrató",
        "Pereira",
        "Pueblo Rico",
        "Quinchía",
        "Santa Rosa de Cabal",
        "Santuario",
    ],
    "Santander": [
        "Aguada",
        "Albania",
        "Aratoca",
        "Barbosa",
        "Barichara",
        "Barrancabermeja",
        "Betulia",
        "Bolívar",
        "Bucaramanga",
        "Cabrera",
        "California",
        "Capitanejo",
        "Carcasí",
        "Cepitá",
        "Cerrito",
        "Charalá",
        "Charta",
        "Chimá",
        "Chipatá",
        "Cimitarra",
        "Concepción",
        "Confines",
        "Contratación",
        "Coromoro",
        "Curití",
        "El Carmen de Chucurí",
        "El Guacamayo",
        "El Peñón",
        "El Playón",
        "Encino",
        "Enciso",
        "Florián",
        "Floridablanca",
        "Galán",
        "Gambita",
        "Girón",
        "Guaca",
        "Guadalupe",
        "Guapotá",
        "Guavatá",
        "Güepsa",
        "Hato",
        "Jesús María",
        "Jordán",
        "La Belleza",
        "La Paz",
        "Landázuri",
        "Lebríja",
        "Los Santos",
        "Macaravita",
        "Málaga",
        "Matanza",
        "Mogotes",
        "Molagavita",
        "Ocamonte",
        "Oiba",
        "Onzaga",
        "Palmar",
        "Palmas del Socorro",
        "Páramo",
        "Piedecuesta",
        "Pinchote",
        "Puente Nacional",
        "Puerto Parra",
        "Puerto Wilches",
        "Rionegro",
        "Sabana de Torres",
        "San Andrés",
        "San Benito",
        "San Gil",
        "San Joaquín",
        "San José de Miranda",
        "San Miguel",
        "San Vicente de Chucurí",
        "Santa Bárbara",
        "Santa Helena del Opón",
        "Simacota",
        "Socorro",
        "Suaita",
        "Sucre",
        "Suratá",
        "Tona",
        "Valle de San José",
        "Vélez",
        "Vetas",
        "Villanueva",
        "Zapatoca",
    ],
    "Sucre":[
        "Buenavista",
        "Caimito",
        "Chalán",
        "Coloso",
        "Corozal",
        "Coveñas",
        "El Roble",
        "Galeras",
        "Guaranda",
        "La Unión",
        "Los Palmitos",
        "Majagual",
        "Morroa",
        "Ovejas",
        "Palmito",
        "Sampués",
        "San Benito Abad",
        "San Juan de Betulia",
        "San Luis de Sincé",
        "San Marcos",
        "San Onofre",
        "San Pedro",
        "Santiago de Tolú",
        "Sincelejo",
        "Sucre",
        "Tolú Viejo",
    ],
    "Tolima":[
        "Alpujarra",
        "Alvarado",
        "Ambalema",
        "Anzoátegui",
        "Armero",
        "Ataco",
        "Cajamarca",
        "Carmen de Apicala",
        "Casabianca",
        "Chaparral",
        "Coello",
        "Coyaima",
        "Cunday",
        "Dolores",
        "Espinal",
        "Falan",
        "Flandes",
        "Fresno",
        "Guamo",
        "Herveo",
        "Honda",
        "Ibagué",
        "Icononzo",
        "Lérida",
        "Líbano",
        "Mariquita",
        "Melgar",
        "Murillo",
        "Natagaima",
        "Ortega",
        "Palocabildo",
        "Piedras",
        "Planadas",
        "Prado",
        "Purificación",
        "Rio Blanco",
        "Roncesvalles",
        "Rovira",
        "Saldaña",
        "San Antonio",
        "San Luis",
        "Santa Isabel",
        "Suárez",
        "Valle de San Juan",
        "Venadillo",
        "Villahermosa",
        "Villarrica",
    ],
    "Valle del Cauca":[
        "Alcalá",
        "Andalucía",
        "Ansermanuevo",
        "Argelia",
        "Bolívar",
        "Buenaventura",
        "Bugalagrande",
        "Caicedonia",
        "Cali",
        "Calima",
        "Candelaria",
        "Cartago",
        "Dagua",
        "El Águila",
        "El Cairo",
        "El Cerrito",
        "El Dovio",
        "Florida",
        "Ginebra",
        "Guacarí",
        "Guadalajara de Buga",
        "Jamundí",
        "La Cumbre",
        "La Unión",
        "La Victoria",
        "Obando",
        "Palmira",
        "Pradera",
        "Restrepo",
        "Riofrío",
        "Roldanillo",
        "San Pedro",
        "Sevilla",
        "Toro",
        "Trujillo",
        "Tuluá",
        "Ulloa",
        "Versalles",
        "Vijes",
        "Yotoco",
        "Yumbo",
        "Zarzal",
    ],
    "Vichada":[
        "Cumaribo",
        "La Primavera",
        "Puerto Carreño",
        "Santa Rosalía",
    ]
}

export default cities;