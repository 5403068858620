import React, {Component} from "react";
import {Alert, Button, ButtonGroup, Card, Col, Placeholder, Row, Table} from "react-bootstrap";
import BackButton from "../../util/backButton";
import {NavLink} from "react-router-dom";
import {FiArchive, FiPrinter} from "react-icons/fi";

class PrintPurchasesPerPeriod extends Component {
    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            ready: false,
            error: false,
            orders: null,
            name: null
        }
    }

    componentDidMount() {
        this.loadPeriodInfoAndPurchases();
    }

    render() {
        return (
            <>
                <Card>
                    <Row className={"component-for-print text-center"}>
                        <Col>
                            <ButtonGroup>
                                <BackButton/>
                                {this.state.ready ? (
                                    <>
                                        <NavLink to={"/admin/edit/purchase_periods/" + this.id}
                                                 className={"btn btn-info"}
                                        >
                                            <i><FiArchive/></i> Ver Periodo
                                        </NavLink>
                                        <Button variant={"success"} onClick={() => window.print()}>
                                            <i><FiPrinter/></i> Imprimir
                                        </Button>
                                    </>
                                ) : null}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Card.Header style={{padding: "0.1rem 1rem"}}>
                        <Row>
                            <Col>
                                <b className={"text-secondary"}>COMPRAS POR PERIODO</b>
                            </Col>
                            <Col className={"text-center"}>
                                {this.state.ready ? this.state.name : null}
                            </Col>
                            <Col>
                                <div style={{position: "absolute", right: "0px"}}>
                                    <small className="text-muted">ID: </small>
                                    <b className="text-info">
                                        {this.id}
                                    </b>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                </Card>
                {this.state.ready ? (
                    this.state.error ? (
                        <Alert variant={"danger"}>
                            <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                            <p>
                                {this.state.error}
                            </p>
                            <hr/>
                            <Button onClick={() => this.loadPeriodInfoAndPurchases()} variant={"dark"}>
                                Intentar nuevamente
                            </Button>
                        </Alert>
                    ) : (
                        <div style={{fontSize: "12px"}}>
                            {this.paintOrders()}
                        </div>
                    )
                ) : (
                    <>
                        <Placeholder as={Card.Text} animation={"glow"}>
                            <Placeholder xs={12} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} size={"lg"} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} size={"lg"} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Text} animation={"glow"}>
                            <Placeholder xs={12} bg={"success"}/>
                            <Placeholder xs={12} bg={"success"}/>
                        </Placeholder>
                    </>
                )}
            </>
        )
    }

    paintOrders = () => {
        if (this.state.orders?.length > 0) {
            return this.state.orders.map((order, key) => {
                if (order.status !== "cancelled") {
                    return (
                        <Card className={"mb-4"}>
                            <Card.Header style={{padding: "0.1rem 1rem"}}>
                                <Table>

                                    <tr>
                                        <th>Proveedor:</th>
                                        <td>{order.provider.name ? order.provider.name.toUpperCase() : ""}</td>
                                        <th>Id:</th>
                                        <th className={"text-danger"}>{order.id}</th>
                                    </tr>
                                    <tr>
                                        <th>Dir/Ciudad:</th>
                                        <td>{order.provider.address} - {order.provider.department ? order.provider.department.toUpperCase() : ""}/
                                            {order.provider.city ? order.provider.city.toUpperCase() : ""}</td>
                                        <th>Nit:</th>
                                        <td>{order.provider.identification}</td>
                                    </tr>
                                    <tr>
                                        <th>Establ.:</th>
                                        <td>{order.provider.establishment_name}</td>
                                        <th>Tels:</th>
                                        <td>{order.provider.phone}</td>
                                    </tr>
                                </Table>
                            </Card.Header>
                            {order.products?.length > 0 ? (
                                <Card.Body style={{padding: "0rem 0rem"}}>
                                    <Table bordered striped hover className={"table-sm-custom"}>
                                        <thead>
                                        <tr className={"text-center"}>
                                            <th>Código</th>
                                            <th>Descripción</th>
                                            <th>Costo</th>
                                            <th>Iva</th>
                                            <th>Cant</th>
                                            <th>Total</th>
                                            <th>Observaciones</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {order.products.map((product) => {
                                            return (
                                                <tr>
                                                    <td className={"text-center"}>{product.code}</td>
                                                    <td>{product.name}</td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.value)}
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        {new Intl.NumberFormat('de-DE').format(product.iva)}
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        {new Intl.NumberFormat('de-DE').format(product.amount)}
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.total)}
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                    <Table>
                                        <tr>
                                            <th>
                                                Pedido por:
                                            </th>
                                            <td style={{minWidth: "100px"}}></td>
                                            <th>
                                                Recibido por:
                                            </th>
                                            <td style={{minWidth: "100px"}}></td>
                                            <td>TOTAL:</td>
                                            <th style={{minWidth: "80px"}}
                                                className={"text-secondary"}>${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(order.total)}</th>
                                        </tr>
                                    </Table>
                                </Card.Body>
                            ): (<p className={"text-danger"}>Orden si productos</p>)}
                        </Card>
                    )
                }
            })
        } else {
            return (
                <Alert variant={"danger"}>No hay ordenes para imprimir</Alert>
            )
        }
    }

    loadPeriodInfoAndPurchases = () => {
        this.props.loadGet("/purchase_periods/" + this.id, this.loadContent, this.loadError);
        this.props.loadGet("/purchases?period=" + this.id, this.loadContentOrders, this.loadError);
    }

    loadContent = (data) => {
        this.setState({
            state: data['status'],
            start: data['open_date'],
            end: data['close_date'],
            name: data['name'],
        });
    }

    loadContentOrders = (data) => {
        this.setState({
            ready: true,
            orders: data ? data : [],
        });
        setTimeout(() => window.print(), 200);
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

}

export default PrintPurchasesPerPeriod;