import {Alert, FormCheck, Spinner} from "react-bootstrap";
import React, {useEffect} from "react";


function CategoriesCheck(props) {
    const category = props.category;
    const categories = props.categories;
    const [checked, setChecked] = React.useState(categories.get(category.id));

    return <FormCheck
        checked={checked}
        value={categories.get(category.id)}
        type={"switch"}
        label={category.name}
        onChange={(event) =>{
            setChecked(!checked);
            categories.set(category.id, !categories.get(category.id));
            props.setNewCategories(categories);
        }}
        inline
    />
}

function CategoriesInProduct(props) {
    const [allCategories, setAllCategories] = React.useState([]);
    const [ready, setReady] = React.useState(false);
    const [error, setError] = React.useState(false);
    const categoriesFromProduct = props.categories ? props.categories : [];
    const [categories, setCategories] = React.useState(null);

    useEffect(() => {
        let categoriesToRead = new Map();
        for (let category of categoriesFromProduct) {
            categoriesToRead.set(category.id, true);
        }
        setCategories(categoriesToRead);
    }, [])

    function loadContent(categories) {
        setAllCategories(categories);
        setReady(true);
    }

    function loadError(error) {
        setError(error);
        setReady(true);
    }

    function downloadAllCategories() {
        props.loadGet("/categories?type=product", loadContent, loadError);
    }

    useEffect(() => {
        downloadAllCategories();
    }, []);

    if (error) {
        return <Alert variant={"danger"}>Error: {error}</Alert>;
    }

    if (!ready) {
        return <div className={"text-center"}>
            <Spinner animation={"border"} variant={"success"}/>
        </div>
    }

    function formatCategories() {
        let categoriesEntity = [];
        categories.forEach((value, id) => {
            if (value) {
                categoriesEntity.push({"id": id});
            }
        });
        return categoriesEntity;
    }

    function setNewCategories(categories) {
        setCategories(categories);
        props.setCategories(formatCategories());
    }

    return (
        <div>
            {allCategories.map((category) => {
                return <CategoriesCheck
                    key={category.id}
                    categories={categories}
                    category={category}
                    setNewCategories={setNewCategories}
                />;
            })}
        </div>
    );
}

export default CategoriesInProduct;
