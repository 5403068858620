import Migration from "./Migration";

const PROCESSING = "PROCESSING"

class MigrationsProductsCosts extends Migration {
    headersBefore = ["codigo", "iva", "costo", "lista1", "lista2", "lista3", "lista4"]
    headerAfter = ["code", "cost", "iva", "sales_list", "sales_list_default"]
    url = "/products"
    enable = true
    update= true
    name = "Productos Costos"

    processArrayData = () => {
        let arrayData = this.state.array;
        let localData = [];
        arrayData.map(row => {
            if (row.codigo) {
                const object = {
                    "code": row.codigo.trim(),
                    "cost": row.costo.trim(),
                    "iva": row.iva.trim(),
                    "sales_list": [
                        {"value": row.lista1.trim()},
                        {"value": row.lista2.trim()},
                        {"value": row.lista3.trim()},
                        {"value": row.lista4.trim()}
                    ]
                }
                localData.push(object);
            }
        });
        this.setState({data: localData, status: PROCESSING});
    }
}

export default MigrationsProductsCosts