import {createStore, combineReducers, compose} from 'redux';
import persistState from 'redux-localstorage';

function tokenReducer(state = '', action) {
    switch (action.type) {
        case 'SET_TOKEN':
            return action.token;
        case 'CLEAR_TOKEN':
            return '';
        default:
            return state;
    }
}

function refreshTokenReducer(state = '', action) {
    switch (action.type) {
        case 'SET_REFRESH_TOKEN':
            return action.refresh;
        case 'CLEAR_REFRESH_TOKEN':
            return '';
        default:
            return state;
    }
}

function userReducer(state = null, action) {
    switch (action.type) {
        case 'LOGGED_IN':
            return action.user;
        case 'SIGN_OUT':
            return null;
        default:
            return state;
    }
}

function miniReducer(state = null, action) {
    switch (action.type) {
        case 'SET_MINI':
            return action.mini;
        case 'CLEAR_MINI':
            return false;
        default:
            return state;
    }
}

function productsReducer(state = [], action) {
    switch (action.type) {
        case 'SET_PRODUCTS':
            return action.products;
        case 'CLEAR_PRODUCTS':
            return null;
        default:
            return state;
    }
}

function photosReducer(state = [], action) {
    switch (action.type) {
        case 'SET_PHOTOS':
            return action.photos;
        case 'CLEAR_PHOTOS':
            return [];
        default:
            return state;
    }
}

let rootReducers = combineReducers({
    token: tokenReducer,
    refresh: refreshTokenReducer,
    user: userReducer,
    mini: miniReducer,
    products: productsReducer,
    photos: photosReducer
});

let mainEnhancer = compose(persistState(['token', 'refresh', 'user', 'mini', 'products']))

export default createStore(rootReducers, {}, mainEnhancer)