import {
    FiUsers,
    FiUserCheck,
    FiBox,
    FiUserPlus,
    FiCalendar,
    FiSave
} from "react-icons/fi";
import {AiOutlineLineChart} from "react-icons/ai";

import {
    FaList,
    FaLuggageCart
} from "react-icons/fa";

import Users from "./views/users/Users";
import Dashboard from "./views/dasboard/Dashboard";
import EditUsers from "./views/users/EditUsers";
import NewUser from "./views/users/NewUser";
import Clients from "./views/clients/Clients";
import NewClient from "./views/clients/NewClient";
import Products from "./views/products/Products";
import NewProduct from "./views/products/NewProduct";
import EditProduct from "./views/products/EditProduct";
import EditClient from "./views/clients/EditClient";
import Providers from "./views/providers/Providers";
import NewProvider from "./views/providers/NewProvider";
import EditProvider from "./views/providers/EditProvider";
import Orders from "./views/orders/Orders";
import NewOrder from "./views/orders/NewOrder";
import EditOrder from "./views/orders/EditOrder";
import SalesPeriods from "./views/periods/SalesPeriods";
import LoadsPeriods from "./views/periods/LoadsPeriods";
import PurchasesPeriods from "./views/periods/PurchasesPeriods";
import EditSalePeriod from "./views/periods/EditSalePeriod";
import EditLoadPeriod from "./views/periods/EditLoadPeriod";
import EditPurchasePeriod from "./views/periods/EditPurchasePeriod";
import PrintOrder from "./views/orders/PrintOrder";
import ActiveOrders from "./views/orders/ActiveOrders";
import ProcessOrders from "./views/orders/ProcessOrders";
import ClosedOrders from "./views/orders/ClosedOrders";
import CancelledOrders from "./views/orders/CancelledOrders";
import Purchases from "./views/purchases/Purchases";
import NewPurchase from "./views/purchases/NewPurchase";
import ActivePurchases from "./views/purchases/ActivePurchases";
import EditPurchase from "./views/purchases/EditPurchase";
import PrintPurchase from "./views/purchases/PrintPurchase";
import Migration from "./views/migrations/Migration";
import MigrationProviders from "./views/migrations/MigrationProviders";
import MigrationClients from "./views/migrations/MigrationClients";
import MigrationProducts from "./views/migrations/MigrationProducts";
import MigrationProductsCosts from "./views/migrations/MigrationProductsCosts";
import EditAllInventory from "./views/products/EditAllInventory";
import SearchIn from "./views/Search/SearchIn";
import PrePurchases from "./views/purchases/PrePurchases";
import AllUsersAdmins from "./views/users/AllUsersAdmins";
import AllUsersClients from "./views/users/AllUsersClients";
import PrintPurchasesPerPeriod from "./views/purchases/PrintPurchasesPerPeriod";
import Categories from "./views/products/Categories";
import NewCategory from "./views/products/NewCategory";
import EditCategory from "./views/products/EditCategory";

let routes = [
    {
        path: "/home",
        layout: "/admin",
        name: "Inicio",
        icon: AiOutlineLineChart,
        component: Dashboard
    },
    {
        path: "/search",
        layout: "/admin",
        name: "Buscador",
        icon: AiOutlineLineChart,
        component: SearchIn,
        hidden: true
    },
    {
        collapse: true,
        path: "/products",
        layout: "/admin",
        name: "Productos",
        icon: FiBox,
        component: Products,
        views: [
            {
                path: "/products",
                layout: "/admin",
                name: "Buscar Productos",
                mini: "BP",
                component: Products
            },
            {
                path: "/new/products",
                layout: "/admin",
                name: "Nuevo Producto",
                mini: "NP",
                component: NewProduct,
            },
            {
                path: "/edit/products",
                params: "/:id",
                layout: "/admin",
                name: "Editar Producto",
                mini: "EP",
                component: EditProduct,
                hidden: true
            },
            {
                path: "/edit/inventory/products",
                layout: "/admin",
                name: "Editar Inventario",
                mini: "EI",
                component: EditAllInventory,
            },
            {
                path: "/categories",
                layout: "/admin",
                name: "Categoría de Productos",
                mini: "CP",
                component: Categories,
            },
            {
                path: "/new/category",
                layout: "/admin",
                name: "Nueva Categoría",
                mini: "NC",
                component: NewCategory,
            },
            {
                path: "/edit/category",
                params: "/:id",
                layout: "/admin",
                name: "Modificar Categoría",
                mini: "MC",
                hidden: true,
                component: EditCategory,
            }
        ]
    },
    {
        collapse: true,
        path: "/orders",
        layout: "/admin",
        name: "Pedidos",
        icon: FaList,
        component: Orders,
        views: [
            {
                path: "/orders",
                layout: "/admin",
                name: "Ultimas ordenes",
                mini: "UP",
                component: ActiveOrders
            },
            {
                path: "/process.orders",
                layout: "/admin",
                name: "Ordenes en Proceso",
                mini: "PP",
                component: ProcessOrders
            },
            {
                path: "/inactive.orders",
                layout: "/admin",
                name: "Ordenes Cerradas",
                mini: "PC",
                component: ClosedOrders
            },
            {
                path: "/cancelled.orders",
                layout: "/admin",
                name: "Ordenes Anuladas",
                mini: "OA",
                component: CancelledOrders
            },
            {
                path: "/new/orders",
                layout: "/admin",
                name: "Nuevo Pedido",
                mini: "NP",
                component: NewOrder
            },
            {
                path: "/edit/orders",
                params: "/:id",
                layout: "/admin",
                name: "Editar Orden",
                mini: "EO",
                component: EditOrder,
                hidden: true
            },
            {
                path: "/print/orders",
                params: "/:id/:isOrder",
                layout: "/admin",
                name: "Imprimir Orden",
                mini: "PO",
                component: PrintOrder,
                hidden: true
            }
        ]
    },
    {
        collapse: true,
        path: "/purchases",
        layout: "/admin",
        name: "Compras",
        icon: FaLuggageCart,
        component: Purchases,
        views: [
            {
                path: "/purchases",
                layout: "/admin",
                name: "Compras en proceso",
                mini: "CP",
                component: ActivePurchases
            },
            {
                path: "/new/purchases",
                layout: "/admin",
                name: "Nueva Compra",
                mini: "NC",
                component: NewPurchase
            },
            {
                path: "/edit/purchases",
                params: "/:id",
                layout: "/admin",
                name: "Editar Compra",
                mini: "EC",
                component: EditPurchase,
                hidden: true
            },
            {
                path: "/print/purchases",
                params: "/:id",
                layout: "/admin",
                name: "Imprimir Compra",
                mini: "IC",
                component: PrintPurchase,
                hidden: true
            },
            {
                path: "/print/period/purchases",
                params: "/:id",
                layout: "/admin",
                name: "Imprimir Compras",
                mini: "IC",
                component: PrintPurchasesPerPeriod,
                hidden: true
            },
            {
                path: "/pre-purchases",
                layout: "/admin",
                name: "Pre Compras",
                mini: "PC",
                component: PrePurchases
            }
        ]
    },
    {
        collapse: true,
        path: "/clients",
        layout: "/admin",
        name: "Clientes",
        icon: FiUserCheck,
        component: Clients,
        views: [
            {
                path: "/clients",
                layout: "/admin",
                name: "Buscar Clientes",
                mini: "BC",
                component: Clients
            },
            {
                path: "/new/clients",
                layout: "/admin",
                name: "Nuevo Cliente",
                mini: "NC",
                component: NewClient,
            },
            {
                path: "/edit/clients",
                params: "/:id",
                layout: "/admin",
                name: "Editar Cliente",
                mini: "EC",
                component: EditClient,
                hidden: true
            }
        ]
    },
    {
        collapse: true,
        path: "/providers",
        layout: "/admin",
        name: "Proveedores",
        icon: FiUserPlus,
        component: Providers,
        views: [
            {
                path: "/providers",
                layout: "/admin",
                name: "Buscar Proveedores",
                mini: "BP",
                component: Providers
            },
            {
                path: "/new/providers",
                layout: "/admin",
                name: "Nuevo Proveedor",
                mini: "NP",
                component: NewProvider,
            },
            {
                path: "/edit/providers",
                params: "/:id",
                layout: "/admin",
                name: "Editar Proveedor",
                mini: "EP",
                component: EditProvider,
                hidden: true
            }
        ]
    },
    {
        collapse: true,
        path: "/periods",
        layout: "/admin",
        name: "Periodos",
        icon: FiCalendar,
        views: [
            {
                path: "/sale_periods",
                layout: "/admin",
                name: "Periodos de Ventas",
                mini: "PV",
                component: SalesPeriods
            },
            {
                path: "/loads",
                layout: "/admin",
                name: "Periodos de Cargue",
                mini: "PC",
                component: LoadsPeriods
            },
            {
                path: "/purchase_periods",
                layout: "/admin",
                name: "Periodos de Compras",
                mini: "PP",
                component: PurchasesPeriods
            },
            {
                path: "/edit/sale_periods",
                params: "/:id",
                layout: "/admin",
                name: "Periodo de Ventas",
                mini: "PP",
                component: EditSalePeriod,
                hidden: true
            },
            {
                path: "/edit/loads",
                params: "/:id",
                layout: "/admin",
                name: "Periodo de Cargue",
                mini: "PP",
                component: EditLoadPeriod,
                hidden: true
            },
            {
                path: "/edit/purchase_periods",
                params: "/:id",
                layout: "/admin",
                name: "Periodo de Compras",
                mini: "PP",
                component: EditPurchasePeriod,
                hidden: true
            }
        ]
    },
    {
        collapse: true,
        path: "/migrations",
        layout: "/admin",
        name: "Masivos",
        icon: FiSave,
        component: Migration,
        views: [
            {
                path: "/migrations/prividers",
                layout: "/admin",
                name: "Migración Proveedores",
                mini: "MP",
                component: MigrationProviders
            },
            {
                path: "/migrations/clients",
                layout: "/admin",
                name: "Migración Clientes",
                mini: "MC",
                component: MigrationClients
            },
            {
                path: "/migrations/products",
                layout: "/admin",
                name: "Migración Productos",
                mini: "MP",
                component: MigrationProducts
            },
            {
                path: "/masive/products",
                layout: "/admin",
                name: "Actualizar Precios",
                mini: "AP",
                component: MigrationProductsCosts
            },
        ]
    },
    {
        collapse: true,
        path: "/users",
        layout: "/admin",
        name: "Usuarios",
        icon: FiUsers,
        component: Users,
        views: [
            {
                path: "/users/admins",
                layout: "/admin",
                name: "Usuarios Admins",
                mini: "UA",
                component: AllUsersAdmins
            },
            {
                path: "/users/clients",
                layout: "/admin",
                name: "Usuarios Clientes",
                mini: "UC",
                component: AllUsersClients
            },
            {
                path: "/new/users",
                layout: "/admin",
                name: "Nuevo Usuario",
                mini: "NU",
                component: NewUser,
            },
            {
                path: "/edit/users",
                params: "/:id",
                layout: "/admin",
                name: "Editar Usuario",
                mini: "EU",
                component: EditUsers,
                hidden: true
            }
        ]
    }
]


export default routes;