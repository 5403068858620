import React, {Component} from "react";
import {
    Alert, Badge,
    Button,
    ButtonGroup,
    Card,
    Placeholder, ProgressBar, Row
} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FiPackage, FiPrinter, FiRefreshCcw, FiSearch} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import HeaderOrder from "./HeaderOrder";
import SweetAlert from "react-bootstrap-sweetalert";
import ClientInformation from "./ClientInformation";
import ProductsInformation from "./ProductsInformation";
import CommentsOrder from "./CommentsOrder";
import loadDateAndTime from "../../util/dateAndTimeToString";
import BackButton from "../../util/backButton";
import {connect} from "react-redux";
import LogsOrder from "./LogsOrder";

const status = {
    "inactive": {
        "name": "Cerrado",
        "color": "secondary",
        "next": [],
    },
    "active": {
        "name": "Activo",
        "color": "info",
        "edit": true,
        "next": [
            "process",
            "cancelled"
        ]
    },
    "process": {
        "name": "Procesando",
        "color": "warning",
        "next": [
            "active",
            "cancelled"
        ]
    },
    "cancelled": {
        "name": "Cancelado",
        "color": "danger",
        "next": [
            "active",
            "process"
        ]
    },
    "deleted": {
        "name": "Eliminado",
        "color": "dark",
        "hidden": true
    }
}

class EditOrder extends Component {

    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            modal: null,
            ready_post: true,
            error_post: false,
            redirect: null,
            update_on: null,
            order: null,
            state: null,
            enabled: false,
            saving: null,
            loads: null,
        }
    }


    componentDidMount() {
        this.loadOrder();
        this.loadPeriods();
    }


    render() {
        return (
            <Card border={this.getColorStatus("color")}>
                <Card.Header>
                    <small className="text-muted">Número de Orden:</small> <b className={"text-danger"}>{this.id} </b>
                    {
                        /*
                        this.state.state ? (<Badge bg={this.getColorStatus("color")}> {this.getColorStatus("name")} </Badge>) : null
                         */
                    }
                    {this.state.order?.from_store?(
                        <Badge bg={"primary"}> Tienda </Badge>
                    ):null}
                    {this.state.error_post ? (<small className="text-muted"> {this.state.error_post}</small>) : null}
                    <ButtonGroup className={"float-end"}>
                        <BackButton size={"sm"}/>
                        {this.state.order ? (
                            <>
                                <Button onClick={() => window.location.reload()} variant={"outline-info"} size={"sm"}>
                                    <i><FiRefreshCcw/></i> Recargar
                                </Button>
                                {this.state.order.load && (this.state.order.status === "active" || this.state.order.status === "process") ? (
                                    <NavLink to={"/admin/print/orders/" + this.id + "/load"}
                                             className={"btn btn-outline-warning btn-sm"}
                                             type={"button"}
                                             onClick={() => this.changeStatusProcess()}
                                    >
                                        <i><FiPackage/></i> Alistar Pedido
                                    </NavLink>
                                ) : null}
                                {this.state.order.load && !(this.state.order.status === "active" || this.state.order.status === "process") ? (
                                    <NavLink to={"/admin/print/orders/" + this.id + "/load"}
                                             className={"btn btn-outline-secondary btn-sm"}
                                             type={"button"}
                                    >
                                        <i><FiPrinter/></i> Imprimir Cargue
                                    </NavLink>
                                ) : null}

                                <NavLink to={"/admin/print/orders/" + this.id + "/order"}
                                         className={"btn btn-outline-success btn-sm"}
                                         type={"button"}
                                >
                                    <i><FiPrinter/></i> Imprimir Pedido
                                </NavLink>
                            </>
                        ) : null}

                        <NavLink to={"/admin/orders"} className={"btn btn-outline-secondary btn-sm"}>
                            <i><FiSearch/></i> Buscar Ordenes
                        </NavLink>
                        <NavLink to={"/admin/new/orders"} className={"btn btn-outline-primary btn-sm"}>
                            <i><GrAdd/></i> Crear Nuevo
                        </NavLink>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.redirect}
                    {this.state.ready ? (
                        <>{this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadOrder()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <>
                                <Row className={"mb-3"}>
                                    {this.state.saving}
                                </Row>
                                <HeaderOrder order={this.state.order}
                                             setOrder={this.setOrder}
                                             color={this.getColorStatus("color")}
                                             status={status}
                                             loads={this.state.loads}
                                />
                                <ClientInformation {...this.props}
                                                   order={this.state.order}
                                                   setOrder={this.setOrder}
                                                   enabled={this.state.enabled}
                                />
                                <ProductsInformation {...this.props}
                                                     order={this.state.order}
                                                     setOrder={this.setOrder}
                                                     enabled={this.state.enabled}
                                />
                                <CommentsOrder {...this.props}
                                               order={this.state.order}
                                               setOrder={this.setOrder}
                                />
                                <LogsOrder {...this.props} order={this.state.order}/>
                            </>
                        )}</>
                    ) : (
                        <>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                            </Placeholder>
                            <Placeholder animation={"glow"}>
                                <Placeholder.Button variant="success" xs={5}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder.Button variant="danger" xs={5}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">
                        {
                            this.state.ready && !this.state.error ? (
                                <>
                                    Actualizado el {this.state.update_on}
                                </>
                            ) : null
                        }
                    </small>
                </Card.Footer>
            </Card>
        )
    }

    loadOrder = () => {
        this.props.loadGet("/orders/" + this.id, this.loadContent, this.loadError)
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            order: data,
            state: data['status'],
            enabled: this.isEnabledForEdit(data['status']),
            update_on: loadDateAndTime(data['updated']),
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    setOrder = (order, event_message) => {
        let event = {
            message: event_message,
            user: this.props.user.name ? this.props.user.name : this.props.user.email
        }

        if (order.logs && order.logs.length > 0) {
            order.logs.push(event);
        } else {
            order.logs = [event];
        }

        this.setState({
            order: order,
            state: order['status'],
            enabled: this.isEnabledForEdit(order['status']),
            saving: (<ProgressBar animated now={30}/>)
        });
        this.props.loadPut("/orders/" + this.id, this.state.order, this.successSaveOrderExpress, this.loadErrorSave)
    }

    successSaveOrderExpress = (order) => {
        this.setState({
            order: order,
            saving: (<ProgressBar animated now={100} variant={"success"}/>),
            update_on: loadDateAndTime(order['updated']),
        });
        setTimeout(() => {
            this.setState({
                saving: null,
            })
        }, 700);
    }

    loadErrorSave = (onerror) => {
        this.setState({
            error_post: "Error: No se pudo guardar la orden",
            saving: (<ProgressBar animated now={100} variant={"danger"}/>),
            ready_post: true
        });
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    getColorStatus = (property) => {
        let status_tem = status[this.state.state]
        if (status_tem) {
            return status_tem[property]
        }
        return ""
    }

    isEnabledForEdit = (state) => {
        let status_tem = status[state]
        return status_tem['edit'] ? status_tem['edit'] : false
    }

    loadPeriods = () => {
        this.props.loadGet("/loads?status=active", this.loadContentPeriods, this.loadError)
    }

    loadContentPeriods = (data) => {
        if (data) {
            this.setState({
                loads: data
            });
        }
    }

    changeStatusProcess = () => {
        let order = this.state.order;
        if (order.status !== "process") {
            order.status = "process";
            this.setOrder(order, "Generó orden de cargue");
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, {})(EditOrder);