import React, {Component} from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col, FormCheck,
    FormControl,
    FormGroup,
    FormLabel, FormSelect, Nav,
    Placeholder,
    Row, Spinner
} from "react-bootstrap";
import {NavLink, Redirect} from "react-router-dom";
import {AiFillDelete, AiFillSave} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import * as Yup from "yup";
import {Form, Formik} from 'formik';
import cities from "./cities";
import SweetAlert from "react-bootstrap-sweetalert";
import advisoriesList from "../../util/advisoriesList";
import BackButton from "../../util/backButton";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    identification: Yup.string().required('Requedido'),
    phone: Yup.string(),
    city: Yup.string(),
    department: Yup.string(),
    address: Yup.string(),
    category: Yup.string(),
    list: Yup.number().min(0, "Minimo 0").max(100, "Maximo 100"),
    quota: Yup.number(),
    contact_name: Yup.string(),
    establishment_name: Yup.string(),
    route_id: Yup.string(),
    email: Yup.string().email('Correo Invalido'),
});

class EditClient extends Component {
    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            client: null,
            modal: null,
            ready_post: true,
            error_post: false,
            redirect: null,
            update_on: null,
            has_access: false,
            access_id: null,
            temporal_password: null,
            name: '',
            last_name: '',
            role: 'client',
        }
    }

    componentDidMount() {
        this.loadClient();
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Editar Cliente
                    <ButtonGroup className={"float-end"}>
                        <BackButton size={"sm"}/>
                        <Button size="sm" variant={"secondary"}>
                            <NavLink to={"/admin/clients"}>
                                <i><FiSearch/></i> Buscar Clientes
                            </NavLink>
                        </Button>
                        <Button size="sm" variant={"primary"}>
                            <NavLink to={"/admin/new/clients"}>
                                <i><GrAdd/></i> Crear Nuevo
                            </NavLink>
                        </Button>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.redirect}
                    {this.state.ready ? (
                        <>{this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadClient()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <>{this.loadForm(this.state.client)}</>
                        )}</>
                    ) : (
                        <>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder.Button variant="success" xs={6}/>
                            <Placeholder.Button variant="danger" xs={6}/>
                        </>
                    )}
                </Card.Body>
                <Card.Footer/>
            </Card>
        )
    }

    loadClient = () => {
        this.props.loadGet("/clients/" + this.id, this.loadContent, this.loadError)
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            client: data,
            has_access: data.has_access ? data.has_access : false,
        });
        if (data.has_access && data.access_id) {
            this.setState({
                ready: false,
            })
            this.props.loadGet("/users/" + data.access_id, this.loadContentUser, this.loadError)
        }
    }

    loadContentUser = (user) => {
        this.setState({
            ready: true,
            name: user.name,
            last_name: user.last_name,
            access_id: user.id,
            role: user.role,
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    loadForm = (data) => {
        if (data) {
            return (
                <Formik
                    initialValues={{
                        name: data.name ? data.name.toUpperCase() : '',
                        identification: data.identification ? data.identification.toUpperCase() : '',
                        phone: data.phone ? data.phone : '',
                        city: data.city ? data.city : '',
                        department: data.department ? data.department : '--',
                        address: data.address ? data.address.toUpperCase() : '',
                        category: data.category ? data.category : '',
                        list: data.list ? data.list : 0,
                        quota: data.quota ? data.quota : 0,
                        contact_name: data.contact_name ? data.contact_name.toUpperCase() : '',
                        establishment_name: data.establishment_name ? data.establishment_name.toUpperCase() : '',
                        route_id: data.route_id ? data.route_id : '',
                        email: data.email ? data.email : '',
                        has_access: data.has_access ? data.has_access : false,
                        advisory: data.advisory ? data.advisory: '',
                    }}
                    validationSchema={schema}
                    onSubmit={values => {
                        this.saveClient(values, data.id);
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"6"} controlId="validationIdentification">
                                    <FormLabel>Identificación del cliente (Nit o CC):</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="identification"
                                        value={values.identification}
                                        onChange={handleChange}
                                        isInvalid={!!errors.identification}
                                        placeholder={"Nit o Número de Identificación"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.identification}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"6"} controlId="validationName">
                                    <FormLabel>Nombre del cliente (Razon Social):</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                        placeholder={"Nombre"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.name}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"4"} controlId="validationPhone">
                                    <FormLabel>Número telefonico (Celular):</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                        placeholder={"Teléfono o Celular"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.phone}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationDepartments">
                                    <FormLabel>Departamento:</FormLabel>
                                    <FormSelect
                                        name="department"
                                        value={values.department}
                                        onChange={handleChange}
                                        isInvalid={!!errors.department}
                                    >
                                        {this.getDepartments()}
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {errors.department}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationCities">
                                    <FormLabel>Ciudad:</FormLabel>
                                    <FormSelect
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city}
                                    >
                                        <option>--</option>
                                        {
                                            cities[values.department].map((city, key) => (
                                                <option key={key}>{city}</option>
                                            ))
                                        }
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {errors.city}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"5"} controlId="validationAddress">
                                    <FormLabel>Dirección:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        isInvalid={!!errors.address}
                                        placeholder={"Dirección"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.address}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"3"} controlId="validationCategory">
                                    <FormLabel>Categoria:</FormLabel>
                                    <FormSelect
                                        name="category"
                                        value={values.category}
                                        onChange={handleChange}
                                        isInvalid={!!errors.category}
                                    >
                                        <option>CLASSIC</option>
                                        <option>GOLD</option>
                                        <option>PLATINUM</option>
                                        <option>BLACK</option>
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {errors.category}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"2"} controlId="validationList">
                                    <FormLabel>Lista de venta:</FormLabel>
                                    <FormControl
                                        type="number"
                                        name="list"
                                        value={values.list}
                                        onChange={handleChange}
                                        isInvalid={!!errors.list}
                                        placeholder={"0"}
                                        style={{textAlign: 'right'}}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.list}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"2"} controlId="validationQuota">
                                    <FormLabel>Credito:</FormLabel>
                                    <FormControl
                                        type="number"
                                        name="quota"
                                        value={values.quota}
                                        onChange={handleChange}
                                        isInvalid={!!errors.quota}
                                        placeholder={"0"}
                                        style={{textAlign: 'right'}}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.quota}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"4"} controlId="validationContactName">
                                    <FormLabel>Nombre de contacto:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="contact_name"
                                        value={values.contact_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.contact_name}
                                        placeholder={"Contacto"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.contact_name}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationEstablishmentName">
                                    <FormLabel>Nombre del establecimiento:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="establishment_name"
                                        value={values.establishment_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.establishment_name}
                                        placeholder={"Establecimiento"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.establishment_name}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationRoutes">
                                    <FormLabel>Ruta:</FormLabel>
                                    <FormSelect
                                        name="route_id"
                                        value={values.route_id}
                                        onChange={handleChange}
                                        isInvalid={!!errors.route_id}
                                    >
                                        <option>R1- RUTA 1</option>
                                        <option>R2</option>
                                        <option>R3</option>
                                        <option>R4</option>
                                        <option>R5</option>
                                        <option>R6</option>
                                        <option>R7</option>
                                        <option>R8</option>
                                        <option>R9</option>
                                        <option>R10</option>
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {errors.route_id}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Card bg={this.state.role === "admin" ? "secondary" : "info"} text={"white"}>
                                <Card.Header>Datos de usuario </Card.Header>
                                <Card.Body>
                                    {this.state.role === "admin"?(
                                        <>
                                            <Card.Subtitle>Usuario Administrador</Card.Subtitle>
                                            <br/>
                                            <Alert variant={"info"}>
                                                <small>* Si deshabilita el usuario desde aqui, no perdera el acceso al admin</small>
                                            </Alert>
                                        </>
                                    ):(
                                        <>
                                            <Card.Subtitle>Usuario Cliente</Card.Subtitle>
                                            <br/>
                                            {this.state.access_id ?(
                                                <Alert variant={"danger"}>
                                                    <small>* Si deshabilita el usuario desde aqui, el usuario va
                                                        a perder todo acceso a la plataforma de pedidos</small>
                                                </Alert>
                                            ): (
                                                <Alert variant={"info"}>
                                                    <small>Ingresar información del usuario para habilitar un acceso a la plataforma</small>
                                                </Alert>
                                            )}
                                        </>
                                    )}
                                    <Row className={"mb-3"}>
                                        <FormGroup as={Col} md={"5"} controlId="validationEmail">
                                            <FormLabel>Email:</FormLabel>
                                            <FormControl
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                placeholder={"Email"}
                                                disabled={values.has_access && this.state.access_id}
                                            />
                                            <FormControl.Feedback type="invalid">
                                                {errors.email}
                                            </FormControl.Feedback>
                                        </FormGroup>
                                        <FormGroup as={Col} sm={4} controlId={"validationAdvisory"}>
                                            <FormLabel><b>Asesor del Cliente:</b></FormLabel>
                                            <FormSelect
                                                name={"advisory"}
                                                value={values.advisory}
                                                onChange={handleChange}
                                            >
                                                <option value={""}></option>
                                                {advisoriesList.map( (advisory) => (
                                                    <option value={advisory}>{advisory}</option>
                                                ))}
                                            </FormSelect>
                                        </FormGroup>
                                        <FormGroup as={Col} md={"3"} controlId="isInStoreClient">
                                            <FormLabel>Disponible en tienda:</FormLabel>
                                            <FormCheck
                                                type="switch"
                                                name={"has_access"}
                                                label="Disponible"
                                                checked={this.state.has_access}
                                                value={values.has_access}
                                                disabled={!values.email}
                                                onChange={(event) => {
                                                    values.has_access = !this.state.has_access;
                                                    this.setState({
                                                        has_access: !this.state.has_access
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Row>
                                    {this.state.has_access ? (
                                        <>
                                            <Row className={"mb-3"}>
                                                <FormGroup as={Col} md={"6"} controlId="validationNameUser">
                                                    <FormLabel>Nombres:</FormLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="name"
                                                        placeholder={"Nombres"}
                                                        value={this.state.name}
                                                        onChange={event => {
                                                            this.setState({name: event.target.value})
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup as={Col} md={"6"} controlId="validationLastName">
                                                    <FormLabel>Apellidos:</FormLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="name"
                                                        placeholder={"Nombres"}
                                                        value={this.state.last_name}
                                                        onChange={event => {
                                                            this.setState({last_name: event.target.value})
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Row>
                                            <Row className={"mb-3"}>
                                                {this.state.access_id ? (
                                                    <FormGroup as={Col} md={"12"} controlId="AccessID">
                                                        <FormLabel>AccessID:</FormLabel>
                                                        <NavLink to={"/admin/edit/users/" + this.state.access_id}
                                                                 className={"btn btn-primary"}
                                                        >
                                                            Ver Usuario
                                                        </NavLink>
                                                    </FormGroup>
                                                ) : (
                                                    <FormGroup as={Col} md={"12"} controlId="validationPassword">
                                                        <FormLabel className={"text-primary"}>Contraseña: (Debe tener
                                                            una
                                                            mayuscula, un numero y un caracter especial y mínimo 8
                                                            caracteres)</FormLabel>
                                                        <FormControl
                                                            type="password"
                                                            name="password"
                                                            placeholder={"Password"}
                                                            value={this.state.temporal_password}
                                                            onChange={event => {
                                                                this.setState({temporal_password: event.target.value})
                                                            }}
                                                        />
                                                    </FormGroup>
                                                )}
                                            </Row>
                                        </>
                                    ) : null}
                                </Card.Body>
                            </Card>
                            <Row className={"mb-3"}>
                                <Col md={6}>
                                    <Button type={"submit"} variant={"success"} disabled={!this.state.ready}
                                            className={"buttons-with-all-width"}
                                    >
                                        <i>
                                            <AiFillSave/>
                                        </i> Guardar Cliente
                                    </Button>
                                </Col>
                                <Col md={"6"}>
                                    <Button variant={"danger"} className={"buttons-with-all-width"}
                                            onClick={() => this.checkDelete(data)}
                                    >
                                        <i><AiFillDelete/></i> Eliminar cliente
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            )
        } else {
            return (
                <p>No hay datos disponibles</p>
            )
        }
    }

    getDepartments = () => {
        let deps = [];
        for (let department in cities) {
            deps.push(<option key={department}>{department}</option>)
        }
        return deps;
    }

    saveClient = (values, client_id) => {
        values['name'] = values.name.toUpperCase();
        values['identification'] = values.identification.toUpperCase();
        values['address'] = values.address.toUpperCase();
        values['establishment_name'] = values.establishment_name.toUpperCase();
        values['contact_name'] = values.contact_name.toUpperCase();

        this.setState({
            ready_post: false,
        });
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el cliente en la base de datos
            </SweetAlert>
        );
        this.setState({
            client: values
        });
        if (this.state.has_access && values['email'] && !this.state.access_id) {
            let user_values = {
                name: this.state.name,
                last_name: this.state.last_name,
                email: values['email'],
                identification: values['identification'],
                password: this.state.temporal_password,
                role: this.state.role,
                client_id: client_id
            }
            this.props.loadPost("/users", user_values, this.successSaveUser, this.loadErrorPost);
        }
        if (this.state.has_access && values['email'] && this.state.access_id) {
            let user_values = {
                name: this.state.name,
                last_name: this.state.last_name,
                email: values['email'],
                identification: values['identification'],
                role: this.state.role,
                client_id: client_id
            }
            this.props.loadPut("/users/" + this.state.access_id, user_values, this.successSaveUserPut, this.loadErrorPost);
        }
        if (!this.state.has_access && this.state.access_id) {
            this.props.loadDelete("/users/" + this.state.access_id, this.reloadUser, this.loadErrorPost);
        }
        if (!this.state.has_access && !this.state.access_id) {
            this.props.loadPut("/clients/" + client_id, values, this.successSaveClient, this.loadErrorPost);
        }
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    successSaveClient = (client) => {
        if (client) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    onConfirm={() => this.setAlert(null)}
                    timeout={2000}
                >
                    Cliente guardado
                </SweetAlert>
            )
            this.setState({
                ready_post: true,
            });
        } else {
            this.loadErrorPost("Error al actualizar el cliente")
        }
    }

    successSaveUser = (user) => {
        if (user) {
            let values = this.state.client
            values['access_id'] = user.id
            this.setState({
                access_id: user.id
            });
            this.props.loadPut("/clients/" + this.id, values, this.successSaveClient, this.loadErrorPost);
        }
    }

    successSaveUserPut = (user) => {
        if (user) {
            let values = this.state.client
            values['access_id'] = user.id
            this.props.loadPut("/clients/" + this.id, values, this.successSaveClient, this.loadErrorPost);
        }
    }

    reloadUser = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Usuario borrado
                </SweetAlert>
            )
            let values = this.state.client
            values.access_id = ''
            this.props.loadPut("/clients/" + this.id, values, this.successSaveClient, this.loadErrorPost);
        } else {
            this.loadError("Error al borrar el usuario")
        }
    };

    loadErrorPost = (onerror) => {
        this.setState({ready_post: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    checkDelete = (client) => {
        let title_var = "Quiere eliminar el cliente " + (client.identification) + " - " + (client.name) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeleteAlert(client)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El cliente no se puede recuperar despues de eliminado
            </SweetAlert>
        )
    }

    loadDeleteAlert = (client) => {
        let title_var = "Eliminando el cliente " + (client.identification) + " - " + (client.name) + "!"
        this.setAlert(
            <SweetAlert
                custom
                title={title_var}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado el cliente
            </SweetAlert>
        )
        if (this.state.has_access && this.state.access_id) {
            this.props.loadDelete("/users/" + this.state.access_id, this.reloadUserDelete, this.loadErrorPost);
        } else {
            this.props.loadDelete("/clients/" + client.id, this.reloadClients, this.loadErrorPost);
        }
    }

    reloadUserDelete = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={1000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Usuario borrado
                </SweetAlert>
            )
            this.props.loadDelete("/clients/" + this.id, this.reloadClients, this.loadErrorPost);
        } else {
            this.loadError("Error al borrar el usuario")
        }
    };

    reloadClients = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Cliente borrado
                </SweetAlert>
            )
            this.setState({
                redirect: (<Redirect to={"/admin/clients"}/>)
            });
        } else {
            this.loadError("Error al borrar el cliente")
        }
    }

}

export default EditClient;