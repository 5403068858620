import React, {Component} from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col, FormGroup,
    FormLabel, FormSelect,
    Placeholder,
    Row, Spinner,
    Table
} from "react-bootstrap";
import formatNumberString from "../../util/formatNumberString";
import {NavLink, Redirect} from "react-router-dom";
import ModalNewPeriod from "../periods/ModalNewPeriod";
import SweetAlert from "react-bootstrap-sweetalert";
import {FiPrinter} from "react-icons/fi";

class PrePurchases extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready_load_products: false,
            ready_load_providers: false,
            error: null,
            modal: null,
            inventory: [],
            providers: [],
            products: [],
            purchases: [],
            period: null,
            periods: [],
            modal_new: false,
            content: null,
        }
        this.purchases_loads = 0;
        this.purchases_error = 0;
    }

    componentDidMount() {
        this.loadInventoryAndProviders();
        this.loadPurchasesPeriods()
    }

    render() {
        return (
            <Card>
                <Card.Header className={"no-print-component"}>
                    Pre-Compras
                    <ButtonGroup className={"float-end"}>
                        <Button  size={"sm"} variant={"secondary"} onClick={() => window.print()}>
                            <i><FiPrinter/></i> Imprimir
                        </Button>
                        <NavLink to={"/admin/edit/inventory/products"} className={"btn btn-sm btn-primary"}>
                            Actualizar Inventario
                        </NavLink>
                        {this.state.period && this.state.purchases.length > 0 ? (
                            <Button size={"sm"} variant={"warning"} onClick={() => this.checkGenerateOrders()}>
                                Generar Compras para periodo {this.getNamePeriod()}
                            </Button>
                        ) : null}
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.content}
                    <ModalNewPeriod {...this.props} show={this.state.modal_new}
                                    onHide={() => this.setModalShow(false)}
                                    loadNewPeriod={this.loadNewPeriod}
                    />
                    {this.state.ready_load_products && this.state.ready_load_providers ?
                        this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadInventoryAndProviders()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <div className={"print-component"}>
                                {this.state.purchases.length > 0 ? (
                                    <>
                                        <Alert variant={"info no-print-component"}>
                                            Se debe seleccionar un periodo de compras para poder generar las compras
                                        </Alert>
                                        <FormGroup as={Row} className="mb-4 no-print-component">
                                            <FormLabel column sm="3">
                                                Periodo de Compra:
                                            </FormLabel>
                                            {this.state.period && this.state.periods.length > 0 ? (
                                                <Col sm="6">
                                                    <FormSelect onChange={
                                                        event => this.setState({
                                                            period: event.target.value
                                                        })}
                                                    >
                                                        {this.getPeriods()}
                                                    </FormSelect>
                                                </Col>
                                            ) : null}
                                            <Col>
                                                <Button onClick={() => this.createNewPeriod()}>
                                                    Agregar Nuevo Periodo
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                        {this.state.purchases.map((purchase, key) => {
                                            return (
                                                <Card className={"shadow p-3 mb-3 bg-white rounded"}
                                                      style={{marginBottom: '0px'}}>
                                                    <Card.Header>
                                                        {purchase.provider.name}
                                                        <small className={"text-muted"}>
                                                            <b> Tel:</b>{purchase.provider.phone}
                                                        </small>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Table>
                                                            <tr>
                                                                <th>
                                                                    Código
                                                                </th>
                                                                <th>
                                                                    Descripción
                                                                </th>
                                                                <th>
                                                                    Cant.
                                                                </th>
                                                                <th className={"text-center"}>
                                                                    V.U.
                                                                </th>
                                                                <th>
                                                                    Iva
                                                                </th>
                                                                <th className={"text-center"}>
                                                                    Total
                                                                </th>
                                                            </tr>
                                                            <tbody>
                                                            {purchase.products.map((product) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {product.code}
                                                                        </td>
                                                                        <td>
                                                                            {product.name}
                                                                        </td>
                                                                        <td className={"text-end"}>
                                                                            {formatNumberString(product.amount)}
                                                                        </td>
                                                                        <td className={"text-end"}>
                                                                            ${formatNumberString(product.value)}
                                                                        </td>
                                                                        <td>
                                                                            {product.iva}%
                                                                        </td>
                                                                        <td className={"text-end"}>
                                                                            ${formatNumberString(product.total)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            )
                                        })}
                                    </>
                                ) : (
                                    <p>No hay productos para generar compras - <NavLink
                                        to={"/admin/edit/inventory/products"}>
                                        Actualiza el inventario aquí
                                    </NavLink>
                                    </p>
                                )}
                            </div>
                        )
                        : (
                            <>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={12} size={"lg"} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Text} animation={"glow"}>
                                    <Placeholder xs={12} bg={"success"}/>
                                    <Placeholder xs={12} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Text} animation={"glow"}>
                                    <Placeholder xs={5} bg={"success"}/>
                                    <Placeholder xs={1} bg={"light"}/>
                                    <Placeholder xs={5} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                    <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                </Placeholder>
                            </>
                        )}
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        )
    }

    loadInventoryAndProviders = () => {
        this.props.loadGet("/providers/", this.loadProviders, this.loadError);
        let params = "status=active&no_available=true&&reserved=true"
        this.props.loadGet("/products?" + params, this.loadProducts, this.loadError);
        setTimeout(() => this.setProductsToProviders(), 600);
    }

    loadProviders = (providers) => {
        providers.sort((a, b) => {
            const nameA = a.establishment_name.toUpperCase();
            const nameB = b.establishment_name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
        this.setState({
            providers: providers,
        });
    }

    loadProducts = (products) => {
        products.sort((a, b) => {
            return a.code - b.code
        });
        this.setState({
            products: products,
        });
    }

    loadError = (error) => {
        this.setState({
            ready_load_products: true,
            ready_load_providers: true,
            error
        })
    }

    createLocalPurchases = () => {
        let local_providers = this.state.providers;
        let local_products = this.state.products;
        let new_purchases = []
        for (let provider of local_providers) {
            let purchase = {
                provider: provider,
                products: []
            }
            for (const localProduct of local_products) {
                if (localProduct.providers && localProduct.providers.length > 0) {
                    let first_provider = localProduct.providers[0];
                    if (first_provider.id === provider.id) {
                        purchase.products.push({
                            id: purchase.products.length,
                            code: localProduct.code,
                            amount: -localProduct.available,
                            value: localProduct.cost * (1 + localProduct.iva/100),
                            iva: localProduct.iva,
                            name: localProduct.name,
                            weight: localProduct.weight,
                            total: -localProduct.available * (localProduct.cost * (1 + localProduct.iva/100)),
                        })
                    }
                }
            }
            if (purchase.products.length > 0) {
                new_purchases.push(purchase);
            }
        }
        this.setState({
            purchases: new_purchases
        })
    }

    setProductsToProviders = () => {
        if (this.state.providers && this.state.providers.length > 0 && this.state.products
            && this.state.products.length > 0) {
            this.createLocalPurchases();
            this.setState({
                ready_load_providers: true,
                ready_load_products: true
            });
        } else {
            setTimeout(() => {
                this.setProductsToProviders();
            }, 500);
        }
    }

    createNewPeriod = () => {
        this.setModalShow(true);
    }

    setModalShow = (show) => {
        this.setState({modal_new: show})
    }

    loadNewPeriod = (values) => {
        this.setAlertSaving();
        this.postPeriod(values);
    }

    setAlertSaving = () => {
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el periodo en la base de datos
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    postPeriod = (values) => {
        this.props.loadPost("/purchase_periods", values, this.loadSuccessPost, this.loadErrorPost);
    }

    loadSuccessPost = (period) => {
        if (period) {
            this.loadSuccessPostAlert()
            let periods = this.state.periods
            periods.push(period)
            this.setState({
                period: period.id,
                periods: periods
            })
        } else {
            this.loadErrorPost("Error al crear el periodo")
        }
    }

    loadErrorPost = (onError) => {
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onError}
            </SweetAlert>
        )
    }
    loadSuccessPostAlert = () => {
        this.setAlert(
            <SweetAlert
                success
                title="Guardado!"
                showConfirm={false}
                timeout={2000}
                onConfirm={() => this.setAlert(null)}
            >
                Periodo Guardado
            </SweetAlert>
        );
    }

    loadPurchasesPeriods = () => {
        this.props.loadGet("/purchase_periods?status=active", this.loadPeriods, this.loadErrorPost)
    }

    loadPeriods = (periods) => {
        if (periods && periods.length > 0) {
            this.setState({
                periods,
                period: periods[0].id
            });

        }
    }

    getPeriods = () => {
        let periods = []
        for (const period of this.state.periods) {
            periods.push(<option key={period.id} selected={period.id===this.state.period}
                                 value={period.id}>{period.name}</option>)
        }
        return periods
    }

    getNamePeriod = () => {
        for (const period_id in this.state.periods) {
            if (this.state.periods[period_id].id === this.state.period) {
                return this.state.periods[period_id].name
            }
        }
    }

    checkGenerateOrders = () => {
        let title_var = "Quiere general las compras para el periodo " + this.getNamePeriod() + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.generateOrdersAndSend()}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="warning"
                confirmBtnText="Generar"
            >

            </SweetAlert>
        )
    }

    generateOrdersAndSend = () => {
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos generando las ordenes de compra, por favor espere
            </SweetAlert>
        );

        //TODO change this FOR each to other cycle with confirmation for one a one purchase
        for (const purchase of this.state.purchases) {
            purchase['period_id'] = this.state.period;
            purchase['purchase_period'] = this.getNamePeriod();
            this.props.loadPost("/purchases", purchase, this.sumPurchase, this.loadErrorPostCreatingPurchases);
        }
        this.checkFinish();
    }

    sumPurchase = (data) => {
        if (data) {
            this.purchases_loads = this.purchases_loads + 1;
        } else {
            this.loadErrorPostCreatingPurchases("Error")
        }
    }

    loadErrorPostCreatingPurchases = (error) => {
        this.purchases_error = this.purchases_error + 1;
        this.loadErrorPost(error);
    }

    checkFinish = () => {
        if (this.purchases_error > 0) {
            return 0;
        }
        if (this.purchases_loads === this.state.purchases.length) {
            this.setState({
                content: (<Redirect to={"/admin/edit/purchase_periods/" + this.state.period}/>)
            });
        } else {
            setTimeout(() => this.checkFinish(), 1000);
        }
    }
}

export default PrePurchases;