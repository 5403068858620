import React, {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, FormSelect, Row, Spinner} from "react-bootstrap";
import {AiFillSave} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {NavLink, Redirect} from "react-router-dom";
import * as Yup from "yup";

import {Formik, Form} from 'formik';
import cities from "./cities";
import SweetAlert from "react-bootstrap-sweetalert";
import advisoriesList from "../../util/advisoriesList";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    identification: Yup.string().required('Requedido'),
    phone: Yup.string(),
    city: Yup.string(),
    department: Yup.string(),
    address: Yup.string(),
    category: Yup.string(),
    list: Yup.number().min(0, "Minimo 0").max(100, "Maximo 100"),
    quota: Yup.number(),
    contact_name: Yup.string(),
    establishment_name: Yup.string(),
    route_id: Yup.string(),
    email: Yup.string().email('Correo Invalido'),
});

class NewClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: null,
            modal: null,
            error: false,
            ready: true
        }
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Nuevo Cliente
                    <NavLink to={"/admin/clients"}>
                        <Button className={"float-end"} size="sm" variant={"secondary"}>
                            <i><FiSearch/></i> Buscar Clientes
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.content}
                    <Formik
                        initialValues={{
                            name: '',
                            identification: '',
                            phone: '',
                            city: '',
                            department: '--',
                            address: '',
                            category: '',
                            list: 0,
                            quota: 0,
                            contact_name: '',
                            establishment_name: '',
                            route_id: '',
                            email: '',
                            advisory: '',
                        }}
                        validationSchema={schema}
                        onSubmit={values => {
                            this.saveClient(values);
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              errors
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"6"} controlId="validationIdentification">
                                        <FormLabel>Identificación del cliente (Nit o CC):</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="identification"
                                            value={values.identification}
                                            onChange={handleChange}
                                            isInvalid={!!errors.identification}
                                            placeholder={"Nit o Número de Identificación"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.identification}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"6"} controlId="validationName">
                                        <FormLabel>Nombre del cliente (Razon Social):</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                            placeholder={"Nombre"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.name}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"4"} controlId="validationPhone">
                                        <FormLabel>Número telefonico (Celular):</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                            isInvalid={!!errors.phone}
                                            placeholder={"Teléfono o Celular"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.phone}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"4"} controlId="validationDepartments">
                                        <FormLabel>Departamento:</FormLabel>
                                        <FormSelect
                                            name="department"
                                            value={values.department}
                                            onChange={handleChange}
                                            isInvalid={!!errors.department}
                                        >
                                            {this.getDepartments()}
                                        </FormSelect>
                                        <FormControl.Feedback type="invalid">
                                            {errors.department}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"4"} controlId="validationCities">
                                        <FormLabel>Ciudad:</FormLabel>
                                        <FormSelect
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            isInvalid={!!errors.city}
                                        >
                                            <option>--</option>
                                            {
                                                cities[values.department].map((city, key) => (
                                                    <option key={key}>{city}</option>
                                                ))
                                            }
                                        </FormSelect>
                                        <FormControl.Feedback type="invalid">
                                            {errors.city}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"5"} controlId="validationAddress">
                                        <FormLabel>Dirección:</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="address"
                                            value={values.address}
                                            onChange={handleChange}
                                            isInvalid={!!errors.address}
                                            placeholder={"Dirección"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.address}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"3"} controlId="validationCategory">
                                        <FormLabel>Categoria:</FormLabel>
                                        <FormSelect
                                            name="category"
                                            value={values.category}
                                            onChange={handleChange}
                                            isInvalid={!!errors.category}
                                        >
                                            <option>CLASSIC</option>
                                            <option>GOLD</option>
                                            <option>PLATINUM</option>
                                            <option>BLACK</option>
                                        </FormSelect>
                                        <FormControl.Feedback type="invalid">
                                            {errors.category}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"2"} controlId="validationList">
                                        <FormLabel>Lista de venta:</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="list"
                                            value={values.list}
                                            onChange={handleChange}
                                            isInvalid={!!errors.list}
                                            placeholder={"0"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.list}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"2"} controlId="validationQuota">
                                        <FormLabel>Credito:</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="quota"
                                            value={values.quota}
                                            onChange={handleChange}
                                            isInvalid={!!errors.quota}
                                            placeholder={"0"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.quota}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"4"} controlId="validationContactName">
                                        <FormLabel>Nombre de contacto:</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="contact_name"
                                            value={values.contact_name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.contact_name}
                                            placeholder={"Contacto"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.contact_name}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"4"} controlId="validationEstablishmentName">
                                        <FormLabel>Nombre del establecimiento:</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="establishment_name"
                                            value={values.establishment_name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.establishment_name}
                                            placeholder={"Establecimiento"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.establishment_name}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"4"} controlId="validationRoutes">
                                        <FormLabel>Ruta:</FormLabel>
                                        <FormSelect
                                            name="route_id"
                                            value={values.route_id}
                                            onChange={handleChange}
                                            isInvalid={!!errors.route_id}
                                        >
                                            <option>R1- RUTA 1</option>
                                            <option>R2</option>
                                            <option>R3</option>
                                            <option>R4</option>
                                            <option>R5</option>
                                            <option>R6</option>
                                            <option>R7</option>
                                            <option>R8</option>
                                            <option>R9</option>
                                            <option>R10</option>
                                        </FormSelect>
                                        <FormControl.Feedback type="invalid">
                                            {errors.route_id}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"8"} controlId="validationEmail">
                                        <FormLabel>Email:</FormLabel>
                                        <FormControl
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                            placeholder={"Email"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.email}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} sm={4} controlId={"validationAdvisory"}>
                                        <FormLabel><b>Asesor del Cliente:</b></FormLabel>
                                        <FormSelect
                                            name={"advisory"}
                                            value={values.advisory}
                                            onChange={handleChange}
                                        >
                                            <option value={""}></option>
                                            {advisoriesList.map( (advisory) => (
                                                <option value={advisory}>{advisory}</option>
                                            ))}
                                        </FormSelect>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type={"submit"} variant={"success"} disabled={!this.state.ready}
                                                className={"buttons-with-all-width"}
                                        >
                                            <i>
                                                <AiFillSave/>
                                            </i> Guardar Cliente
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
                <Card.Footer/>
            </Card>
        );
    }

    getDepartments = () => {
        let deps = [];
        for (let department in cities) {
            deps.push(<option key={department}>{department}</option>)
        }
        return deps;
    }

    saveClient = (values) => {
        values['name'] = values.name.toUpperCase();
        values['identification'] = values.identification.toUpperCase();
        values['address'] = values.address.toUpperCase();
        values['establishment_name'] = values.establishment_name.toUpperCase();
        values['contact_name'] = values.contact_name.toUpperCase();

        this.setState({
            ready: false,
        });
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el cliente en la base de datos
            </SweetAlert>
        );
        this.props.loadPost("/clients", values, this.redirectToEdit, this.loadError);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    redirectToEdit = (client) => {
        if (client) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    showConfirm={false}
                    closeOnClickOutside={false}
                >
                    Cliente guardado
                </SweetAlert>
            )
            this.setState({
                content: (<Redirect to={"/admin/edit/clients/" + client.id}/>)
            });
        } else {
            this.loadError("Error al crear el cliente")
        }
    };

    loadError = (onerror) => {
        this.setState({ready: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }
}

export default NewClient;