import {Component} from "react";
import {Card, Row} from "react-bootstrap";
import loadDateAndTime from "../../util/dateAndTimeToString";

class LogsOrder extends Component{
    render() {
        let order = this.props.order;
        if( order.logs && order.logs.length > 0 ){
            return(
                <Card>
                    <Card.Header>
                        Auditoría:
                    </Card.Header>
                    <Card.Body>
                        {order.logs.map((log) => (
                            <Row className={"mb-1"}>
                                <small>{loadDateAndTime(log.date)} - <b>{log.user}</b>: {log.message}</small>
                            </Row>
                            )
                        )}
                    </Card.Body>
                </Card>
            )
        } return (
            <Card>
                <Card.Header>
                    Auditoría no disponible
                </Card.Header>
            </Card>
        )

    }
}
export default LogsOrder;