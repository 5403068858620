import React, {Component} from "react";
import {
    Accordion,
    Alert,
    Button,
    Card,
    Col,
    FormControl,
    FormFloating,
    Placeholder,
    Row,
    Spinner
} from "react-bootstrap";
import * as Yup from "yup";

import {Formik, Form} from 'formik';
import SweetAlert from "react-bootstrap-sweetalert";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

const schema = Yup.object().shape({
    q: Yup.string()
});

class NewOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: true,
            error: false,
            clients: null,
            modal: null,
            query: '',
            content: null
        }
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Crear Pedido
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.content}
                    <Formik
                        initialValues={{
                            q: ''
                        }}
                        onSubmit={values => {
                            this.searchClientForOrders(values)
                        }}
                        validationSchema={schema}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormFloating className={"mb-3"}>
                                    <FormControl
                                        type={"text"}
                                        id={"query"}
                                        placeholder={"Buscar cliente"}
                                        name={"q"}
                                        onChange={handleChange}
                                        value={values.q}
                                    />
                                    <label htmlFor={"query"}>Buscar cliente</label>
                                </FormFloating>
                            </Form>
                        )}
                    </Formik>
                    {this.state.ready ? (
                        <>{this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.searchClientForOrders(this.state.query)} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <>{this.state.clients}</>
                        )}</>
                    ) : (
                        <>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={11}/>
                                <Placeholder xs={10}/>
                                <Placeholder xs={9}/>
                                <Placeholder xs={10}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
                <Card.Footer/>
            </Card>
        );
    }

    searchClientForOrders = (values) => {
        this.setState({
            query: values,
        });
        let query = values.q.toUpperCase();
        if (query) {
            this.setState({
                ready: false
            });
            this.props.loadGet("/clients?q=" + query, this.loadContent, this.loadError)
        } else {
            this.setState({
                ready: true,
                clients: null,
                error: false
            })
        }
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            clients: this.paintClients(data)
        })
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    paintClients = (data) => {
        if (data && data.length > 0) {
            return (
                <div>
                    <h5>Se encontraron {data.length} resultados:</h5>
                    {this.paintClientsRows(data)}
                </div>
            )
        } else {
            return (
                <h5 className={"text-center"}>No se encontraron resultados para crear la orden</h5>
            )
        }
    }

    paintClientsRows = (data) => {
        const rows = data.map((client, key) => (
            <Accordion.Item eventKey={key} key={key}>
                <Accordion.Header>
                    <small className="text-muted">[{client.identification}] </small> {client.name}
                </Accordion.Header>
                <Accordion.Body>
                    <Row className={"mb-3"}>
                        <Col md={"3"}><small className="text-muted"> Departamento:</small> {client.department}</Col>
                        <Col md={"3"}><small className="text-muted"> Municipio:</small> {client.city}</Col>
                        <Col md={"3"}><small className="text-muted"> Telefono:</small> {client.phone}</Col>
                        <Col md={"3"}><small className="text-muted"> Contacto:</small> {client.contact_name}</Col>
                        <Col md={"3"}><small className="text-muted"> Dirección:</small> {client.address}</Col>
                        <Col md={"3"}><small
                            className="text-muted"> Cupo:</small> ${new Intl.NumberFormat('de-DE').format(client.quota)}
                        </Col>
                        <Col md={"3"}><small
                            className="text-muted"> Establecimiento:</small> {client.establishment_name}</Col>
                        <Col md={"3"}><small className="text-muted"> Categoria:</small> {client.category}</Col>
                    </Row>
                    <Row>
                        <Button variant={"outline-success"} onClick={() => this.createOrder(client)}>Crear pedido
                            para {client.name}</Button>
                    </Row>

                </Accordion.Body>
            </Accordion.Item>
        ));
        return (
            <Accordion>{rows}</Accordion>
        )
    }

    createOrder = (client) => {
        this.setAlert(
            <SweetAlert
                custom
                title="Seleccionando Cliente!"
                showConfirm={false}
                closeOnClickOutside={false}
                onConfirm={() => {
                }}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos preparando una nueva orden para {client.name}
            </SweetAlert>
        );
        let values = {
            "client_id": client.id,
            "client": client,
            "logs": [{
                "message": "Creó orden con cliente "+ client.name + " ID:" + client.id,
                "user": this.props.user.name ? this.props.user.name : this.props.user.email
            }]
        }
        this.props.loadPost("/orders", values, this.redirectToEdit, this.loadError);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    redirectToEdit = (order) => {
        if(order) {
            this.setState({
                content: (<Redirect to={"/admin/edit/orders/" + order.id}/>)
            });
        } else {
            this.loadError("Error al crear la orden")
        }
    }

    loadError = (onerror) => {
        this.setState({ready: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps, {})(NewOrder);