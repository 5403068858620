import React, {Component} from "react";


import image from "../assets/img/full-screen-image-3.jpg";
import Sidebar from "../components/Sidebar";
import AdminNavbar from "../components/AdminNavbar";
import Footer from "../components/Footer";
import {Redirect, Route} from "react-router-dom";
import routes from "../routes";
import {connect} from 'react-redux';
import {
    clearRefreshToken,
    clearToken,
    clearUser,
    saveRefreshToken,
    saveToken,
    setMini,
    setUser
} from "../initializers/actions";
import MainPanel from "../components/MainPanel";
import axios from "axios";
import properties from "../properties";
import {Alert, Button, Card, Container} from "react-bootstrap";


//let ps;

class AdminLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: "green",
            image: image,
            hasImage: true,
            navbar: false,
            fixedClasses: "",
            error: false,
            functionToken: null,
            user: this.props.user,
            search: null
        }
    }

    setError = (error) => {
        this.setState({error})
    }

    functionLoadAfterToken = (loadFunction) => {
        this.setState({functionToken: loadFunction})
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            //ps = new PerfectScrollbar(this.refs.mainPanel);
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            //ps.destroy();
        }
    }

    componentDidUpdate(e) {
        if (navigator.platform.indexOf("Win") > -1) {
            setTimeout(() => {
                //ps.update();
            }, 350);
        }
        if (e.history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            //this.refs.mainPanel.scrollTop = 0;
        }
        if (
            window.innerWidth < 993 &&
            e.history.action === "PUSH" &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    componentWillMount() {
        if (document.documentElement.className.indexOf("nav-open") !== -1) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    handleMiniClick = () => {
        this.props.setMini(!this.props.mini)
        document.body.classList.toggle("sidebar-mini");
    };

    render() {
        if (this.state.user) {
            return (
                <div className={"wrapper"}>
                    <Sidebar
                        {...this.props}
                        image={this.state.image}
                        color={this.state.color}
                        hasImage={this.state.hasImage}
                    />
                    <div
                        className={"main-panel"}
                        useref="mainPanel"
                    >
                        <AdminNavbar
                            {...this.props}
                            handleMiniClick={this.handleMiniClick}
                            navbar={this.state.navbar}
                            setSearch={this.setSearchState}
                        />
                        {
                            this.state.error ? (
                                <>{this.getError()}</>
                            ) : (
                                <MainPanel
                                    route={this.getRoutes(routes)}
                                />
                            )
                        }
                        <Footer fluid/>
                    </div>
                </div>
            );
        } else {
            return (
                <Redirect
                    to={"/logout"}
                />
            )
        }

    }

    getRoutes = routes => {
        return routes.map(
            (prop, key) => {
                if (prop.collapse) {
                    return this.getRoutes(prop.views)
                }
                let params = prop.params ? prop.params : "";
                if (prop.layout === "/admin") {
                    return (
                        <Route
                            path={prop.layout + prop.path + params}
                            key={key}
                            render={routeProps => (
                                <prop.component
                                    {...routeProps}
                                    loadGet={this.loadGet}
                                    loadPost={this.loadPost}
                                    loadPut={this.loadPut}
                                    loadDelete={this.loadDelete}
                                    loadGetBucket={this.loadGetBucket}
                                />
                            )}
                        />
                    )
                } else {
                    return null;
                }
            }
        )
    }

    getError = () => {
        return (
            <div className={"main-content"}>
                <Container fluid>
                    <Card bg={"secondary"} text={"white"}>
                        <Card.Header>Error</Card.Header>
                        <Card.Body>
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadNewToken(this.state.functionToken)}
                                        variant={"light"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        </Card.Body>
                        <Card.Footer>
                        </Card.Footer>
                    </Card>
                </Container>
            </div>
        )
    }

    loadGet = (endpoint, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "GET",
            headers: {
                'Authorization': this.props.token
            }
        }).then((response) => {
            if (response.data) {
                try {
                    loadFunction(response.data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if(error.response.status === 401 || error.response.status ===403){
                    this.loadNewToken(() => this.loadGet(endpoint, loadFunction, errorFunction));
                    return;
                } else {

                }
                errorFunction(JSON.stringify(error.response.data));
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadPost = (endpoint, data, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "POST",
            headers: {
                'Authorization': this.props.token
            },
            data: data
        }).then((response) => {
            if (response.data) {
                try {
                    loadFunction(response.data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if(error.response.status === 401 || error.response.status ===403){
                    this.loadNewToken(() => this.loadPost(endpoint, data, loadFunction, errorFunction));
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadPut = (endpoint, data, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "PUT",
            headers: {
                'Authorization': this.props.token
            },
            data: data
        }).then((response) => {
            if (response.data) {
                try {
                    loadFunction(response.data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if(error.response.status === 401 || error.response.status ===403){
                    this.loadNewToken(() => this.loadPut(endpoint, data, loadFunction, errorFunction));
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        });
    }

    loadDelete = (endpoint, loadFunction, errorFunction) => {
        axios({
            url: properties.api_host + endpoint,
            method: "DELETE",
            headers: {
                'Authorization': this.props.token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    loadFunction(response);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                if(error.response.status === 401 || error.response.status ===403){
                    this.loadNewToken(() => this.loadDelete(endpoint, loadFunction, errorFunction))
                    return;
                } else {
                    errorFunction(JSON.stringify(error.response.data));
                }
            } else {
                errorFunction(JSON.stringify(error));
            }
        })
    }

    loadNewToken = (loadFunction) => {
        this.functionLoadAfterToken(loadFunction)
        this.setError(false)
        axios({
            url: properties.url_token_r,
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': properties.basic,
            },
            params: {
                refresh_token: this.props.refresh
            },
            data: ''
        }).then((response) => {
            if (response.data) {
                let data = response.data
                this.props.saveToken(data.access_token);
                loadFunction()
            } else {
                this.setError(JSON.stringify(response));
            }
        }).catch((onerror) => {
            if (onerror.response) {
                this.setState({
                    user: false
                });
            } else {
                this.setError("Error no Identificado")
            }
        })
    }

    loadGetBucket = (endpoint, loadFunction, errorFunction) => {
        axios({
            url: properties.bucket_host + endpoint,
            method: "GET",
            responseType: 'arraybuffer'
        }).then((response) => {
            if (response.data) {
                try {
                    let data = Buffer.from(response.data, 'binary').toString('base64');
                    loadFunction("data:image/png;base64,"+data);
                } catch (error) {
                    errorFunction("Application Error: " + error);
                }
            } else {
                errorFunction(JSON.stringify(response));
            }
        }).catch((error) => {
            if (error.response) {
                errorFunction(JSON.stringify(error.response.data))
            } else {
                errorFunction(JSON.stringify(error))
            }
        })
    }

    setSearchState =  (values) => {
        this.setState({
            search: values.q
        });

    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        user: state.user,
        mini: state.mini,
        refresh: state.refresh
    }
}

const mapDispatchToProps = {
    setMini,
    saveToken,
    saveRefreshToken,
    setUser,
    clearToken,
    clearRefreshToken,
    clearUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);