import React, {Component} from "react";
import {Button, Col, FormControl, Navbar} from "react-bootstrap";

import {FaEllipsisV, FaBars} from 'react-icons/fa';
import HeaderLinks from "./AdminNavbarLinks";

import routes from "../routes";
import { Form, Formik} from "formik";

class AdminNavbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_search: false
        }
    }

    getActiveRoutesString = routes => {
        let activeRoute = "Not fount this page"
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoutesString(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    document.title = routes[i].name + " - Back Office | LIDER CAMARGO S.A.S. "
                        + (1900 + new Date().getYear())
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }

    getActiveRoutesPath = routes => {
        let activeRoute = "/404"
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoutesPath(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].layout + routes[i].path;
                }
            }
        }
        return activeRoute;
    }

    mobileSidebarToggle = e => {
        document.documentElement.classList.toggle("nav-open");
    };

    render() {
        return (
            <Navbar className={this.props.navbar ? "navbar-fixed" : ""} collapseOnSelect bg={"light"} expand={"lg"}
                    variant={"light"}>
                <div className={"navbar-minimize"}>
                    <Button
                        id={"minimizeSidebar"}
                        className={"btn-success btn-fill btn-round btn-icon"}
                        onClick={this.props.handleMiniClick}
                    >
                        <FaEllipsisV className={"visible-on-sidebar-regular"}/>
                        <FaBars className={"visible-on-sidebar-mini"}/>
                    </Button>
                </div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.mobileSidebarToggle}/>
                <Navbar.Brand
                    href={this.getActiveRoutesPath(routes)}
                >
                    {this.getActiveRoutesString(routes)}
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Col>
                        <Formik
                            initialValues={{
                                q: ''
                            }}
                            onSubmit={values => {
                                this.search(values)
                            }}
                        >
                            {(
                                {
                                    handleSubmit,
                                    handleChange,
                                    values
                                }
                            ) => (
                                <Form onSubmit={handleSubmit}>
                                    <FormControl
                                        type="search"
                                        placeholder="Buscar"
                                        className="me-2"
                                        aria-label="Search"
                                        name={"q"}
                                        value={values.q}
                                        onChange={handleChange}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Col>
                    {window.innerWidth >= 992 ? (
                        <HeaderLinks/>
                    ) : null}
                </Navbar.Collapse>
            </Navbar>
        );
    }

    search = (values) => {
        this.props.setSearch(values)
    }
}

export default AdminNavbar;
