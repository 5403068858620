import React, {Component} from "react";
import {Alert, Badge, Button, Card, Col, Placeholder, Row} from "react-bootstrap";
import {GrAdd} from "react-icons/gr";
import {NavLink} from "react-router-dom";
import formatNumberString from "../../util/formatNumberString";
import loadDate from "../../util/datesToString";

class OrdersDashboard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            orders: [],
        }
    }

    componentDidMount() {
        this.downloadActiveOrders();
    }

    render() {
        return(
            <Card>
                <Card.Header>
                    <NavLink to={"/admin/orders"}>
                        Ultimas Ordenes
                    </NavLink>

                    <NavLink to={"/admin/new/orders"}>
                        <Button className={"float-end"} size="sm" variant={"info"}>
                            <i><GrAdd/></i> Crear Nuevo
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    <NavLink to={"/admin/orders"}>
                        Ver todas las ordenes recientes
                    </NavLink>
                    <hr/>
                    {this.state.ready? (
                        this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button variant={"dark"}
                                        onClick={
                                            () => this.downloadActiveOrders()
                                }
                                >
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ): (
                            <div>
                                {this.state.orders.map( (order, key) => (
                                    <Card className={"shadow p-3 mb-3 bg-white rounded"} style={{marginBottom:'0px'}}>
                                        <Row style={{alignItems: 'center'}}>
                                            <Col className={"text-info"} sm={1}>
                                                <NavLink to={"/admin/edit/orders/" + order.id}>
                                                    <Badge bg={"success"}>
                                                        {order.id}
                                                    </Badge>
                                                </NavLink>
                                            </Col>
                                            <Col sm={3} md={2}>
                                                {loadDate(order.date)}
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>{order.client.name}</Col>
                                                </Row>
                                                <Row>
                                                    <small className={"text-muted"}>
                                                        {order.client.city} ({order.client.department})
                                                    </small>
                                                </Row>
                                            </Col>
                                            {order.from_store? (
                                                <Col sm={1}>
                                                    <Badge bg={"primary"}> Tienda </Badge>
                                                </Col>
                                            ): null}
                                            <Col style={{textAlign: 'right'}} sm={3} md={2}>
                                                ${formatNumberString(parseInt(order.total))}
                                            </Col>
                                            <Col sm={1} className={"text-center"}>
                                                <Row>
                                                    <NavLink to={"/admin/edit/orders/" + order.id}
                                                             className={"btn btn-primary btn-sm"}
                                                    >
                                                        Editar
                                                    </NavLink>
                                                </Row>

                                            </Col>

                                        </Row>
                                    </Card>
                                ))}
                            </div>
                        )
                    ):(
                        <>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={12} bg={"success"}/>
                            </Placeholder>
                        </>
                    )}
                    <Row className={"text-center"}>
                        <Col>
                            <Card className={"shadow p-3 bg-white rounded"} style={{marginBottom:'0px'}}>
                                <NavLink to={"/admin/process.orders"} className={"text-warning"}>
                                    Ver Ordenes en Proceso
                                </NavLink>
                            </Card>
                        </Col>
                        <Col>
                            <Card className={"shadow p-3 bg-white rounded"} style={{marginBottom:'0px'}}>
                                <NavLink to={"/admin/inactive.orders"} className={"text-danger"}>
                                    Ver Ordenes Cerradas
                                </NavLink>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        )
    }

    downloadActiveOrders = () => {
        this.props.loadGet("/orders?status=active", this.loadContent, this.loadError);
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            orders: data
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }
}
export default OrdersDashboard;