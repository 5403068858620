import React, {Component} from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col,
    FormControl,
    FormGroup,
    FormLabel, FormSelect,
    Placeholder,
    Row,
    Spinner
} from "react-bootstrap";

import {Form, Formik} from 'formik';
import * as Yup from "yup";
import {AiFillDelete, AiFillSave} from "react-icons/ai";
import {FiUsers} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import SweetAlert from "react-bootstrap-sweetalert";
import {NavLink, Redirect} from "react-router-dom";
import BackButton from "../../util/backButton";
import ModalSearchClientForUser from "./ModalSearchClientForUser";

const schemaEdit = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    last_name: Yup.string().required('Requerido'),
    email: Yup.string().email('Correo Invalido').required('Requerido'),
    phone: Yup.number(),
    role: Yup.string().required("Requerido"),
    identification: Yup.number(),
    client_id: Yup.string(),
    UserStatus: Yup.string(),
});

class EditUsers extends Component {
    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            user: null,
            modal: null,
            ready_post: true,
            error_post: false,
            redirect: null,
            modal_user: null,
            client_id: null,
        }
    }

    componentDidMount() {
        this.loadUser();
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Editar usuario
                    <ButtonGroup className={"float-end"}>
                        <BackButton size={"sm"}/>
                        <Button size="sm" variant={"secondary"}>
                            <NavLink to={"/admin/users/clients"}>
                                <i><FiUsers/></i> Todos los usuarios
                            </NavLink>
                        </Button>
                        <Button size="sm" variant={"primary"}>
                            <NavLink to={"/admin/new/users"}>
                                <i><GrAdd/></i> Crear Nuevo
                            </NavLink>
                        </Button>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.redirect}
                    {
                        this.state.ready ? (
                            <>{
                                this.state.error ? (
                                    <>
                                        <Alert variant={"danger"}>
                                            <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                            <p>
                                                {this.state.error}
                                            </p>
                                            <hr/>
                                            <Button onClick={() => this.loadUser()} variant={"dark"}>
                                                Intentar nuevamente
                                            </Button>
                                        </Alert>
                                    </>
                                ) : (
                                    <>
                                        <ModalSearchClientForUser
                                            {...this.props} show={this.state.modal_user}
                                            onHide={() => this.setModalShow(false)}
                                            setClientId={ (client_id) => {
                                                this.setState(
                                                    {client_id}
                                                );
                                            }}
                                        />
                                        {this.loadForm(this.state.user)}
                                    </>
                                )
                            }</>
                        ) : (
                            <>
                                <Placeholder as={Card.Text} animation={"glow"}>
                                    <Placeholder xs={5} bg={"success"}/>
                                    <Placeholder xs={1} bg={"light"}/>
                                    <Placeholder xs={5} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                    <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Text} animation={"glow"}>
                                    <Placeholder xs={5} bg={"success"}/>
                                    <Placeholder xs={1} bg={"light"}/>
                                    <Placeholder xs={5} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                    <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                </Placeholder>
                                <Placeholder.Button variant="success" xs={6}/>
                                <Placeholder.Button variant="danger" xs={6}/>
                            </>
                        )
                    }
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        )
    }

    loadUser = () => {
        this.props.loadGet("/users/" + this.id, this.loadContent, this.loadError)
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            user: data,
            client_id: data.client_id ? data.client_id : '',
        })
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    loadForm = (data) => {
        if (data) {
            return (
                <Formik
                    initialValues={{
                        name: data.name ? data.name : '',
                        last_name: data.last_name ? data.last_name : '',
                        email: data.email ? data.email : '',
                        phone: data.phone ? data.phone : '',
                        identification: data.identification ? data.identification : '',
                        role: data.role ? data.role : "client",
                        client_id: data.client_id ? data.client_id : '',
                        UserStatus: data.status,
                    }}
                    validationSchema={schemaEdit}
                    onSubmit={values => {
                        this.saveUser(values, data.id)
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors,
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"6"} controlId={"validationName"}>
                                    <FormLabel>Nombres:</FormLabel>
                                    <FormControl
                                        type={"text"}
                                        name={"name"}
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                        placeholder={"Nombres"}
                                    />
                                    <FormControl.Feedback type={"invalid"}>
                                        {errors.name}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"6"} controlId="validationLastName">
                                    <FormLabel>Apellidos:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.last_name}
                                        placeholder={"Apellidos"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.last_name}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className="mb-3">
                                <FormGroup as={Col} md={"6"} controlId="validationEmail">
                                    <FormLabel>Correo Electronico:</FormLabel>
                                    <FormControl
                                        type="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={!!errors.email}
                                        placeholder={"Correo electronico"}
                                        disabled={true}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.email}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"6"} controlId="validationIdentification">
                                    <FormLabel>Teléfono:</FormLabel>
                                    <FormControl
                                        type="phone"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                        placeholder={"320 000 0000"}
                                        style={{textAlign: 'right'}}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.phone}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className="mb-3">
                                <FormGroup as={Col} md={"4"} controlId="validationIdentification">
                                    <FormLabel>Numero de Identificación:</FormLabel>
                                    <FormControl
                                        type="number"
                                        name="identification"
                                        value={values.identification}
                                        onChange={handleChange}
                                        isInvalid={!!errors.identification}
                                        placeholder={"000000000000"}
                                        style={{textAlign: 'right'}}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.identification}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationIdentification">
                                    <FormLabel>Estado del Usuario:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="UserStatus"
                                        value={values.UserStatus}
                                        onChange={handleChange}
                                        isInvalid={!!errors.UserStatus}
                                        placeholder={"000000000000"}
                                        disabled
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.UserStatus}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationRole">
                                    <FormLabel>Rol:</FormLabel>
                                    <FormSelect
                                        name="role"
                                        value={values.role}
                                        onChange={handleChange}
                                        isInvalid={!!errors.role}
                                    >
                                        <option value={"admin"}>Administrador</option>
                                        <option value={"client"}>Cliente</option>
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {errors.role}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"6"} controlId="validationClientID">
                                    <FormLabel>ClientID:</FormLabel>
                                    {this.state.client_id?(
                                        <Row>
                                            <Col>
                                                <NavLink to={"/admin/edit/clients/"+this.state.client_id}
                                                         className={"btn btn-info"}
                                                >
                                                    Ver Cliente ID: <small>{this.state.client_id}</small>
                                                </NavLink>
                                            </Col>
                                        </Row>
                                        ):(
                                        <FormControl
                                            type="string"
                                            value={this.state.client_id}
                                            placeholder={"NO ASIGNADO"}
                                            disabled={true}
                                        />
                                        )}
                                </FormGroup>
                                <Col>
                                    <FormLabel>Cambiar o asignar:</FormLabel>
                                    <Row>
                                        <Col>
                                            <Button variant={this.state.client_id?"warning":"primary"}
                                                    onClick={() => this.setModalShow(true)}
                                            >
                                                {this.state.client_id ? "Cambiar Cliente" : "Asignar Cliente"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col md={"6"}>
                                    <Button type="submit" variant={"success"} disabled={!this.state.ready_post}
                                            className={"buttons-with-all-width"}>
                                        <i>
                                            <AiFillSave/>
                                        </i> Guardar Usuario
                                    </Button>
                                </Col>
                                <Col md={6}>
                                    <Button variant={"danger"} className={"buttons-with-all-width"}
                                            onClick={() => this.checkDelete(data)}
                                    >
                                        <i><AiFillDelete/></i> Eliminar usuario
                                    </Button>
                                </Col>

                            </Row>
                        </Form>
                    )}
                </Formik>
            )
        } else {
            return (
                <p>No hay datos disponibles</p>
            )
        }
    }

    saveUser = (values, user_id) => {
        delete values.UserStatus;
        values['client_id'] = this.state.client_id
        this.setState({
            ready_post: false,
            modal: (
                <SweetAlert
                    custom
                    title="Guardando!"
                    showConfirm={false}
                    closeOnClickOutside={false}
                    customIcon={(
                        <div className={"text-center"}>
                            <Spinner animation={"border"} variant={"success"}/>
                        </div>
                    )}
                >
                    Estamos guardando el usuario en la base de datos
                </SweetAlert>
            )
        });
        if(this.state.client_id){
            let values_client = {
                has_access: true,
                access_id: user_id,
                email: values.email
            }
            this.props.loadPut("/clients/" + this.state.client_id, values_client, this.successSaveUser, this.loadErrorPost);
        }
        this.props.loadPut("/users/" + user_id, values, this.successSaveUser, this.loadErrorPost);
    }

    successSaveUser = (user) => {
        if (user) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    onConfirm={() => this.setAlert(null)}
                >
                    Usuario guardado
                </SweetAlert>
            )
            this.setState({ready_post: true})
        } else {
            this.loadErrorPost("Error al actualizar el usuario")
        }
    };

    loadErrorPost = (onerror) => {
        this.setState({ready_post: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    checkDelete = (user) => {
        let title_var = "Quiere eliminar el usuario " + (user.name ? user.name : user.email) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeleteAlert(user)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El usuario no se puede recuperar despues de eliminado
            </SweetAlert>
        )
    }

    loadDeleteAlert = (user) => {
        let title_var = "Eliminando el usuario " + (user.name ? user.name : user.email) + "!"
        let user_id = user.Username
        this.setAlert(
            <SweetAlert
                custom
                title={title_var}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado el usuario
            </SweetAlert>
        )
        this.props.loadDelete("/users/" + user_id, this.reloadUser, this.loadErrorPost);
    }

    reloadUser = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Usuario borrado
                </SweetAlert>
            )
            this.setState({
                redirect: (<Redirect to={"/admin/users"}/>)
            });
        } else {
            this.loadError("Error al borrar el usuario")
        }
    };

    setModalShow = (show) => {
        this.setState({modal_user: show})
    }
}

export default EditUsers;