export const saveToken = (token) => {
    return {
        type: 'SET_TOKEN',
        token: token
    }
}

export const clearToken = () => {
    return {
        type: 'CLEAR_TOKEN'
    }
}

export const saveRefreshToken = (refresh) => {
    return {
        type: 'SET_REFRESH_TOKEN',
        refresh: refresh
    }
}

export const clearRefreshToken = () => {
    return {
        type: 'CLEAR_REFRESH_TOKEN'
    }
}

export const setUser = (user) => {
    return {
        type: 'LOGGED_IN',
        user
    }
}
export const clearUser = () => {
    return {
        type: 'SIGN_OUT'
    }
}

export const setMini = (mini) => {
    return {
        type: 'SET_MINI',
        mini
    }
}
export const clearMini = () => {
    return {
        type: 'CLEAR_MINI'
    }
}

export const setProducts = (products) => {
    return {
        type: 'SET_PRODUCTS',
        products
    }
}
export const clearProducts = () => {
    return {
        type: 'CLEAR_PRODUCTS'
    }
}

export const setPhotos = (photos) => {
    return {
        type: 'SET_PHOTOS',
        photos
    }
}
export const clearPhotos = () => {
    return {
        type: 'CLEAR_PHOTOS'
    }
}