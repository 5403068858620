import React, {Component} from "react";
import {FormSelect, FormControl, Button, Spinner, Badge} from "react-bootstrap";
import {AiFillDelete} from "react-icons/ai";
import {BiCheckDouble} from "react-icons/bi";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalSearchProduct from "./ModalSearchProduct";
import utilizeFocus from "../../util/focusElement";

class RowFormProductOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            option: this.props.product ? this.props.product.option : true,
            code: this.props.product ? this.props.product.code : 0,
            name: this.props.product ? this.props.product.name : '',
            iva: this.props.product ? this.props.product.iva : 0,
            weight: this.props.product ? this.props.product.weight : 0,
            differential: this.props.product ? this.props.product.differential : 0,
            value: this.props.product ? this.props.product.value : 0,
            amount: this.props.product ? this.props.product.amount : 0,
            saved: !!(this.props.product && this.props.product.code),
            was_modify: false,
            ready_load_inventory: false,
            load_inventory: 0,
            modal_error: null,
            modalSearch: false,
        }
        this.inputSearch = utilizeFocus()
    }

    render() {
        let key = this.props.product.id;
        let refInputOption = this.props.inputOptionNewProduct[key] ? this.props.inputOptionNewProduct[key].ref : null
        let refInputCode = this.props.inputCodeProducts[key] ? this.props.inputCodeProducts[key].ref : null
        let refInputAmount = this.props.inputCantProducts[key] ? this.props.inputCantProducts[key].ref : null
        let refInputValue = this.props.inputValuesProducts[key] ? this.props.inputValuesProducts[key].ref : null
        //console.log("Repaint product", key, this.state.name, this.state.code)
        return (
            <tr>
                {this.state.modal_error}
                <ModalSearchProduct {...this.props} show={this.state.modalSearch}
                                    onHide={() => this.setModalSearchShow(false)}
                                    setProductSelected={this.setProductSelected}
                                    focusSearch={this.inputSearch}
                />
                <td style={{minWidth: "65px"}}>
                    <FormSelect
                        name={"option"}
                        size={"sm"}
                        value={this.state.option}
                        onChange={event => {
                            this.setState({
                                option: event.target.value
                            });
                            setTimeout(() =>
                                this.setState({
                                    differential: this.calculateDifferentialValue(this.state.value)
                                }), 250
                            )

                        }}
                        disabled={!this.props.enabled}
                        id={`product.${key}.option`}
                        ref={refInputOption}
                        onKeyPress={
                            (event) => {
                                event.preventDefault();
                                if (event.key === "Enter") {
                                    this.props.inputCodeProducts[key].setFocus();
                                }
                                if (event.key === "1") {
                                    this.setState({
                                        option: true
                                    });
                                }
                                if (event.key === "2") {
                                    this.setState({
                                        option: false
                                    });
                                }
                                if (event.key === "0") {
                                    this.setState({
                                        option: !this.state.option
                                    });
                                }
                            }
                        }
                    >
                        <option value={true}>1</option>
                        <option value={false}>2</option>
                    </FormSelect>
                </td>
                <td style={{minWidth: "100px"}}>
                    <FormControl
                        type="number"
                        size={"sm"}
                        value={this.state.code}
                        ref={refInputCode}
                        onChange={
                            event => {
                                this.searchProductLocal(event.target.value)
                            }
                        }
                        placeholder={"123"}
                        id={`product.${key}.code`}
                        disabled={!this.props.enabled}
                        style={{textAlign: 'center'}}
                        onKeyPress={
                            (event) => {
                                if (event.key === "Enter") {
                                    if (this.state.code && this.state.name) {
                                        this.props.inputCantProducts[key].setFocus();
                                    } else {
                                        this.setModalSearchShow(true);
                                        setTimeout(
                                            () => this.inputSearch.setFocus(), 300
                                        )
                                    }
                                }
                            }
                        }
                        onWheel={
                            (event) => {
                                event.preventDefault();
                                event.target.blur();
                                event.stopPropagation();
                                setTimeout(() => {
                                    event.target.focus()
                                }, 0);
                            }
                        }
                        onFocus={e => e.target.select()}
                    />
                </td>
                <td style={{minWidth: "160px"}}>
                    {this.state.name ? (
                        <div>
                            {this.state.name}
                        </div>
                    ) : (
                        <Button
                            size={"sm"}
                            onClick={() => {
                                this.setModalSearchShow(true);
                                setTimeout(
                                    () => this.inputSearch.setFocus(), 300
                                )
                            }}
                        >
                            Buscar
                        </Button>
                    )}
                </td>
                <td className={"text-center"} style={{minWidth: "65px"}}>
                    {this.state.was_modify ? (
                        this.state.ready_load_inventory ? (
                            <FormControl
                                type="text"
                                value={new Intl.NumberFormat('de-DE').format(
                                    this.state.load_inventory
                                )}
                                readOnly
                                style={{textAlign: 'right'}}
                            />
                        ) : (
                            <Spinner animation={"border"}/>
                        )
                    ) : null}
                </td>
                <td style={{minWidth: "100px"}}>
                    <FormControl
                        type="number"
                        size={"sm"}
                        value={this.state.amount}
                        onChange={event => {
                            this.validateAndSaveAmount(event.target.value)
                        }}
                        placeholder={"0"}
                        disabled={!this.props.enabled}
                        style={{textAlign: 'right'}}
                        id={`product.${key}.amount`}
                        ref={refInputAmount}
                        onKeyPress={
                            (event) => {
                                if (event.key === "Enter") {
                                    this.props.inputValuesProducts[key].setFocus();
                                }
                            }
                        }
                        onWheel={
                            (event) => {
                                event.preventDefault();
                                event.target.blur();
                                event.stopPropagation();
                                setTimeout(() => {
                                    event.target.focus()
                                }, 0);
                            }
                        }
                        onFocus={e => e.target.select()}
                    />
                </td>
                <td style={{minWidth: "120px"}}>
                    <FormControl
                        type="number"
                        size={"sm"}
                        id={`product.${key}.value`}
                        value={this.state.value}
                        onChange={event => {
                            this.validateAndSaveValue(event.target.value);
                        }}
                        placeholder={"0"}
                        disabled={!this.props.enabled}
                        style={{textAlign: 'right'}}
                        ref={refInputValue}
                        onKeyPress={
                            (event) => {
                                if (event.key === "Enter") {
                                    this.props.sentValuesLocal(key,
                                        this.state.option,
                                        this.state.code,
                                        this.state.amount,
                                        this.state.value,
                                        this.state.differential,
                                        this.state.iva,
                                        this.state.name,
                                        this.state.weight
                                    )
                                    this.setState({
                                        saved: true
                                    })
                                }
                            }
                        }
                        onWheel={
                            (event) => {
                                event.preventDefault();
                                event.target.blur();
                                event.stopPropagation();
                                setTimeout(() => {
                                    event.target.focus()
                                }, 0);
                            }
                        }
                        onFocus={e => e.target.select()}
                    />
                </td>
                <td style={{minWidth: "45px", textAlign: 'right'}}>
                    {this.state.iva}
                </td>

                <td style={{minWidth: "44px", textAlign: "right"}}
                    className={"table-"+this.getColorForDifferential()}
                >
                    <Badge bg={this.getColorForDifferential()}>
                        {new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2}).format(
                            this.state.differential
                        )}
                    </Badge>
                </td>
                <td style={{minWidth: "120px"}}>
                    <div style={{textAlign: 'right'}}>
                        ${new Intl.NumberFormat('de-DE').format(
                        this.state.amount * this.state.value
                    )}
                    </div>
                </td>
                <td className={"text-center"}>
                    {this.props.enabled ? (
                        this.isDeletable(key) ? (
                            <Button
                                variant={"outline-danger"}
                                onClick={() => {
                                    this.props.deleteItem(this.props.product, key)
                                }}
                                size={"sm"}
                                disabled={!this.props.enabled}
                            >
                                <i><AiFillDelete/></i>
                            </Button>
                        ) : (
                            <Button
                                variant={"outline-info"}
                                onClick={() => {
                                    this.props.deleteItemEmpty(key)
                                }}
                                size={"sm"}
                            >
                                <i><AiFillDelete/></i>
                            </Button>
                        )
                    ) : null}
                    {this.state.saved ? (
                        <i variant={"outline-success"}><BiCheckDouble/></i>
                    ) : null}
                </td>
            </tr>
        );
    }

    searchProductLocal = (value) => {
        this.setState({
            code: value,
            saved: false,
            ready_load_inventory: false
        });
        let product = this.searchLocal(value);
        if (product) {
            let default_value = this.getDefaultValue(product);
            this.setState({
                name: product.name,
                value: default_value,
                iva: product.iva,
                weight: product.weight,
                differential: 0,
                was_modify: true
            })
            this.loadProductInventory(product.code)
        } else {
            this.setState({
                name: "",
                value: 0,
                iva: 0,
                weight: 0,
                differential: 0,
                was_modify: false
            })
        }
    }

    setProductSelected = (product) => {
        let default_value = this.getDefaultValue(product);
        this.setState({
            code: product.code,
            saved: false,
            name: product.name,
            value: default_value,
            iva: product.iva,
            weight: product.weight,
            differential: 0,
            was_modify: true,
            ready_load_inventory: true,
            load_inventory: product.amount - product.reserved,
        });
        let key = this.props.product.id;
        setTimeout(
            () => this.props.inputCantProducts[key].setFocus(), 300
        );
    }

    searchLocal = (id) => {
        for (const valueKey in this.props.productsStorage) {
            if (this.props.productsStorage[valueKey].id === id) {
                return this.props.productsStorage[valueKey]
            }
        }
        return false
    }

    getDefaultValue = (product) => {
        if (product.sales_list && product.sales_list.length > 0) {
            if (product.sales_list[product.sales_list_default]) {
                if (product.iva && product.iva_mandatory) {
                    return parseInt(product.sales_list[product.sales_list_default].value * (1 + (product.iva / 100)))
                }
                if (product.iva && (this.state.option === true || this.state.option === "true")) {
                    return parseInt(product.sales_list[product.sales_list_default].value * (1 + (product.iva / 100)))
                }
                return product.sales_list[product.sales_list_default].value
            }
            return product.sales_list[0].value
        }
        return 0
    }

    getColorForDifferential = () => {
        if (this.props.product) {
            if (this.state.differential >= -5 && this.state.differential <= 19) {
                return "success"
            }
            if (this.state.differential >= -20 && this.state.differential <= 60) {
                return "warning"
            }
            if (this.state.differential > 60) {
                return "primary"
            }
            if (this.state.differential < -20){
                return "danger"
            }
            return "secondary"
        } else {
            return "secondary"
        }
    }

    getDifferentialProduct = (original_value, new_value) => {
        if (original_value) {
            return (new_value * 100 / original_value) - 100
        }
        return 100
    }

    calculateDifferentialValue = (value) => {
        let product = this.searchLocal(this.state.code);
        if (product) {
            let default_value = this.getDefaultValue(product);
            return this.getDifferentialProduct(default_value, value)
        }
        return 0
    }

    isDeletable = (key) => {
        let order = this.props.order;
        let product_search = false
        for (const id in order.products) {
            if (order.products[id].id === key) {
                product_search = order.products[id]
            }
        }
        if (product_search && product_search.code && product_search.name) {
            return true
        }
        return false
    }

    loadProductInventory = (product_id) => {
        this.setState({
            ready_load_inventory: false,
            load_inventory: 0,
        });
        if (product_id) {
            this.props.loadGet("/products/" + product_id, this.loadSuccess, this.loadError);
        }
    }

    loadSuccess = (product) => {
        this.setState({
            ready_load_inventory: true,
            load_inventory: product.amount - product.reserved,
        })
    }

    loadError = (onerror) => {
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal_error: param
        })
    }

    setModalSearchShow = (modalSearch) => {
        this.setState({modalSearch})
    }

    validateAndSaveValue = (value) => {
        if (value >= 0){
            this.setState({
                value: value,
                differential: this.calculateDifferentialValue(value)
            });
        }
        else {
            this.setState({
                value: 0,
                differential: this.calculateDifferentialValue(0)
            });
        }
    }

    validateAndSaveAmount = (value) => {
        if (value >= 0) {
            this.setState({
                amount: value
            });
        } else {
            this.setState({
                amount: 0
            });
        }
    }
}

export default RowFormProductOrder;