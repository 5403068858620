import React, {Component} from "react";
import {Alert, Button, Card, OverlayTrigger, Placeholder, Spinner, Table, Tooltip} from "react-bootstrap";
import {FiEdit} from "react-icons/fi";
import {AiFillDelete} from "react-icons/ai";
import {GrAdd} from "react-icons/gr";
import {NavLink} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";


class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            users: null,
            modal: null
        }
    }

    componentDidMount() {
        this.loadTableUsers();
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Lista de usuarios
                    <NavLink to={"/admin/new/users"}>
                        <Button className={"float-end"} size="sm" variant={"primary"}>
                            <i><GrAdd/></i> Crear Nuevo
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {
                        this.state.ready ? (
                            <>
                                {
                                    this.state.error ? (
                                        <Alert variant={"danger"}>
                                            <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                            <p>
                                                {this.state.error}
                                            </p>
                                            <hr/>
                                            <Button onClick={() => this.loadTableUsers()} variant={"dark"}>
                                                Intentar nuevamente
                                            </Button>
                                        </Alert>

                                    ) : (
                                        <>
                                            {this.state.users}
                                        </>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={12} size={"lg"} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Text} animation={"glow"}>
                                    <Placeholder xs={12} bg={"success"}/>
                                    <Placeholder xs={12} bg={"success"}/>
                                    <Placeholder xs={12} bg={"success"}/>
                                </Placeholder>
                            </>
                        )
                    }
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>
        );
    }

    loadTableUsers = () => {}

    loadContent = (data) => {
        this.setState({
            ready: true,
            users: this.paintUsers(data)
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    paintUsers = (data) => {
        if (data) {
            return (
                <Table striped hover size="sm" responsive>
                    <thead>
                    <tr className={"text-center"}>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>Email</th>
                        <th>Teléfono</th>
                        <th>Status</th>
                        <th>Rol</th>
                        <th></th>
                    </tr>
                    </thead>
                    {this.paintRowsUsers(data)}
                </Table>
            )
        } else {
            return (
                <p>No hay datos disponibles</p>
            )
        }
    }


    paintRowsUsers = (data) => {
        const rows = data.map((user, key) =>
            {
                return(<tr key={key}>
                    <td>{user.name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.status}</td>
                    <td>{user.role && user.role === "admin"? "Admin": "Cliente"}</td>
                    <td className={"text-center"}>
                        <OverlayTrigger
                            key={key + "tooltip1"}
                            placement={"bottom"}
                            overlay={
                                <Tooltip id={"tooltip-1"}>
                                    Editar
                                </Tooltip>
                            }
                        >

                            <NavLink to={"/admin/edit/users/" + user.id}>
                                <Button size={"sm"}><i>
                                    <FiEdit/>
                                </i></Button>
                            </NavLink>
                        </OverlayTrigger>
                        <OverlayTrigger
                            key={key + "tooltip2"}
                            placement={"bottom"}
                            overlay={
                                <Tooltip id={"tooltip-1"}>
                                    Eliminar
                                </Tooltip>
                            }
                        >
                            <Button variant={"danger"} size={"sm"} onClick={() => this.checkDelete(user)}><i>
                                <AiFillDelete/>
                            </i></Button>
                        </OverlayTrigger>
                    </td>
                </tr>)
            }
        )
        return (
            <tbody>{rows}</tbody>
        )
    }

    checkDelete = (user) => {
        let title_var = "Quiere eliminar el usuario " + (user.name ? user.name : user.email) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeleteAlert(user)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El usuario no se puede recuperar despues de eliminado
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    loadDeleteAlert = (user) => {
        let title_var = "Eliminando el usuario " + (user.name ? user.name : user.email) + "!"
        let user_id = user.id
        this.setAlert(
            <SweetAlert
                custom
                title={title_var}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado el usuario
            </SweetAlert>
        )
        this.props.loadDelete("/users/" + user_id, this.reloadUsers, this.loadError);
    }

    reloadUsers = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Usuario borrado
                </SweetAlert>
            )
            this.loadTableUsers();
        } else {
            this.loadError("Error al borrar el usuario")
        }
    };

    loadError = (onerror) => {
        this.setState({ready: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }
}


export default Users;