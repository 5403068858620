import React, {Component} from "react";
import {
    Alert,
    Badge,
    Button, ButtonGroup,
    Card, Dropdown,
    FormSelect,
    Placeholder, Spinner, Table
} from "react-bootstrap";
import {FiPackage, FiPrinter} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import {NavLink} from "react-router-dom";
import loadDate from "../../util/datesToString";
import formatNumberString from "../../util/formatNumberString";
import capitalizeString from "../../util/capitalizeString";

const ACTIVE = "ACTIVE"
const PROCESS = "PROCESS"
const CANCELLED = "CANCELLED"
const INACTIVE = "INACTIVE"

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            orders: null,
            modal: null,
            loads: null
        }
    }

    componentDidMount() {
        this.loadTableOrders();
        this.loadPeriods();
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Ordenes de Pedido
                    <NavLink to={"/admin/new/orders"}>
                        <Button className={"float-end"} size="sm" variant={"primary"}>
                            <i><GrAdd/></i> Crear Nuevo
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.ready ? (
                        <>
                            {this.state.error ? (
                                <Alert variant={"danger"}>
                                    <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                    <p>
                                        {this.state.error}
                                    </p>
                                    <hr/>
                                    <Button onClick={() => this.loadTableOrders()} variant={"dark"}>
                                        Intentar nuevamente
                                    </Button>
                                </Alert>
                            ) : this.paintOrders(this.state.orders)}
                        </>
                    ) : (
                        <>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
                <Card.Footer/>
            </Card>
        )
    }

    loadTableOrders = () => {
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            orders: data
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    paintOrders = (data) => {
        if (data && data.length > 0) {
            return (
                <Table striped hover size={"sm"} responsive bordered
                       variant={this.loadColorTable()}
                >
                    <thead //className={"lidercamargo-background"
                    >
                    <tr className={"text-center"}>
                        <th>id</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Ciudad</th>
                        <th>Cargue</th>
                        <th>Asesor</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                    </thead>
                    {this.paintRowsOrders(data)}
                </Table>
            )
        } else {
            return (<p>No hay ordenes disponibles</p>)
        }
    }

    paintRowsOrders = (data) => {
        const rows = data.map((order, key) =>
            <tr key={key}>
                <td className={"text-center"}>
                    <NavLink to={"/admin/edit/orders/" + order.id}>
                        <Badge bg={this.loadColor()}>
                            {order.id}
                        </Badge>
                    </NavLink>
                </td>
                <td className={"text-center"}>{loadDate(order.date)}</td>
                <td>
                    {capitalizeString(order.client.name)}
                    {order.from_store ? (
                        <Badge bg={"primary"}> Tienda </Badge>
                    ): null}
                </td>
                <td>{order.client.city} ({order.client.department})</td>
                {(this.loadType() === ACTIVE) ? (
                    <td>
                        {this.state.loads ? (
                            <FormSelect
                                name="load_id"
                                defaultValue={order.load_id}
                                onChange={(event) =>
                                    this.changeLoadOrder(event.target.value, order)
                                }
                                disabled={(!(order.status === "active" || order.status === "process"))}
                            >
                                {this.getLoads()}
                            </FormSelect>
                        ) : (
                            <Spinner animation={"border"}/>
                        )}
                    </td>
                ) : (
                    <td>
                        {order.load}
                    </td>
                )}
                <td>{order.advisory}</td>
                <td style={{textAlign: 'right'}}>${formatNumberString(parseInt(order.total))}</td>
                <td className={"text-center"}>
                    <Dropdown as={ButtonGroup} drop={"down"}>
                        <NavLink to={"/admin/edit/orders/" + order.id}
                                 className={"btn btn-primary btn-sm"}
                        >
                            {this.loadType() === CANCELLED || this.loadType() === INACTIVE ? (
                                <>Ver</>
                            ) : (
                                <>Editar</>
                            )}
                        </NavLink>
                        <Dropdown.Toggle split id={"dropdown-split-orders-" + key} size={"sm"}/>
                        <Dropdown.Menu>
                            {order.load ? (
                                <>
                                    <NavLink to={"/admin/print/orders/" + order.id + "/load"}
                                             className={"dropdown-item"}
                                             onClick={() => this.changeStatusProcess(order)}
                                    >
                                        {order.load && (this.loadType() === ACTIVE || this.loadType() === PROCESS) ? (
                                            <><i><FiPackage/></i> Alistar</>
                                        ) : null}
                                        {order.load && !(this.loadType() === ACTIVE || this.loadType() === PROCESS) ? (
                                            <><i><FiPrinter/></i> Imprimir Cargue</>
                                        ) : null}
                                    </NavLink>
                                    <Dropdown.Divider/>
                                </>
                            ) : null}
                            <NavLink to={"/admin/print/orders/" + order.id + "/order"}
                                     className={"dropdown-item"}
                            >
                                <><i><FiPrinter/></i> Imprimir Pedido</>
                            </NavLink>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        )
        return (
            <tbody>{rows}</tbody>
        )
    }

    loadColor = () => {
        return "info"
    }

    loadColorTable = () => {
        return ""
    }

    loadType = () => {
    }

    loadPeriods = () => {
        if (this.loadType() === ACTIVE || this.loadType() === PROCESS) {
            this.props.loadGet("/loads?status=active", this.loadContentPeriods, this.loadError)
        }
    }

    loadContentPeriods = (data) => {
        if (data) {
            this.setState({
                loads: data
            });
        }
    }

    getLoads = () => {
        let loads = []
        loads.push(<option value=""></option>)
        for (let load in this.state.loads) {
            let option = this.state.loads[load]
            loads.push(<option key={load} value={option.id}>{option.name}</option>)
        }
        return loads;
    }

    changeLoadOrder = (value, order) => {
        let load = ""
        for (let i in this.state.loads) {
            let load1 = this.state.loads[i]
            if (load1.id === value) {
                load = load1.name
            }
        }
        if (value === "") {
            order.status = "active";
        }
        order.load = load;
        order.load_id = value;
        this.setOrder(order);
    }

    changeStatusProcess = (order) => {
        if (order.status === "active") {
            order.status = "process";
            this.setOrder(order);
        }
    }

    setOrder = (order) => {
        this.props.loadPut("/orders/" + order.id, order, this.successSaveOrderExpress, this.loadError)
    }
    successSaveOrderExpress = (order) => {
        if (order.load_id === "" && this.loadType() === PROCESS) {
            window.location.reload();
        } else {
            this.loadTableOrders()
        }
    }
}

export default Orders;