import React, {Component} from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col, FormCheck,
    FormControl,
    FormGroup,
    FormLabel, FormSelect,
    Placeholder,
    Row, Spinner
} from "react-bootstrap";
import {NavLink, Redirect} from "react-router-dom";
import {AiFillDelete, AiFillSave, AiOutlineClose} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";

import {FieldArray, Form, Formik} from 'formik';
import * as Yup from "yup";
import SweetAlert from "react-bootstrap-sweetalert";
import {object} from "yup";
import ModalSearchProvider from "./ModalSearchProvider";
import ImageSource from "./ImageSource";

import imageBack from "../../assets/img/logo512.png"
import ModalViewProductInOrders from "./ModalViewProductInOrders";
import FormEditProduct from "./FormEditProduct";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    code: Yup.number().required('Requedido'),
    weight: Yup.number(),
    cost: Yup.number(),
    iva: Yup.number().min(0, "Minimo 0").max(100, "Maximo 100"),
    iva_mandatory: Yup.boolean(),
    amount: Yup.number().min(0, "Minimo 0"),
    reserved: Yup.number(),
    unity: Yup.string(),
    sales_list: Yup.array().of(
        object({
            value: Yup.number().min(0, "Minimo 0"),
        })
    ),
    sales_list_default: Yup.number(),
    providers: Yup.array(),
    url_image: Yup.string(),
});

class EditProduct extends Component {
    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            product: null,
            modal: null,
            ready_post: true,
            error_post: false,
            redirect: null,
            update_on: null,
            providers: null,
            push_provider: null,
            delete_provider: null,
            providers_list: [],
            in_store: false,
            available: 0,
            image_source: imageBack,
            image_base: null,
            has_image_saved: true,
            url_image: null,
            modal_orders: null,
            orders: null,
            ready_orders: false,
        }
    }

    componentDidMount() {
        this.loadProduct();
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Editar Producto
                    <ButtonGroup className={"float-end"}>
                        <Button size="sm" variant={"secondary"}>
                            <NavLink to={"/admin/products"}>
                                <i><FiSearch/></i> Buscar Productos
                            </NavLink>
                        </Button>
                        <Button size="sm" variant={"primary"}>
                            <NavLink to={"/admin/new/products"}>
                                <i><GrAdd/></i> Crear Nuevo
                            </NavLink>
                        </Button>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.redirect}
                    {
                        this.state.ready ? (
                            <>{
                                this.state.error ? (
                                    <Alert variant={"danger"}>
                                        <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                        <p>
                                            {this.state.error}
                                        </p>
                                        <hr/>
                                        <Button onClick={() => this.loadProduct()} variant={"dark"}>
                                            Intentar nuevamente
                                        </Button>
                                    </Alert>
                                ) : (
                                    <>
                                        <FormEditProduct
                                            {...this.props}
                                            product={this.state.product} saveProduct={this.saveProduct}
                                            loadOrdersAndShowModal={this.loadOrdersAndShowModal}
                                            setInStore={(value)=> this.setState({in_store: value})}
                                            inStore={this.state.in_store}
                                            imageSource={this.state.image_source}
                                            hasImageSaved={this.state.has_image_saved}
                                            setSourceImage={image => this.setState({image_source: image})}
                                            setBaseImage={image_base => this.setState({
                                                image_base,
                                                has_image_saved: false
                                            })}
                                            imageBase={this.state.image_base}
                                            readyPost={this.state.ready_post}
                                            sendImageURL={this.sendImageURL}
                                            available={this.state.available}
                                            openModalProvidersSearch={this.openModalProvidersSearch}
                                            removeFromState={this.removeFromState}
                                        />
                                        <ModalSearchProvider
                                            {...this.props} show={this.state.providers}
                                            onHide={() => this.setModalShow(false)}
                                            providers={this.state.providers_list}
                                            addProvider={this.addProvider}
                                        />
                                        {this.state.product && this.state.product.reserved && this.state.product.reserved > 0 ? (
                                            <ModalViewProductInOrders
                                                {...this.props} show={this.state.modal_orders}
                                                onHide={() => this.setModalShowOrders(false)}
                                                product={this.state.product}
                                                orders={this.state.orders}
                                                ready={this.state.ready_orders}
                                            />
                                        ) : null}
                                    </>
                                )
                            }</>
                        ) : (
                            <>
                                <Placeholder as={Card.Text} animation={"glow"}>
                                    <Placeholder xs={5} bg={"success"}/>
                                    <Placeholder xs={1} bg={"light"}/>
                                    <Placeholder xs={5} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                    <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Text} animation={"glow"}>
                                    <Placeholder xs={5} bg={"success"}/>
                                    <Placeholder xs={1} bg={"light"}/>
                                    <Placeholder xs={5} bg={"success"}/>
                                </Placeholder>
                                <Placeholder as={Card.Title} animation="glow">
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                    <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                    <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                </Placeholder>
                                <Placeholder.Button variant="success" xs={6}/>
                                <Placeholder.Button variant="danger" xs={6}/>
                            </>
                        )
                    }
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">
                        {
                            this.state.ready && !this.state.error ? (
                                <>
                                    Actualizado el {this.state.update_on}
                                </>
                            ) : null
                        }
                    </small>
                </Card.Footer>
            </Card>
        )
    }

    loadProduct = () => {
        this.props.loadGet("/products/" + this.id, this.loadContent, this.loadError)
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            product: data,
            update_on: this.loadDate(data['updated']),
            available: data['available'],
            providers_list: data.providers ? data.providers : [],
            in_store: data.in_store,
        });
        if (data['url_image'] && data.in_store) {
            this.setState({
                url_image: data['url_image']
            });
            this.props.loadGetBucket("/images/" + data['url_image'], this.loadImage, this.loadErrorBucket);
        }
    }

    loadImage = (image) => {
        this.setState({
            image_source: image
        });
    }

    loadErrorBucket = (error) => {
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                Error al cargar la imagen:
                <hr/>
                {error}
            </SweetAlert>
        )
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    addProvider = (provider) => {
        let providers = this.state.providers_list
        providers.push(provider);
        this.state.push_provider(provider);
        this.setState({
            provider_list: providers
        });
    }
    saveProduct = (values, product_id) => {
        values['name'] = values.name.toUpperCase();
        //values['iva_mandatory'] = values.iva_mandatory !== "false"
        if (this.state.url_image) {
            values['url_image'] = this.state.url_image
        }
        this.setState({
            ready_post: false,
        });
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el producto en la base de datos
            </SweetAlert>
        );
        this.props.loadPut("/products/" + product_id, values, this.successSaveProduct, this.loadErrorPost);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    successSaveProduct = (product) => {
        if (product) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    onConfirm={() => this.setAlert(null)}
                    timeout={2000}
                >
                    Producto guardado
                </SweetAlert>
            )
            this.setState({
                ready_post: true,
                product: product,
                available: product['available'],
                update_on: this.loadDate(product['updated'])
            });
            setTimeout(
                this.loadProduct(),
                3000
            );
        } else {
            this.loadErrorPost("Error al actualizar el producto")
        }
    };

    loadErrorPost = (onerror) => {
        this.setState({ready_post: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    checkDelete = (product) => {
        let title_var = "Quiere eliminar el producto " + (product.id) + " " + (product.name) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeleteAlert(product)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El producto no se puede recuperar despues de eliminado
            </SweetAlert>
        )
    }

    loadDeleteAlert = (product) => {
        let title_var = "Eliminando el producto " + (product.id) + " " + (product.name) + "!"
        this.setAlert(
            <SweetAlert
                custom
                title={title_var}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado el producto
            </SweetAlert>
        )
        this.props.loadDelete("/products/" + product.id, this.reloadProducts, this.loadErrorPost);
    }

    reloadProducts = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Producto borrado
                </SweetAlert>
            )
            this.setState({
                redirect: (<Redirect to={"/admin/products"}/>)
            });
        } else {
            this.loadError("Error al borrar el producto")
        }
    }

    setModalShow = (show) => {
        this.setState({providers: show})
    }

    setModalShowOrders(modal_orders) {
        this.setState({
            modal_orders
        })
    }

    loadDate = (datum) => {
        let date = new Date(datum * 1000).toLocaleDateString("es-US")
        let hour = new Date(datum * 1000).toLocaleTimeString("es-US")
        return date + " " + hour;
    }

    openModalProvidersSearch = (pushFunction) => {
        this.setState({
            push_provider: pushFunction
        })
        this.setModalShow(true);
    }

    removeFromState = (provider) => {
        let providers = this.state.providers_list
        for (let i = 0; i < providers.length; i++) {
            if (providers[i].id === provider.id) {
                providers.splice(i, 1)
            }
        }
        this.setState({
            provider_list: providers
        });
    }

    sendImageURL = () => {
        let body_request = {
            image: this.state.image_base
        }
        this.setState({
            ready_post: false,
        });
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos cargando la imagen, por favor espere
            </SweetAlert>
        );
        this.props.loadPost("/products/images", body_request, this.successSavedImage, this.loadErrorPost);
    }

    successSavedImage = (data) => {
        if (data) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    onConfirm={() => this.setAlert(null)}
                    timeout={2000}
                >
                    Imagen Guardada
                </SweetAlert>
            );
            this.setState({
                ready_post: true,
                url_image: data['image'],
                has_image_saved: true,
            });
            setTimeout(() => {
                this.saveProduct(this.state.product, this.state.product.id)
            }, 300)
        } else {
            this.loadErrorPost("Error al guardar la image")
        }
    }

    loadOrdersAndShowModal = () => {
        this.props.loadGet("/orders?status=active,process", this.loadOrdersActiveAndProcess, this.loadErrorPost);
        this.setModalShowOrders(true);
    }

    loadOrdersActiveAndProcess = (orders) => {
        this.setState({
            ready_orders: true,
            orders
        });
    }
}

export default EditProduct;