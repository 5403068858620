import React, {Component} from "react";
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Card,
    Placeholder, ProgressBar,
    Row
} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FiPrinter, FiSearch} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import SweetAlert from "react-bootstrap-sweetalert";
import ProviderInformation from "./ProviderInformation";
import HeaderPurchase from "./HeaderPurchase";
import ProductsInformationPurchase from "./ProductsInformationPurchase";
import CommentsOrder from "../orders/CommentsOrder";
import BackButton from "../../util/backButton";

const status = {
    "inactive": {
        "name": "Cerrada",
        "color": "secondary",
        "next": [],
    },
    "active": {
        "name": "Abierta",
        "color": "success",
        "edit": true,
        "next": [
            "process",
            "cancelled"
        ]
    },
    "process": {
        "name": "Aprobada",
        "color": "warning",
        "next": [
            "active",
            "cancelled"
        ]
    },
    "cancelled": {
        "name": "Cancelada",
        "color": "danger",
        "next": [
            "active",
            "process"
        ]
    },
    "deleted": {
        "name": "Eliminada",
        "color": "dark",
        "hidden": true
    }
}

class EditPurchase extends Component {
    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            modal: null,
            ready_post: true,
            error_post: false,
            redirect: null,
            purchase: null,
            state: null,
            enabled: false,
            saving: null,
            periods: null,
        }
    }

    componentDidMount() {
        this.loadPurchase();
        this.loadPurchasePeriods()
    }

    render() {
        return (
            <Card border={this.getColorStatus("color")}>
                <Card.Header>
                    <small className="text-muted">Número de Orden:</small> <b className={"text-success"}>{this.id} </b>
                    {this.state.state ? (
                        <Badge bg={this.getColorStatus("color")}> {this.getColorStatus("name")}</Badge>) : null}
                    {this.state.error_post ? (<small className="text-muted"> {this.state.error_post}</small>) : null}
                    <ButtonGroup className={"float-end"}>
                        <BackButton size={"sm"}/>
                        {this.state.purchase ? (
                            <NavLink to={"/admin/print/purchases/" + this.id}
                                     className={"btn btn-outline-success btn-sm"}
                                     type={"button"}
                            >
                                <i><FiPrinter/></i> Imprimir Compra
                            </NavLink>
                        ) : null}
                        <NavLink to={"/admin/purchases"} type={"button"} className={"btn btn-outline-secondary btn-sm"}>
                            <i><FiSearch/></i> Buscar Compras
                        </NavLink>
                        <NavLink to={"/admin/new/purchases"} type={"button"} className={"btn btn-outline-primary btn-sm"}>
                            <i><GrAdd/></i> Crear Nuevo
                        </NavLink>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.redirect}
                    {this.state.ready ? (
                        <>
                            {this.state.error ? (
                                <Alert variant={"danger"}>
                                    <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                    <p>
                                        {this.state.error}
                                    </p>
                                    <hr/>
                                    <Button onClick={() => this.loadPurchase()} variant={"dark"}>
                                        Intentar nuevamente
                                    </Button>
                                </Alert>
                            ) : (
                                <>
                                    <Row className={"mb-3"}>
                                        {this.state.saving}
                                    </Row>
                                    <HeaderPurchase purchase={this.state.purchase}
                                                    setPurchase={this.setPurchase}
                                                    status={status}
                                                    periods={this.state.periods}
                                    />
                                    <ProviderInformation {...this.props}
                                                         purchase={this.state.purchase}
                                                         setPurchase={this.setPurchase}
                                                         enabled={this.state.enabled}
                                    />
                                    <ProductsInformationPurchase {...this.props}
                                                                 purchase={this.state.purchase}
                                                                 setPurchase={this.setPurchase}
                                                                 enabled={this.state.enabled}
                                    />
                                    <CommentsOrder {...this.props}
                                                   order={this.state.purchase}
                                                   setOrder={this.setPurchase}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"success"}/>
                            </Placeholder>
                            <Placeholder animation={"glow"}>
                                <Placeholder.Button variant="success" xs={5}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder.Button variant="danger" xs={5}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        )
    }

    loadPurchase = () => {
        this.props.loadGet("/purchases/" + this.id, this.loadContent, this.loadError)
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            purchase: data,
            state: data['status'],
            enabled: this.isEnabledForEdit(data['status'])
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    getColorStatus = (property) => {
        let status_tem = status[this.state.state]
        if (status_tem) {
            return status_tem[property]
        }
        return ""
    }

    setPurchase = (purchase) => {
        this.setState({
            purchase: purchase,
            state: purchase['status'],
            enabled: this.isEnabledForEdit(purchase['status']),
            saving: (<ProgressBar animated now={30}/>)
        });
        this.props.loadPut("/purchases/" + this.id, this.state.purchase, this.successSavePurchaseExpress, this.loadErrorSave)
    }

    isEnabledForEdit = (state) => {
        let status_tem = status[state]
        return status_tem['edit'] ? status_tem['edit'] : false
    }

    successSavePurchaseExpress = (purchase) => {
        this.setState({
            purchase: purchase,
            saving: (<ProgressBar animated now={100} variant={"success"}/>),
        });
        setTimeout(() => {
            this.setState({
                saving: null,
            })
        }, 700);
    }

    loadErrorSave = (onerror) => {
        this.setState({
            error_post: "Error: No se pudo guardar la orden de compra",
            saving: (<ProgressBar animated now={100} variant={"danger"}/>),
            ready_post: true
        });
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    loadPurchasePeriods = () => {
        this.props.loadGet("/purchase_periods?status=active", this.loadContentPeriods, this.loadError)
    }

    loadContentPeriods = (data) => {
        if (data) {
            this.setState({
                periods: data
            });
        }
    }
}

export default EditPurchase;