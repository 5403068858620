import React, {Component} from "react";
import {Accordion, Alert, Button, Col, FormControl, FormFloating, Modal, Placeholder, Row} from "react-bootstrap";
import * as Yup from "yup";

import {Formik, Form} from 'formik';


const schema = Yup.object().shape({
    q: Yup.string()
});

class ModalSearchProvider extends Component{

    constructor(props) {
        super(props);
        this.state = {
            ready: true,
            error: false,
            providers: null,
            query: '',
        }
    }

    render() {
        return(
            <Modal
                {...this.props}
                size={"xl"}
                aria-labelledby="contained-modal-title-vcenter"
                //centered
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            q: ''
                        }}
                        onSubmit={values => {
                            this.searchProviderForOrders(values)
                        }}
                        validationSchema={schema}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormFloating className={"mb-3"}>
                                    <FormControl
                                        type={"text"}
                                        id={"query"}
                                        placeholder={"Buscar Proveedor"}
                                        name={"q"}
                                        onChange={handleChange}
                                        value={values.q}
                                    />
                                    <label htmlFor={"query"}>Buscar Proveedor</label>
                                </FormFloating>
                            </Form>
                        )}
                    </Formik>
                    {this.state.ready ? (
                        <>{this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.searchProviderForOrders(this.state.query)} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <>{this.state.providers}</>
                        )}</>
                    ) : (
                        <>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={11}/>
                                <Placeholder xs={10}/>
                                <Placeholder xs={9}/>
                                <Placeholder xs={10}/>
                            </Placeholder>
                        </>
                    )}
                </Modal.Body>
            </Modal>
            )
    }

    searchProviderForOrders = (values) => {
        this.setState({
            query: values,
        });
        let query = values.q.toUpperCase();
        if (query) {
            this.setState({
                ready: false
            });
            this.props.loadGet("/providers?q=" + query, this.loadContent, this.loadError)
        } else {
            this.setState({
                ready: true,
                providers: null,
                error: false
            })
        }
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            providers: this.paintClients(data)
        })
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    paintClients = (data) => {
        if (data && data.length > 0) {
            return (
                <div>
                    <h5>Se encontraron {data.length} resultados:</h5>
                    {this.paintProvidersRows(data)}
                </div>
            )
        } else {
            return (
                <h5 className={"text-center"}>No se encontraron resultados </h5>
            )
        }
    }

    paintProvidersRows = (data) => {
        const rows = data.map((provider, key) => (
            <Accordion.Item eventKey={key} key={key}>
                <Accordion.Header>
                    <small className="text-muted">[{provider.identification}] </small> {provider.name}
                </Accordion.Header>
                <Accordion.Body>
                    <Row className={"mb-3"}>
                        <Col md={"3"}><small className="text-muted"> Departamento:</small> {provider.department}</Col>
                        <Col md={"3"}><small className="text-muted"> Municipio:</small> {provider.city}</Col>
                        <Col md={"3"}><small className="text-muted"> Telefono:</small> {provider.phone}</Col>
                        <Col md={"3"}><small className="text-muted"> Dirección:</small> {provider.address}</Col>
                        <Col md={"12"}><small
                            className="text-muted"> Establecimiento:</small> {provider.establishment_name}</Col>
                    </Row>
                    <Row>
                        <Button variant={"outline-success"} onClick={() => this.changeProvider(provider)}>
                            Cambiar por {provider.name}</Button>
                    </Row>

                </Accordion.Body>
            </Accordion.Item>
        ));
        return (
            <Accordion>{rows}</Accordion>
        )
    }

    changeProvider = (provider) => {
        let purchase = this.props.purchase;
        purchase.provider = provider;
        this.props.setPurchase(purchase);
        this.props.onHide();
    }
}
export default ModalSearchProvider;