import React, {Component} from "react";
import {
    Button,
    Card, Col, FormControl, FormGroup, FormLabel, FormSelect,
    Row,
    Spinner,
    Table
} from "react-bootstrap";
import {GrAdd} from "react-icons/gr";
import SweetAlert from "react-bootstrap-sweetalert";
import utilizeFocus from "../../util/focusElement";
import {
    clearProducts,
    setProducts
} from "../../initializers/actions";
import {connect} from "react-redux";
import RowFormProductOrder from "./RowFormProductOrder";


class ProductsInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_error: null,
            products: this.props.products,
            order_products: this.props.order.products ? this.props.order.products : []
        }
        this.buttonAddProducts = utilizeFocus()
        this.inputOptionNewProduct = []
        this.inputCodeProducts = []
        this.inputCantProducts = []
        this.inputValuesProducts = []
        for (let key in this.props.order.products) {
            this.inputOptionNewProduct.push(utilizeFocus())
            this.inputCodeProducts.push(utilizeFocus())
            this.inputCantProducts.push(utilizeFocus())
            this.inputValuesProducts.push(utilizeFocus())
        }
    }

    componentDidMount() {
        this.downloadAllProducts();
        this.buttonAddProducts.setFocus();
    }

    render() {
        let products = this.state.order_products ? this.state.order_products : [];
        return (
            <Card>
                <Card.Header>
                    Productos
                </Card.Header>
                <Card.Body>
                    {this.state.modal_error}
                    {products.length > 0 ? (
                        <Table size={"sm"} hover striped responsive>
                            <tr>
                                <th>
                                    OP
                                </th>
                                <th className={"text-center"}>
                                    Código
                                </th>
                                <th>
                                    Descripción
                                </th>
                                <th>
                                    Disp
                                </th>
                                <th className={"text-center"}>
                                    Cant
                                </th>
                                <th className={"text-center"}>
                                    Valor U.
                                </th>
                                <th className={"text-center"}>
                                    IVA
                                </th>
                                <th className={"text-center"}>
                                    Diff
                                </th>
                                <th className={"text-center"}>
                                    Valor T.
                                </th>
                                <th className={"text-center"}>
                                    Acciones
                                </th>
                            </tr>
                            <tbody>
                            {this.state.order_products.map((product) => {
                                    //console.log("Paint", product.id, product.name, product.code)
                                    return (
                                        <RowFormProductOrder {...this.props}
                                                             productsStorage={this.state.products}
                                                             product={product}
                                                             deleteItem={this.deleteItem}
                                                             deleteItemEmpty={this.deleteItemEmpty}
                                                             inputOptionNewProduct={this.inputOptionNewProduct}
                                                             inputCodeProducts={this.inputCodeProducts}
                                                             inputCantProducts={this.inputCantProducts}
                                                             inputValuesProducts={this.inputValuesProducts}
                                                             sentValuesLocal={this.sentValuesLocal}
                                                             key={product.id}
                                        />
                                    )
                                }
                            )}
                            </tbody>
                        </Table>
                    ) : null}
                    {this.props.enabled ? (
                        <Row>
                            <Button variant={"outline-primary"}
                                    onClick={() => {
                                        this.addNewRowProduct()
                                    }}
                                    disabled={!this.props.enabled}
                                    ref={this.buttonAddProducts.ref}
                            ><i><GrAdd/></i> Agregar productos</Button>
                        </Row>
                    ) : null}
                </Card.Body>
                <Card.Footer>
                    {products.length > 0 ? (
                        <Row>
                            <FormGroup as={Col} sm={3} controlId={"validationDate"}>
                                <FormLabel>Subtotal:</FormLabel>
                                <FormControl
                                    type="text"
                                    value={this.getValueInMoney(this.props.order.subtotal)}
                                    readOnly
                                    style={{textAlign: 'right'}}
                                />
                            </FormGroup>
                            <FormGroup as={Col} sm={3} controlId={"validationDate"}>
                                <FormLabel>Descuento:</FormLabel>
                                <FormSelect
                                    type="number"
                                    name={"discount"}
                                    defaultValue={this.props.order.discount ? this.props.order.discount : 0}
                                    style={{textAlign: 'right'}}
                                    onChange={(event) => this.changeDiscount(event)}
                                    disabled={!this.props.enabled}
                                >
                                    {this.getOptionsDiscount()}
                                </FormSelect>
                            </FormGroup>
                            <FormGroup as={Col} sm={3} controlId={"validationDate"}>
                                <FormLabel>Descontado:</FormLabel>
                                <FormControl
                                    type="text"
                                    value={this.getValueInMoney(this.props.order.total_discount)}
                                    readOnly
                                    style={{textAlign: 'right'}}
                                />
                            </FormGroup>
                            <FormGroup as={Col} sm={3} controlId={"validationDate"}>
                                <FormLabel><b>Total:</b></FormLabel>
                                <FormControl
                                    type="text"
                                    value={this.getValueInMoney(this.props.order.total)}
                                    readOnly
                                    style={{textAlign: 'right'}}
                                />
                            </FormGroup>
                        </Row>
                    ) : null}
                </Card.Footer>
            </Card>
        );
    }

    getValueInMoney = (value) => {
        let number = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(value)
        return '$' + number
    }

    loadError = (onerror) => {
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    setAlert = (param) => {
        this.setState({
            modal_error: param
        })
    }

    getOptionsDiscount = () => {
        let options = []
        for (let i = 0; i <= 10; i++) {
            options.push(<option value={i} key={i}>{i} %</option>)
        }
        return options;
    }

    changeDiscount = (event) => {
        let value = event.target.value;
        let order = this.props.order;
        order.discount = value;
        this.props.setOrder(order, "Actualizó descuento a " + value);
    }

    deleteItem = (item, key) => {
        let title_var = "Quiere eliminar el producto " + (item.code) + " " + (item.name) + " de la orden?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeleteAlert(item, key)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El producto no se puede recuperar despues de eliminado
            </SweetAlert>
        )
    }

    loadDeleteAlert = (product, key) => {
        let title_var = "Eliminando el producto " + (product.code) + " " + (product.name) + "!"
        this.setAlert(
            <SweetAlert
                custom
                title={title_var}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado el producto dentro de la orden
            </SweetAlert>
        );
        this.deleteItemFromOrder(key);
    }

    deleteItemFromOrder = (key) => {
        let order = this.props.order;
        let newProducts = []
        let counter = 0
        let product = order.products[key];
        for (let product of order.products) {
            if (product.id !== key) {
                product.id = counter
                newProducts.push(product)
                counter++
            }
        }

        this.setState({
            order_products: newProducts
        });
        order.products = newProducts
        this.props.setOrder(order, "Eliminó producto COD:" + product.code + "CANT:" + product.amount + "VAL:" + product.value + " ID:" + key);

        this.setAlert(
            <SweetAlert
                success
                title="Eliminado!"
                closeOnClickOutside={false}
                timeout={2000}
                onConfirm={() => this.setAlert(null)}
            >
                Producto borrado
            </SweetAlert>
        )
        setTimeout(
            () => {
                window.location.reload();
            }, 600
        )
    }

    deleteItemEmpty = (key) => {
        let products = this.state.order_products;
        products.splice(key, 1)
        this.setState({
            order_products: products
        });
    }

    downloadAllProducts = () => {
        if (!this.props.products || this.props.products.length === 0) {
            this.props.loadGet("/products/", this.loadContent, this.loadError)
        }
    }

    loadContent = (data) => {
        this.setState({
            products: data
        });
        this.props.setProducts(data);
    }

    sentValuesLocal = (key, option, code, amount, value, differential, iva, name, weight) => {
        if (code && amount && value) {
            let product = {
                id: key,
                code: code,
                amount: amount,
                value: value,
                differential: differential,
                iva: iva,
                name: name,
                weight: weight,
                option: !(option === "false" || option === false),
                discount: 0
            }
            let order = this.props.order;
            let isUpdated = true;
            let message_log = ""
            if (order.products) {
                if (order.products[key]) {
                    let product_saved = order.products[key]
                    if (product_saved.id === product.id && product_saved.option === product.option &&
                        product_saved.amount === product.amount && product_saved.value === product.value) {
                        isUpdated = false
                    } else {
                        order.products[key] = product
                        message_log = "Actualizó producto "
                    }
                    if (this.state.order_products[key + 1]) {
                        this.inputOptionNewProduct[key + 1].setFocus();
                    } else {
                        setTimeout(() => this.addNewRowProduct(), 300);
                    }
                } else {
                    message_log = "Agregó nuevo producto "
                    order.products.push(product);
                    setTimeout(() => this.addNewRowProduct(), 300);
                }
            } else {
                order.products = [product]
                message_log = "Agregó primer producto "
                setTimeout(() => this.addNewRowProduct(), 300);
            }
            message_log += "COD:" + code + " CANT:" + amount + " VAL:" + value + " OP:" + option + " ID:" + key
            if (isUpdated) {
                this.props.setOrder(order, message_log);
            }
        }
    }

    addNewRowProduct = () => {
        this.inputOptionNewProduct.push(utilizeFocus());
        this.inputCodeProducts.push(utilizeFocus());
        this.inputCantProducts.push(utilizeFocus());
        this.inputValuesProducts.push(utilizeFocus());
        setTimeout(
            () => {
                this.inputOptionNewProduct[this.inputOptionNewProduct.length - 1].setFocus();
            },
            300
        );
        let order_products = this.state.order_products;
        order_products.push({option: true, code: '', id: order_products.length})
        this.setState({
            order_products
        })

    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products,
    }
}

const mapDispatchToProps = {
    setProducts,
    clearProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsInformation);