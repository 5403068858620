import React, {Component} from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    Col,
    FormControl,
    FormGroup,
    FormLabel, FormSelect,
    Placeholder,
    Row, Spinner
} from "react-bootstrap";
import * as Yup from "yup";
import {NavLink, Redirect} from "react-router-dom";
import {AiFillDelete, AiFillSave} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";

import {Form, Formik} from 'formik';
import cities from "../clients/cities";
import SweetAlert from "react-bootstrap-sweetalert";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    identification: Yup.string().required('Requedido'),
    phone: Yup.string(),
    city: Yup.string(),
    department: Yup.string(),
    address: Yup.string(),
    establishment_name: Yup.string(),
});

class EditProvider extends Component {
    id = this.props.match.params.id;
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            provider: null,
            modal: null,
            ready_post: true,
            error_post: false,
            redirect: null,
            update_on: null
        }
    }

    componentDidMount() {
        this.loadProvider();
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Editar Proveedor
                    <ButtonGroup className={"float-end"}>
                        <Button size="sm" variant={"secondary"}>
                            <NavLink to={"/admin/providers"}>
                                <i><FiSearch/></i> Buscar Proveedores
                            </NavLink>
                        </Button>
                        <Button size="sm" variant={"primary"}>
                            <NavLink to={"/admin/new/providers"}>
                                <i><GrAdd/></i> Crear Nuevo
                            </NavLink>
                        </Button>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.redirect}
                    {this.state.ready ? (
                        <>{this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadProvider()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <>{this.state.provider}</>
                        )}</>
                    ) : (
                        <>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={5} bg={"success"}/>
                                <Placeholder xs={1} bg={"light"}/>
                                <Placeholder xs={5} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder.Button variant="success" xs={6}/>
                            <Placeholder.Button variant="danger" xs={6}/>
                        </>
                    )}
                </Card.Body>
                <Card.Footer/>
            </Card>
        )
    }

    loadProvider = () => {
        this.props.loadGet("/providers/" + this.id, this.loadContent, this.loadError);
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            provider: this.loadForm(data)
        })
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    loadForm = (data) => {
        if (data) {
            return(
                <Formik
                    initialValues={{
                        name: data.name ? data.name.toUpperCase() : '',
                        identification: data.identification ? data.identification.toUpperCase() : '',
                        phone: data.phone ? data.phone : '',
                        city: data.city ? data.city : '',
                        department: data.department ? data.department : '--',
                        address: data.address ? data.address.toUpperCase() : '',
                        establishment_name: data.establishment_name ? data.establishment_name.toUpperCase() : '',
                    }}
                    onSubmit={ values => {
                        this.saveProvider(values, data.id);
                    }}
                    validationSchema={schema}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"6"} controlId="validationIdentification">
                                    <FormLabel>Identificación del proveedor (Nit o CC):</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="identification"
                                        value={values.identification}
                                        onChange={handleChange}
                                        isInvalid={!!errors.identification}
                                        placeholder={"Nit o Número de Identificación"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.identification}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"6"} controlId="validationName">
                                    <FormLabel>Nombre del proveedor (Razon Social):</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                        placeholder={"Nombre"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.name}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"4"} controlId="validationPhone">
                                    <FormLabel>Número telefonico (Celular):</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        isInvalid={!!errors.phone}
                                        placeholder={"Teléfono o Celular"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.phone}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationDepartments">
                                    <FormLabel>Departamento:</FormLabel>
                                    <FormSelect
                                        name="department"
                                        value={values.department}
                                        onChange={handleChange}
                                        isInvalid={!!errors.department}
                                    >
                                        {this.getDepartments()}
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {errors.department}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"4"} controlId="validationCities">
                                    <FormLabel>Ciudad:</FormLabel>
                                    <FormSelect
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        isInvalid={!!errors.city}
                                    >
                                        <option>--</option>
                                        {
                                            cities[values.department].map((city, key) => (
                                                <option key={key}>{city}</option>
                                            ))
                                        }
                                    </FormSelect>
                                    <FormControl.Feedback type="invalid">
                                        {errors.city}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} md={"6"} controlId="validationAddress">
                                    <FormLabel>Dirección:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        isInvalid={!!errors.address}
                                        placeholder={"Dirección"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.address}
                                    </FormControl.Feedback>
                                </FormGroup>
                                <FormGroup as={Col} md={"6"} controlId="validationEstablishmentName">
                                    <FormLabel>Nombre del establecimiento:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="establishment_name"
                                        value={values.establishment_name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.establishment_name}
                                        placeholder={"Establecimiento"}
                                    />
                                    <FormControl.Feedback type="invalid">
                                        {errors.establishment_name}
                                    </FormControl.Feedback>
                                </FormGroup>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Button type={"submit"} variant={"success"} disabled={!this.state.ready}
                                            className={"buttons-with-all-width"}
                                    >
                                        <i>
                                            <AiFillSave/>
                                        </i> Guardar Proveedor
                                    </Button>
                                </Col>
                                <Col md={"6"}>
                                    <Button variant={"danger"} className={"buttons-with-all-width"}
                                            onClick={() => this.checkDelete(data)}
                                    >
                                        <i><AiFillDelete/></i> Eliminar proveedor
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            )
        } else {
            return (
                <p>No hay datos disponibles</p>
            )
        }
    }

    getDepartments = () => {
        let deps = [];
        for (let department in cities) {
            deps.push(<option key={department}>{department}</option>)
        }
        return deps;
    }

    saveProvider = (values, provider_id) => {
        values['name'] = values.name.toUpperCase();
        values['identification'] = values.identification.toUpperCase();
        values['address'] = values.address.toUpperCase();
        values['establishment_name'] = values.establishment_name.toUpperCase();

        this.setState({
            ready_post: false,
        });

        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el proveedor en la base de datos
            </SweetAlert>
        );
        this.props.loadPut("/providers/" + provider_id, values, this.successSaveProvider, this.loadErrorPost);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    successSaveProvider = (provider) => {
        if (provider) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    onConfirm={() => this.setAlert(null)}
                    timeout={2000}
                >
                    Proveedor guardado
                </SweetAlert>
            )
            this.setState({
                ready_post: true,
            })
        } else {
            this.loadErrorPost("Error al actualizar el cliente")
        }
    }

    loadErrorPost = (onerror) => {
        this.setState({ready_post: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    checkDelete = (provider) => {
        let title_var = "Quiere eliminar el proveedor " + (provider.identification) + " - " + (provider.name) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadDeleteAlert(provider)}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="danger"
                confirmBtnText="Eliminar"
            >
                El provider no se puede recuperar despues de eliminado
            </SweetAlert>
        )
    }

    loadDeleteAlert = (provider) => {
        let title_var = "Eliminando el proveedor " + (provider.identification) + " - " + (provider.name) + "!"
        this.setAlert(
            <SweetAlert
                custom
                title={title_var}
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos eliminado el proveedor
            </SweetAlert>
        )
        this.props.loadDelete("/providers/" + provider.id, this.reloadProviders, this.loadErrorPost);
    }

    reloadProviders = (response) => {
        if (response.status === 200) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Eliminado!"
                    closeOnClickOutside={false}
                    timeout={2000}
                    onConfirm={() => this.setAlert(null)}
                >
                    Proveedor borrado
                </SweetAlert>
            )
            this.setState({
                redirect: (<Redirect to={"/admin/providers"}/>)
            });
        } else {
            this.loadError("Error al borrar el proveedor")
        }
    }
}

export default EditProvider;