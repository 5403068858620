import React, {Component} from "react";
import {Badge, Card, Col, Row, Spinner} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import loadDate from "../../util/datesToString";
import formatNumberString from "../../util/formatNumberString";

class PeriodCardDasboard extends Component {

    constructor(props) {
        super(props);
        this.state= {
            number_orders: 0,
            total: 0,
            ready: false,
            error: null
        }
    }

    componentDidMount() {
        this.loadPeriodOrders();
    }

    render() {
        return (
            <Card className={"shadow p-3 mb-3 bg-white rounded"} style={{marginBottom: '0px'}}>
                <Row style={{alignItems: 'center'}}>
                    <Col className={"text-success"}>
                        {this.props.period.name}
                    </Col>
                    <Col>
                        <Row>
                            <small className={"text-muted"}>
                                Fecha de apertura:
                            </small>
                        </Row>
                        <Row>
                            <Col>{loadDate(this.props.period.open_date)}</Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <small className={"text-muted text-center"}>
                                N. Ordenes:
                            </small>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'right'}}>
                                {this.state.ready?formatNumberString(this.state.number_orders):(
                                    <Spinner animation={"border"}/>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <small className={"text-muted text-center"}>
                                {this.props.type==="sales"?"T. Ventas": null }
                                {this.props.type==="loads"?"Peso (Kg)": null }
                                {this.props.type==="purchases"?"T. Compras": null }
                                :
                            </small>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'right'}}>
                                {this.state.ready? (
                                    <>
                                    {this.props.type==="loads"? null: "$"}
                                    {formatNumberString(parseInt(this.state.total))}
                                    </>
                                ): (
                                    <Spinner animation={"border"}/>
                                )}

                            </Col>
                        </Row>
                    </Col>
                    <Col sm={1} className={"text-center"}>
                        <Row>
                            <NavLink to={"/admin/edit/" + this.getRoute() + this.props.period.id}
                                     className={"btn btn-primary btn-sm"}>
                                Ver
                            </NavLink>
                        </Row>
                    </Col>
                </Row>
            </Card>
        )
    }

    getRoute = () => {
        if (this.props.type === "sales") {
            return "sale_periods/"
        }
        if(this.props.type === "loads"){
            return "loads/"
        }
        if(this.props.type === "purchases"){
            return "purchase_periods/"
        }
    }

    loadPeriodOrders = () => {
        if(this.props.type === "sales"){
            let end = this.props.period['close_date'];
            if(end === 0 || end === "0") {
                end = parseInt(Date.now() / 1000);
            }
            this.props.loadGet("/orders?start=" + this.props.period['open_date'] + "&end=" + end, this.loadContentOrders, this.loadError)
        }
        if(this.props.type === "loads"){
            this.props.loadGet("/orders?load=" + this.props.period.id, this.loadContentOrders, this.loadError)
        }
        if(this.props.type === "purchases"){
            this.props.loadGet("/purchases?period=" + this.props.period.id, this.loadContentOrders, this.loadError)
        }
    }

    loadContentOrders = (orders) => {
        let total = 0;
        if(orders.length > 0) {
            for (const order of orders) {
                if(this.props.type==="loads"){
                    total+=order.weight
                } else {
                    total+=order.total
                }

            }
            this.setState({
                number_orders: orders.length,
                total
            })
        }
        this.setState({
            ready: true
        })

    }
}

export default PeriodCardDasboard;