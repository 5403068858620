import React, {Component} from "react";
import {Alert, Button, ButtonGroup, Card, Col, Placeholder, Row, Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FiArchive, FiPrinter} from "react-icons/fi";
import BackButton from "../../util/backButton";

class PrintPurchase extends Component {
    id = this.props.match.params.id;

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            ready: false,
            error: false,
            purchase: null,
            update_on: null,
            create_on: null,
        }
    }

    componentDidMount() {
        this.loadPurchase()
    }

    render() {
        return (
            <>
                <Card>
                    <Row className={"component-for-print text-center"}>
                        <Col>
                            <ButtonGroup>
                                <BackButton/>
                                {this.state.purchase ? (
                                    <>
                                        <NavLink to={"/admin/edit/purchases/" + this.id}
                                                 className={"btn btn-info"}
                                        >
                                            <i><FiArchive/></i> Ver Orden de Compra
                                        </NavLink>
                                        <Button variant={"success"} onClick={() => window.print()}>
                                            <i><FiPrinter/></i> Imprimir
                                        </Button>
                                    </>
                                ) : null}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Card.Header style={{padding: "0.1rem 1rem"}}>
                        <Row>
                            <Col>
                                <b className={"text-info"}>ORDEN DE COMPRA</b>
                            </Col>
                            <Col className={"text-center"}>
                                {this.state.purchase && this.state.purchase.period_id ? (
                                    <small className="text-muted">
                                        Periodo:
                                        <b className={"text-info"}> {this.state.purchase.purchase_period}</b>
                                    </small>
                                ) : null}
                            </Col>
                            <Col>
                                <div style={{position: "absolute", top: "-8px", right: "0px"}}>
                                    <small className="text-muted">ID: </small>
                                    <b className="text-success"
                                       style={{fontSize: "30px"}}
                                    >
                                        {this.id}
                                    </b>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>
                </Card>
                {this.state.ready ? (
                    this.state.error ? (
                        <Alert variant={"danger"}>
                            <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                            <p>
                                {this.state.error}
                            </p>
                            <hr/>
                            <Button onClick={() => this.loadPurchase()} variant={"dark"}>
                                Intentar nuevamente
                            </Button>
                        </Alert>
                    ) : (
                        <div style={{fontSize: "12px"}}>
                            <Card>
                                <Card.Body style={{padding: "0.1rem 1rem"}}>
                                    <Table>
                                        <tr>
                                            <th>Proveedor:</th>
                                            <td>{this.state.purchase.provider.name ? this.state.purchase.provider.name.toUpperCase() : ""}</td>
                                            <th>Ciudad:</th>
                                            <td>{this.state.purchase.provider.department ? this.state.purchase.provider.department.toUpperCase() : ""}/
                                                {this.state.purchase.provider.city ? this.state.purchase.provider.city.toUpperCase() : ""}</td>
                                        </tr>
                                        <tr>
                                            <th>Establecimiento:</th>
                                            <td>{this.state.purchase.provider.establishment_name}</td>
                                            <th>Nit:</th>
                                            <td>{this.state.purchase.provider.identification}</td>
                                        </tr>
                                        <tr>
                                            <th>Dirección:</th>
                                            <td>{this.state.purchase.provider.address}</td>
                                            <th>Teléfono:</th>
                                            <td>{this.state.purchase.provider.phone}</td>
                                        </tr>
                                    </Table>
                                </Card.Body>
                            </Card>
                            {this.state.purchase.products && this.state.purchase.products.length > 0 ? (
                                <Card>
                                    <Card.Body style={{fontSize: "12px", padding: "0rem 0rem"}}>
                                        <Table bordered striped hover className={"table-sm-custom"}>
                                            <thead>
                                            <tr className={"text-center"}>
                                                <th>Código</th>
                                                <th>Descripción</th>
                                                <th>Costo</th>
                                                <th>Iva</th>
                                                <th>Cant</th>
                                                <th>Total</th>
                                                <th>Observaciones</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.purchase.products.map((product) => {
                                                return (<tr>
                                                    <td className={"text-center"}>{product.code}</td>
                                                    <td>{product.name}</td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.value)}
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        {new Intl.NumberFormat('de-DE').format(product.iva)}
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        {new Intl.NumberFormat('de-DE').format(product.amount)}
                                                    </td>
                                                    <td style={{
                                                        textAlign: 'right',
                                                        paddingRight: "0.3rem"
                                                    }}>
                                                        ${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(product.total)}
                                                    </td>
                                                    <td></td>
                                                </tr>)
                                            })}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                    <Card.Footer style={{padding: "0.0rem 1rem"}}>
                                        <Table>
                                            <tr>
                                                <th>
                                                    Pedido por:
                                                </th>
                                                <td style={{minWidth: "100px"}}></td>
                                                <th>
                                                    Recibido por:
                                                </th>
                                                <td style={{minWidth: "100px"}}></td>
                                                <td>TOTAL:</td>
                                                <th style={{minWidth: "80px"}}
                                                    className={"text-secondary"}>${new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0}).format(this.state.purchase.total)}</th>
                                            </tr>
                                        </Table>
                                    </Card.Footer>
                                </Card>
                            ) : null}
                        </div>
                    )
                ) : (
                    <>
                        <Placeholder as={Card.Text} animation={"glow"}>
                            <Placeholder xs={12} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} size={"lg"} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={12} size={"lg"} bg={"success"}/>
                        </Placeholder>
                        <Placeholder as={Card.Text} animation={"glow"}>
                            <Placeholder xs={12} bg={"success"}/>
                            <Placeholder xs={12} bg={"success"}/>
                        </Placeholder>
                    </>
                )}
            </>
        )
    }

    loadPurchase = () => {
        this.props.loadGet("/purchases/" + this.id, this.loadContent, this.loadError)
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            create_on: this.loadDate(data['date']),
            purchase: data,
            state: data['status'],
        });
        setTimeout(() => window.print(), 200);
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }
    loadDate = (datum) => {
        let date = new Date(datum * 1000).toLocaleDateString("es-US")
        let hour = new Date(datum * 1000).toLocaleTimeString("es-US")
        return date + " " + hour;
    }
}

export default PrintPurchase;