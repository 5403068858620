import React from 'react';
import {Document, Page, Text, View} from '@react-pdf/renderer';
import TablePDF from "../../util/TablePDF";
import formatNumberString from "../../util/formatNumberString";

function InventoryPDF({products}) {
    const columns=[
        {title: "Código", field: "code", width: '10%'},
        {title: "Nombre", field: "name", width: '46%'},
        {title: "P. Proveedor", field: "providers", width: '20%'},
        {title: "Invent.", field: "amount", width: '8%', cellStyle: {textAlign: 'right'}},
        {title: "Reserv.", field: "reserved", width: '8%', cellStyle: {textAlign: 'right'}},
        {title: "Conteo", width: '8%', field: ""}
    ]
    const date = new Date();

    const productsPDF = products.map(product => {
        return {
            code: product.code,
            name: product.name,
            providers: product.providers.map(provider => provider.establishment_name).join(', '),
            amount: formatNumberString(product.amount),
            reserved: formatNumberString(product.reserved),
        }
    })
    const itemsPerPage = 50;
    const pages = [];
    for (let i = 0; i < productsPDF.length; i += itemsPerPage) {
        pages.push(productsPDF.slice(i, i + itemsPerPage));
    }

    return (
        <Document title={"Inventario del " + date.toLocaleDateString()} author={"SGP LIDER CAMARGO SAS"}>
            {pages.map((page, index) => {
                return (
                    <Page size={"A4"} wrap style={{paddingTop: 35, paddingBottom: 37, paddingHorizontal: 20}} key={index}>
                        <View>
                            <Text style={{textAlign: 'center', fontSize: 15}}>PRODUCTOS DEL INVENTARIO</Text>
                            <Text style={{fontWeight: 'thin', fontSize: 10, textAlign: 'center'}}>
                                Fecha: {date.toLocaleDateString() + " " + date.toLocaleTimeString('es-US')}
                            </Text>
                        </View>
                        <TablePDF columns={columns} data={page}/>
                        <View style={{position: 'absolute', bottom: 20, left: 30, right: 30}}>
                            <Text style={{textAlign: 'right', fontSize: 10, marginTop: 10}}>
                                Página {index + 1} de {pages.length}
                            </Text>
                        </View>
                    </Page>
                )
            })}
        </Document>
    )
}

export default InventoryPDF;