export default function capitalizeString(string) {
    if(string && string.trim()){
        let stringLoweCase = string.toLowerCase();
        let words = stringLoweCase.split(" ")
        let stringToReturn = ""
        for (let wordsKey in words) {
            if(words[wordsKey].length > 0){
                words[wordsKey] = words[wordsKey][0].toUpperCase() + words[wordsKey].substr(1)
                stringToReturn += words[wordsKey] + " "
            }
        }
        return stringToReturn
    }
}