import EditPeriod from "./EditPeriod";
import {NavLink} from "react-router-dom";
import {Badge, ButtonGroup, Dropdown, Table} from "react-bootstrap";
import loadDate from "../../util/datesToString";
import formatNumberString from "../../util/formatNumberString";
import {FiPrinter} from "react-icons/fi";
import React from "react";
import capitalizeString from "../../util/capitalizeString";
import AllPurchasesFromPeriodPDF from "./AllPurchasesFromPeriodPDF";

const INACTIVE = "inactive"

class EditPurchasePeriod extends EditPeriod {
    url_period = "purchase_periods"
    name_period = "Compras"

    loadPeriodComponent = () => {
        this.loadPeriod();
        this.loadOrders();
    }

    loadOrders = () => {
        this.props.loadGet("/purchases?period=" + this.id, this.loadContentOrders, this.loadError)
    }

    closePeriodPut = (id, values) => {
        this.props.loadPut("/purchase_periods/" + id + "/close", values, this.loadSuccessPut, this.loadErrorPost);
    }

    closeOrdersWithStatusProcess = (id, i) => {
        this.props.loadPut("/purchases/" + id, {status: INACTIVE}, (data) => this.loadSuccessPutOrder(data, i), this.loadErrorPost);
    }

    viewPeriodWithOrders = (data) => {
        if (data && data.length > 0) {
            return (
                <div>
                    {this.paintPeriodHeader()}
                    <h4>Listado de Compras</h4>
                    <Table striped hover size={"sm"} responsive bordered>
                        <thead>
                        <tr className={"text-center"}>
                            <th>id</th>
                            <th>Fecha</th>
                            <th>Cliente</th>
                            <th>Ciudad</th>
                            <th>Estado</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                        </thead>
                        {this.paintRowsOrders(data)}
                    </Table>
                </div>
            )
        } else {
            return (
                <h3>No hay compras para este periodo</h3>
            )
        }
    }

    viewPeriodWithOrdersPDF = (orders) => {
        return (
            <AllPurchasesFromPeriodPDF orders={orders} name={this.state.name} id={this.id}/>
        )
    }

    paintRowsOrders = (data) => {
        const rows = data.map((purchase, key) =>
            <tr key={key}
            >
                <td className={"text-center"}>
                    <NavLink to={"/admin/edit/purchases/" + purchase.id}>
                        <Badge bg={this.loadColor(purchase)}>
                            {purchase.id}
                        </Badge>
                    </NavLink>
                </td>
                <td className={"text-center"}>{loadDate(purchase.date)}</td>
                <td>{capitalizeString(purchase.provider && purchase.provider.name ? purchase.provider.name : "Sin Proveedor")}</td>
                <td>{purchase.provider.city} ({purchase.provider.department})</td>
                <td className={"text-center"}>
                    <Badge bg={this.loadColor(purchase)}>
                        {purchase.status}
                    </Badge>
                </td>
                <td style={{textAlign: 'right'}}>${formatNumberString(parseInt(purchase.total))}</td>

                <td className={"text-center"}>
                    <Dropdown as={ButtonGroup} drop={"down"}>
                        <NavLink to={"/admin/edit/purchases/" + purchase.id}
                                 className={"btn btn-primary btn-sm"}
                        >
                            <>Editar</>
                        </NavLink>
                        <Dropdown.Toggle split id={"dropdown-split-orders-" + key} size={"sm"}/>
                        <Dropdown.Menu>
                            <NavLink to={"/admin/print/purchases/" + purchase.id}
                                     className={"dropdown-item"}
                            >
                                <><i><FiPrinter/></i> Imprimir Compra</>
                            </NavLink>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        )
        return (
            <tbody>{rows}</tbody>
        )
    }
}

export default EditPurchasePeriod;