import EditPeriod from "./EditPeriod";

const INACTIVE = "inactive"

class EditLoadPeriod extends EditPeriod {
    url_period = "loads"
    name_period = "Carga"

    loadPeriodComponent = () => {
        this.loadPeriod();
        this.loadOrders();
    }

    loadOrders = () => {
        this.props.loadGet("/orders?load=" + this.id, this.loadContentOrders, this.loadError)
    }

    closePeriodPut = (id, values) => {
        this.props.loadPut("/loads/" + id + "/close", values, this.loadSuccessPut, this.loadErrorPost);
    }

    closeOrdersWithStatusProcess = (id, i) => {
        this.props.loadPut("/orders/" + id, {status: INACTIVE},
            (data) => {
                this.loadSuccessPutOrder(data, i);
            },
            this.loadErrorPost
        );
    }
}

export default EditLoadPeriod;