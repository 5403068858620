import Periods from "./Periods";

class SalesPeriods extends Periods {
    classPeriod = "sale_periods";
    description = "En los peridos de venta, se pueden ver las ordenes creadas dentro de un determinado tiempo o periodo. " +
        "Una orden de pedido puede estar presente en varios periodos de venta."
    loadTablePeriods = () => {
        this.props.loadGet("/sale_periods", this.loadContent, this.loadError)
    }

    postPeriod = (values) => {
        this.props.loadPost("/sale_periods", values, this.loadSuccessPost, this.loadErrorPost);
    }

    putPeriod = (id, values) => {
        this.props.loadPut("/sale_periods/" + id, values, this.loadSuccessPut, this.loadErrorPost);
    }

    closePeriodPut = (id, values) => {
        this.props.loadPut("/sale_periods/" + id + "/close", values, this.loadSuccessPut, this.loadErrorPost);
    }
    deletePeriod = (id) => {
        this.props.loadDelete("/sale_periods/" + id, this.loadSuccessDelete, this.loadErrorPost);
    }
}

export default SalesPeriods;