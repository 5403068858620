import React, {Component} from "react";
import {Button, Col, FormControl, FormGroup, FormLabel, Modal, Row} from "react-bootstrap";

import * as Yup from "yup";
import {Formik, Form} from 'formik';

const schema = Yup.object().shape({
    name: Yup.string().required("Requerido")
})

class ModalEditPeriod extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size={""}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton/>
                <Formik
                    initialValues={{
                        name: this.props.PeriodEdit? this.props.PeriodEdit.name: ""
                    }}
                    onSubmit={values => {
                        this.loadEditPeriod(values);
                    }}
                    validationSchema={schema}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Row>
                                    <FormGroup as={Col} controlId={"validationName"}>
                                        <FormLabel>Nombre del periodo:</FormLabel>
                                        <FormControl
                                            type={"text"}
                                            name={"name"}
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                            placeholder={"Nombre del periodo"}
                                        />
                                        <FormControl.Feedback type={"invalid"}>
                                            {errors.name}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant={"success"} type={"submit"}>Modificar</Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        )
    }

    loadEditPeriod = (values) => {
        values['name'] = values.name.toUpperCase();
        this.props.loadEditPeriod(values);
        this.props.onHide();
    }
}

export default ModalEditPeriod;