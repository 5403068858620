import React, {Component} from "react";
import OrdersDashboard from "./OrdersDashboard";
import SalesDashboard from "./SalesDashboard";


class Dashboard extends Component {
    render() {
        return(
            <>
                <SalesDashboard {...this.props}/>
                <OrdersDashboard {...this.props}/>
            </>
        )
    }
}
export default Dashboard;