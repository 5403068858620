import React, {Component} from "react";
import {Button, Card, Container, Spinner} from "react-bootstrap";
import {clearProducts, clearRefreshToken, clearToken, clearUser} from "../initializers/actions";
import {connect} from "react-redux";
import properties from "../properties";
import axios from "axios";


class Logout extends Component {

    componentDidMount() {
        this.revokeToken()
        this.props.clearToken()
        this.props.clearRefreshToken()
        this.props.clearUser()
        this.props.clearProducts()
        window.location.href = properties.url_login;
    }

    render() {
        return(
            <Container>
                <Card className={"text-center"}>
                    <Card.Header>
                        Cargando...
                    </Card.Header>
                    <Card.Body>
                        <Spinner animation={"grow"}/>
                    </Card.Body>
                    <Button href={properties.url_login}
                            variant={"success"}
                    >
                        Iniciar Sesión
                    </Button>
                    <Card.Footer></Card.Footer>
                </Card>
            </Container>
        )
    }

    revokeToken = () => {
        axios({
            url: properties.url_revoke,
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': properties.basic,
            },
            params: {
                token: this.props.refresh
            },
            accept: 'application/json',
            data: ''
        }).then(
            (response) => {
                if (!response.data) {
                    this.setState({
                        isError: true,
                        error: JSON.stringify(response)
                    })
                }
            }
        ).catch((error) => {
            if (error.response) {
                this.setState({
                    isError: true,
                    error: JSON.stringify(error.response.data)
                });
            } else {
                this.setState({
                    isError: true,
                    error: "Error no Identificado"
                })
            }

        })
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        user: state.user,
        refresh: state.refresh,
        products: state.products
    }
}

const mapDispatchToProps = {
    clearToken,
    clearRefreshToken,
    clearUser,
    clearProducts
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);