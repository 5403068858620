import Migration from "./Migration";

const PROCESSING = "PROCESSING"

class MigrationProviders extends Migration{
    headersBefore = ["id", "nit", "nombre", "sobrenombre", "ciudad", "direccion", "dias", "telefonos"]
    headerAfter = ["name", "identification", "phone", "city", "department", "address", "establishment_name"]
    url = "/providers"
    enable = false
    name = "Proveedores"

    processArrayData = () => {
        let arrayData = this.state.array;
        let localData = [];
        arrayData.map(row => {
            if (row.id) {
                const object = {
                    "name": row.nombre.trim(),
                    "identification": row.nit.trim(),
                    "phone": row.telefonos.trim(),
                    "city": this.searchCityLocalInformation(row.ciudad),
                    "department": this.searchDepartmentLocalInformationWithCiti(row.ciudad),
                    "address": row.direccion.trim(),
                    "establishment_name": row.sobrenombre.trim(),
                }
                localData.push(object);
            }
        });
        this.setState({data: localData, status: PROCESSING});
    }
}
export default MigrationProviders