import Orders from "./Orders";

const ACTIVE = "ACTIVE"

class ActiveOrders extends Orders {
    loadTableOrders = () => {
        this.props.loadGet("/orders?status=active", this.loadContent, this.loadError)
    }

    loadType = () => {
        return ACTIVE
    }
}
export default ActiveOrders;