import EditPeriod from "./EditPeriod";

class EditSalePeriod extends EditPeriod{
    url_period = "sale_periods"
    name_period = "Ventas"

    loadPeriodComponent = () => {
        this.loadPeriod();
    }

    loadOrders = () => {
        let end = this.state.end;
        if(end === 0 || end === "0") {
            end = parseInt(Date.now() / 1000);
        }
        this.props.loadGet("/orders?start=" + this.state.start + "&end=" + end, this.loadContentOrders, this.loadError)
    }

    loadOtherComponentAfterPeriod = () => {
        this.loadOrders();
        this.loadPeriods();
    }

    loadPeriods = () => {
        this.props.loadGet("/loads?status=active", this.loadContentPeriods, this.loadError)
    }

    closePeriodPut = (id, values) => {
        this.props.loadPut("/sale_periods/" + id + "/close", values, this.loadSuccessPut, this.loadErrorPost);
    }
}
export default EditSalePeriod;