import React, {Component} from "react";
import {Badge, Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {AiFillDelete} from "react-icons/ai";
import {FiEdit, FiList} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import {IoFileTrayOutline} from "react-icons/io5";

class TablePeriods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        }
    }

    render() {
        let isData = false;
        this.props.periods.map((period) => {
            if(period.status === this.props.type) {
                isData = true;
            }
            return null
        });
        if(isData) {
            return (
                <div>
                    <Table striped hover size={"sm"} responsive
                           variant={this.props.type==="active"?"":"danger"} bordered
                    >
                        <thead>
                        <tr className={"text-center"}>
                            <th>id</th>
                            <th>Nombre</th>
                            <th>Fecha Apertura</th>
                            {this.props.type === "closed" ? (
                                <th>Fecha Cierre</th>
                            ) : null}
                            <th></th>
                        </tr>
                        </thead>
                        {this.paintRowsPeriods(this.props.periods)}
                    </Table>
                </div>
            )
        }
        return (
            <p>No existen periodos con este estado</p>
        )

    }

    paintRowsPeriods = (data) => {
        let classPeriod = this.props.classPeriod
        const rows = data.map((period, key) =>
            <>
                {period.status === this.props.type ? (
                    <tr key={key}>
                        <td className={"text-center"}>
                            <NavLink
                                to={"/admin/edit/"+classPeriod+"/" + period.id}
                            >
                                <Badge bg={this.loadColor(period)}>
                                    {period.id}
                                </Badge>
                            </NavLink>
                        </td>
                        <td>
                            <NavLink
                                to={"/admin/edit/"+classPeriod+"/" + period.id}
                                className={"text-dark"}
                            >
                                {period.name}
                            </NavLink>
                        </td>
                        <td className={"text-center"}>{this.loadDate(period.open_date)}</td>
                        {this.props.type === "closed" ? (
                            <td className={"text-center"}>{this.loadDate(period.close_date)}</td>
                        ) : null}
                        <td className={"text-center"}>
                            <OverlayTrigger
                                key={key + "tooltip1"}
                                placement={"left"}
                                overlay={
                                    <Tooltip id={"tooltip-1"}>
                                        Editar Nombre
                                    </Tooltip>
                                }
                            >
                                <Button size={"sm"} variant={"default"}
                                        onClick={() => this.editPeriodModalOpen(period)}>
                                    <i><FiEdit/></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                key={key + "tooltip1"}
                                placement={"left"}
                                overlay={
                                    <Tooltip id={"tooltip-1"}>
                                        Ver
                                    </Tooltip>
                                }
                            >
                                <NavLink
                                    to={"/admin/edit/"+classPeriod+"/" + period.id}
                                    className={"btn btn-default btn-sm"}
                                >
                                    <FiList/>
                                </NavLink>
                            </OverlayTrigger>
                            {this.props.type === "active" ? null : (
                                <OverlayTrigger
                                    key={key + "tooltip1"}
                                    placement={"left"}
                                    overlay={
                                        <Tooltip id={"tooltip-1"}>
                                            Reabrir Periodo
                                        </Tooltip>
                                    }
                                >
                                    <Button size={"sm"} variant={"default"} onClick={() => this.openPeriod(period)}>
                                        <i><IoFileTrayOutline/></i>
                                    </Button>
                                </OverlayTrigger>
                            )}
                            {this.props.type === "active"?(
                                <OverlayTrigger
                                    key={key + "tooltip2"}
                                    placement={"left"}
                                    overlay={
                                        <Tooltip id={"tooltip-1"}>
                                            Eliminar
                                        </Tooltip>
                                    }
                                >
                                    <Button variant={"danger"} size={"sm"} onClick={() => this.checkDelete(period)}><i>
                                        <AiFillDelete/>
                                    </i></Button>
                                </OverlayTrigger>
                            ): null}
                        </td>
                    </tr>
                ) : null}
            </>
        )
        return (
            <tbody>{rows}</tbody>
        )
    }

    editPeriodModalOpen = (period) => {
        this.props.editPeriodModalOpen(period)
    }
    closePeriod = (period) => {
        this.props.closePeriod(period)
    }
    openPeriod = (period) => {
        this.props.openPeriod(period)
    }
    checkDelete = (period) => {
        this.props.checkDelete(period)
    }

    loadDate = (datum) => {
        if (datum === 0) {
            return "--"
        }
        return new Date(datum * 1000).toLocaleDateString("es-US");
    }
    loadColor = (period) => {
        if (period.status === "active") {
            return "success"
        }
        if (period.status === "closed") {
            return "danger"
        }
        return "secondary"
    }
}

export default TablePeriods;