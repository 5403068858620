import React, {Component} from "react";
import {
    Button,
    Card,
    Col,
    FormCheck,
    FormControl,
    FormGroup,
    FormLabel,
    FormSelect,
    Row, Spinner
} from "react-bootstrap";
import * as Yup from 'yup';
import {AiFillSave, AiOutlineClose} from "react-icons/ai";
import {FiSearch} from "react-icons/fi";
import {GrAdd} from "react-icons/gr";
import {NavLink, Redirect} from "react-router-dom";

import {Formik, Form, FieldArray} from 'formik';
import SweetAlert from "react-bootstrap-sweetalert";
import {object} from "yup";
import ModalSearchProvider from "./ModalSearchProvider";

const schema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
    code: Yup.number().required('Requedido'),
    weight: Yup.number(),
    cost: Yup.number(),
    iva: Yup.number().min(0, "Minimo 0").max(100, "Maximo 100"),
    iva_mandatory: Yup.bool(),
    amount: Yup.number().min(0, "Minimo 0"),
    unity: Yup.string(),
    sales_list: Yup.array().of(
        object({
            value: Yup.number().min(0, "Minimo 0"),
        })
    ),
    sales_list_default: Yup.number(),
    providers: Yup.array(),
});

class NewProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            modal: null,
            error: false,
            ready: true,
            providers: null,
            push_provider: null,
            delete_provider: null,
            providers_list: []
        }
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Producto Nuevo
                    <NavLink to={"/admin/products"}>
                        <Button className={"float-end"} size="sm" variant={"secondary"}>
                            <i><FiSearch/></i> Buscar Productos
                        </Button>
                    </NavLink>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.content}
                    <ModalSearchProvider
                        {...this.props} show={this.state.providers}
                        onHide={() => this.setModalShow(false)}
                        providers = {this.state.providers_list}
                        addProvider = {this.addProvider}
                    />
                    <Formik
                        initialValues={{
                            name: '',
                            code: '',
                            weight: 0,
                            cost: 0,
                            iva: 0,
                            iva_mandatory: true,
                            amount: 0,
                            unity: '',
                            sales_list: [],
                            providers: [],
                            sales_list_default: 0,
                        }}
                        validationSchema={schema}
                        onSubmit={values => {
                            this.saveProduct(values);
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                              errors
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"6"} controlId="validationName">
                                        <FormLabel>Nombre del producto:</FormLabel>
                                        <FormControl
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                            placeholder={"Nombre"}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.name}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"6"} controlId="validationCode">
                                        <FormLabel>Código:</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="code"
                                            value={values.code}
                                            onChange={handleChange}
                                            isInvalid={!!errors.code}
                                            placeholder={"Código"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.code}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"4"} controlId="validationWeight">
                                        <FormLabel>Peso (kg):</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="weight"
                                            value={values.weight}
                                            onChange={handleChange}
                                            isInvalid={!!errors.weight}
                                            placeholder={"0.0"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.weight}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"4"} controlId="validationCost">
                                        <FormLabel>Costo de compra:</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="cost"
                                            value={values.cost}
                                            onChange={handleChange}
                                            isInvalid={!!errors.cost}
                                            placeholder={"1900"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.cost}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"2"} controlId="validationIva">
                                        <FormLabel>Iva:</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="iva"
                                            value={values.iva}
                                            onChange={handleChange}
                                            isInvalid={!!errors.iva}
                                            placeholder={"19"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.iva}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"2"} controlId="validationIvaMandatory">
                                        <FormLabel>Iva Obligatorio:</FormLabel>
                                        <FormSelect
                                            name="iva_mandatory"
                                            value={values.iva_mandatory}
                                            onChange={handleChange}
                                            isInvalid={!!errors.iva_mandatory}
                                            defaultValue={true}
                                        >
                                            <option value={true}>Obligatorio</option>
                                            <option value={false}>No Obligatorio</option>
                                        </FormSelect>
                                        <FormControl.Feedback type="invalid">
                                            {errors.iva_mandatory}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormGroup as={Col} md={"4"} controlId="validationAmount">
                                        <FormLabel>Cantidad disponible:</FormLabel>
                                        <FormControl
                                            type="number"
                                            name="amount"
                                            value={values.amount}
                                            onChange={handleChange}
                                            isInvalid={!!errors.amount}
                                            placeholder={"0"}
                                            style={{textAlign: 'right'}}
                                        />
                                        <FormControl.Feedback type="invalid">
                                            {errors.amount}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                    <FormGroup as={Col} md={"4"} controlId="validationUnity">
                                        <FormLabel>Unidad de medida:</FormLabel>
                                        <FormSelect
                                            name="unity"
                                            value={values.unity}
                                            onChange={handleChange}
                                            isInvalid={!!errors.unity}
                                            defaultValue={"Unidades"}
                                        >
                                            <option>Unidades</option>
                                            <option>Kilogramos</option>
                                            <option>Libra</option>
                                            <option>Arroba</option>
                                            <option>Bulto</option>
                                            <option>Cajas</option>
                                            <option>Bloques</option>
                                            <option>Rollos</option>
                                        </FormSelect>
                                        <FormControl.Feedback type="invalid">
                                            {errors.unity}
                                        </FormControl.Feedback>
                                    </FormGroup>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormLabel><b>Lista de ventas:</b></FormLabel>
                                    <FieldArray name={"sales_list"}>
                                        {({insert, remove, push}) => (
                                            <div>
                                                {values.sales_list.length > 0 && values.sales_list.map(
                                                    (sale_item, key) => (
                                                        <Row className={"align-items-center border-top"} key={key}>
                                                            <FormLabel column sm={2}>Valor de
                                                                Venta {key + 1}:</FormLabel>
                                                            <Col sm={3} className={"my-1"}>
                                                                <FormControl
                                                                    type={"number"}
                                                                    value={values.sales_list[key].value}
                                                                    placeholder={"0"}
                                                                    name={`sales_list.${key}.value`}
                                                                    onChange={handleChange}
                                                                    style={{textAlign: 'right'}}
                                                                />
                                                            </Col>
                                                            <Col sm={4}>
                                                                <FormCheck
                                                                    type={"radio"}
                                                                    label={"Marcar Valor de venta por defecto"}
                                                                    value={key}
                                                                    onChange={handleChange}
                                                                    name={`sales_list_default`}
                                                                    id={`sales-list-${key}`}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    variant={"outline-danger"}
                                                                    onClick={() => remove(key)}
                                                                >
                                                                    <i><AiOutlineClose/></i> Quitar valor
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    )
                                                )}
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            onClick={() => push({value: ''})}
                                                            className={"buttons-with-all-width"}
                                                        >
                                                            <i><GrAdd/></i> Agregar valor de venta
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </FieldArray>
                                </Row>
                                <Row className={"mb-3"}>
                                    <FormLabel><b>Proveedores:</b></FormLabel>
                                    <FieldArray name={"providers"}>
                                        {({insert, remove, push}) => (
                                            <div>
                                                {values.providers.length > 0 && values.providers.map(
                                                    (provider, key) => (
                                                        <Row className={"align-items-center border-top"} key={key}>
                                                            <Col sm={2} className={"my-1"}>
                                                                <FormControl
                                                                    type={"text"}
                                                                    value={values.providers[key].identification}
                                                                    name={`providers.${key}.identification`}
                                                                    plaintext={true}
                                                                />
                                                            </Col>
                                                            <Col sm={3} className={"my-1"}>
                                                                <FormControl
                                                                    type={"text"}
                                                                    value={values.providers[key].name}
                                                                    name={`providers.${key}.name`}
                                                                    plaintext={true}
                                                                />
                                                            </Col>
                                                            <Col sm={4} className={"my-1"}>
                                                                <FormControl
                                                                    type={"text"}
                                                                    value={values.providers[key].establishment_name}
                                                                    name={`providers.${key}.establishment_name`}
                                                                    plaintext={true}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    variant={"outline-danger"}
                                                                    onClick={() => {
                                                                        remove(key);
                                                                        this.removeFromState(provider)
                                                                    }}
                                                                >
                                                                    <i><AiOutlineClose/></i>Quitar Proveedor
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    )
                                                )}
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            onClick={() => this.openModalProvidersSearch(push)}
                                                            className={"buttons-with-all-width"}
                                                        >
                                                            <i><GrAdd/></i> Agregar Proveedor
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </FieldArray>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button type={"submit"} variant={"success"} disabled={!this.state.ready}
                                                className={"buttons-with-all-width"}
                                        >
                                            <i>
                                                <AiFillSave/>
                                            </i> Guardar Producto
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
                <Card.Footer>

                </Card.Footer>
            </Card>
        )
    }

    redirectToEdit = (product) => {
        if (product) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    showConfirm={false}
                    closeOnClickOutside={false}
                >
                    Producto guardado
                </SweetAlert>
            )
            this.setState({
                content: (<Redirect to={"/admin/edit/products/" + product.id}/>)
            });
        } else {
            this.loadError("Error al crear el usuario")
        }
    };

    loadError = (onerror) => {
        this.setState({ready: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }

    saveProduct = (values) => {
        values['name'] = values.name.toUpperCase();
        values['iva_mandatory'] = values.iva_mandatory !== "false"
        this.setState({
            ready: false,
        });

        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el producto en la base de datos
            </SweetAlert>
        );
        this.props.loadPost("/products", values, this.redirectToEdit, this.loadError);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    addProvider = (provider) => {
        let providers = this.state.providers_list
        providers.push(provider);
        this.state.push_provider(provider);
        this.setState({
            provider_list: providers
        });
    }

    setModalShow = (show) => {
        this.setState({providers: show})
    }

    openModalProvidersSearch = (pushFunction)  => {
        this.setState({
            push_provider: pushFunction
        })
        this.setModalShow(true);
    }

    removeFromState = (provider)  => {
        let providers = this.state.providers_list
        for(let i=0; i< providers.length; i++){
            if(providers[i].id === provider.id){
                providers.splice(i, 1)
            }
        }
        this.setState({
            provider_list: providers
        });
    }
}

export default NewProduct;