import React, {Component} from "react";
import {Button, Card, Col, FormControl, FormGroup, FormLabel, Row, Spinner} from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import {NavLink, Redirect} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {AiFillSave} from "react-icons/ai";
import SweetAlert from "react-bootstrap-sweetalert";

const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"), description: Yup.string(),
})

class NewCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: null, error: false, ready: true, content: null
        }
    }

    render() {
        return (<Card>
            <Card.Header>
                Nueva Categoría
                <NavLink to={"/admin/categories"}>
                    <Button className={"float-end"} size="sm" variant={"secondary"}>
                        <i><FiSearch/></i> Categorias de Producto
                    </Button>
                </NavLink>
            </Card.Header>
            <Card.Body>
                {this.state.modal}
                {this.state.content}
                <Formik
                    initialValues={{
                        name: '', description: '',
                    }}
                    onSubmit={values => {
                        this.saveNewCategory(values)
                    }}
                    validationSchema={schema}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          errors
                      }) => (
                        <Form onSubmit={handleSubmit}>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} controlId="validationName">
                                    <FormLabel>Nombre de la categoría:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                        placeholder={"Nombre de la categoría"}
                                    />
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <FormGroup as={Col} controlId="validationDescription">
                                    <FormLabel>Descripción:</FormLabel>
                                    <FormControl
                                        type="text"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={!!errors.description}
                                        placeholder={"Descripción"}
                                    />
                                </FormGroup>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col>
                                    <Button type={"submit"} variant={"success"} disabled={!this.state.ready}
                                            className={"buttons-with-all-width"}
                                    >
                                        <i>
                                            <AiFillSave/>
                                        </i> Guardar Nueva Categoría
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>)
    }

    saveNewCategory = (values) => {
        values['type'] = "product";
        this.setState({
            ready: false
        });
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando la categoría en la base de datos
            </SweetAlert>
        );
        this.props.loadPost("/categories", values, this.redirectToEdit, this.loadError);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    redirectToEdit = (category) => {
        if (category) {
            this.setAlert(
                <SweetAlert
                    success
                    title="Guardado!"
                    showConfirm={false}
                    closeOnClickOutside={false}
                >
                    Categoria creada
                </SweetAlert>
            );
            this.setState({
                content: (<Redirect to={`/admin/edit/category/${category.id}`}/>)
            });
        } else {
            this.loadError("Error al crear la categoria!");
        }
    }

    loadError = (onerror) => {
        this.setState({ready: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }
}

export default NewCategory;
