import Orders from "./Orders";

const CANCELLED = "CANCELLED"
class CancelledOrders extends Orders {
    loadTableOrders = () => {
        this.props.loadGet("/orders?status=cancelled", this.loadContent, this.loadError)
    }

    loadColor = () => {
        return "danger"
    }

    loadColorTable = () => {
        return this.loadColor()
    }

    loadType = () => {
        return CANCELLED
    }
}
export default CancelledOrders;