import Migration from "./Migration";

const PROCESSING = "PROCESSING"

class MigrationClients extends Migration {
    headersBefore = ["id", "nit", "nombre", "departamento", "ciudad", "direccion", "telefonos", "clase", "lista", "cupo",
        "dias", "contacto", "establecimiento", "ruta"]
    headerAfter = ["name", "identification", "phone", "city", "department", "address", "quota", "contact_name",
        "establishment_name"]
    url = "/clients"
    enable = false
    name = "Clientes"

    processArrayData = () => {
        let arrayData = this.state.array;
        let localData = [];
        arrayData.map(row => {
            if (row.id) {
                const object = {
                    "name": row.nombre.trim(),
                    "identification": row.nit.trim(),
                    "phone": row.telefonos.trim(),
                    "city": this.searchCityLocalInformation(row.ciudad),
                    "department": this.searchDepartmentLocalInformation(row.departamento),
                    "address": row.direccion.trim(),
                    "quota": row.cupo.trim(),
                    "contact_name": row.contacto.trim(),
                    "establishment_name": row.establecimiento.trim(),
                }
                localData.push(object);
            }
        });
        this.setState({data: localData, status: PROCESSING});
    }
}

export default MigrationClients