import React, {Component} from "react";
import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Card,
    Col, Dropdown, FormCheck, FormControl, FormGroup, FormLabel,
    FormSelect,
    Placeholder,
    Row,
    Spinner,
    Table
} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {FiCalendar, FiPackage, FiPrinter, FiX} from "react-icons/fi";
import formatNumberString from "../../util/formatNumberString";
import loadDate from "../../util/datesToString";
import SweetAlert from "react-bootstrap-sweetalert";
import capitalizeString from "../../util/capitalizeString";
import {BsTable} from "react-icons/bs";
import {AiOutlineFilePdf} from "react-icons/ai";
import {PDFViewer} from "@react-pdf/renderer";

const status = {
    "closed": {
        "name": "Cerrado",
        "color": "danger",
    },
    "active": {
        "name": "Abierto",
        "color": "success",
        "edit": true
    }
}

const ACTIVE = "active"
const PROCESS = "process"
const CANCELLED = "cancelled"
const INACTIVE = "inactive"

class EditPeriod extends Component {
    id = this.props.match.params.id;
    url_period = ""
    name_period = "Default Name"


    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            error: false,
            orders: null,
            modal: null,
            id: null,
            state: null,
            start: 0,
            end: 0,
            name: null,
            loads: null,
            total: 0,
            is_all_processed: false,
            orders_is_cancelled: 0,
            orders_selected: new Map(),
            cities_selected: new Map(),
            processing_order: false,
            viewWeb: true,
        }
    }

    componentDidMount() {
        this.loadPeriodComponent();
    }

    loadPeriodComponent = () => {
    }

    loadPeriods = () => {
    }

    render() {
        return (
            <Card border={this.getColorStatus("color")}>
                <Card.Header>
                    <small className="text-muted">Periodo ID: </small> <b>{this.id}</b> de {this.name_period} {" "}
                    {this.state.state ? (
                        <Badge bg={this.getColorStatus("color")}> {this.getColorStatus("name")}</Badge>) : null}
                    <ButtonGroup className={"float-end no-print-component"}>
                        {
                            this.state.viewWeb ? (
                                <Button size={"sm"} variant={"secondary"} onClick={() => window.print()}>
                                    <i><FiPrinter/></i> Imprimir
                                </Button>
                            ): null
                        }
                        {this.url_period === "purchase_periods" && this.state.ready && !this.state.error && this.state.orders?.length > 0 ? (
                            /*
                             <NavLink to={"/admin/print/period/purchases/"+this.id} className={"btn btn-primary btn-sm"} type={"button"}>
                                <i><FiPrinter/></i> Imprimir Compras
                            </NavLink>
                             */
                            this.state.viewWeb ? (
                                <Button size={"sm"} variant={"outline-danger"} onClick={() => this.viewPDF()}>
                                    <i><AiOutlineFilePdf/></i> Ver PDF
                                </Button>
                            ): (
                                <Button size={"sm"} variant={"primary"} onClick={() => this.viewWeb()}>
                                    <i><BsTable/></i> Ver {this.name_period}
                                </Button>
                                )
                        ) : null}
                        <NavLink to={"/admin/" + this.url_period} className={"btn btn-sm btn-info"} type={"button"}>
                            <i><FiCalendar/></i> Ver todos los periodos
                        </NavLink>
                    </ButtonGroup>
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.ready ? (
                        <> {this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.loadPeriod()} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            this.state.viewWeb ? (
                                this.viewPeriodWithOrders(this.state.orders)
                            ) : (
                                <PDFViewer style={{ width: "100%", height: "90vh" }}>
                                    {this.viewPeriodWithOrdersPDF(this.state.orders)}
                                </PDFViewer>
                            )
                        )}
                        </>
                    ) : (
                        <>
                            <Placeholder as={Card.Title} animation={"glow"}>
                                <Placeholder xs={10} bg={"success"}/>
                                <Placeholder xs={9}/>
                                <Placeholder xs={10} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation="glow">
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                                <Placeholder xs={12} size={"lg"} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Text} animation={"glow"}>
                                <Placeholder xs={12} bg={"success"}/>
                                <Placeholder xs={12} bg={"primary"}/>
                                <Placeholder xs={12} bg={"success"}/>
                            </Placeholder>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                                <Placeholder xs={1} size={"lg"} bg={"light"}/>
                                <Placeholder xs={5} size={"lg"} bg={"success"}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
            </Card>
        )
    }

    loadPeriod = () => {
        this.props.loadGet("/" + this.url_period + "/" + this.id, this.loadContent, this.loadError)
    }

    loadOrders = () => {
    }

    loadContent = (data) => {
        this.setState({
            state: data['status'],
            start: data['open_date'],
            end: data['close_date'],
            name: data['name'],
        });
        this.loadOtherComponentAfterPeriod();
    }

    loadContentPeriods = (data) => {
        if (data) {
            this.setState({
                loads: data
            });
        }
    }

    loadOtherComponentAfterPeriod = () => {
    }

    getColorStatusOrder(order) {
        return "table-" + this.loadColor(order);
    }

    viewWeb = () => {
        this.setState({
            viewWeb: true,
        })
    }

    viewPDF = () => {
        this.setState({
            viewWeb: false,
        });
    }

    loadColor = (order) => {
        if (order.status === ACTIVE) {
            return "info"
        }
        if (order.status === PROCESS) {
            return "warning"
        }
        if (order.status === CANCELLED) {
            return "danger"
        }
        if (order.status === INACTIVE) {
            return "secondary"
        }
        return "secondary"
    }

    loadContentOrders = (data) => {
        this.setState({
            ready: true,
            orders: data ? data : [],
        });
        let sumaVariable = 0
        let is_all_processed = true
        let orders_is_cancelled = 0

        for (let order of data) {
            if (order.status !== "cancelled") {
                if (this.url_period === "loads") {
                    sumaVariable += order.weight;
                } else {
                    sumaVariable += order.total;
                }
            } else {
                orders_is_cancelled += 1;
            }
            if (order.status === ACTIVE && this.url_period !== "sale_periods") {
                is_all_processed = false
            }
        }
        this.setState({
            total: sumaVariable,
            is_all_processed: is_all_processed,
            orders_is_cancelled
        });
    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        });
    }

    viewPeriodWithOrders = (data) => {
        if (data && data.length > 0) {
            return (
                <div className={"print-component"}>
                    {this.paintPeriodHeader()}
                    <h4 className={"print-component"}>Listado de Ordenes</h4>
                    <Table striped hover size={"sm"} responsive bordered className={"print-component"}>
                        <thead>
                        <tr className={"text-center"}>
                            <td></td>
                            <th>id</th>
                            <th>Fecha</th>
                            <th>Cliente</th>
                            <th>Ciudad</th>
                            {this.url_period !== "loads" ? (
                                <th>Cargue</th>
                            ) : null}
                            <th>Asesor</th>
                            <th>Estado</th>
                            <th>Total</th>
                            <th className={"no-print-component"}></th>
                        </tr>
                        </thead>
                        {this.paintRowsOrders(data)}
                    </Table>
                    {this.url_period === "loads" ? (
                        <>
                            <h4 className={"print-component"}>Pesos por Ciudad:</h4>
                            <Table striped hover size={"sm"} responsive bordered className={"print-component"}>
                                <thead>
                                <tr className={"text-center"}>
                                    <td></td>
                                    <th>Ciudad</th>
                                    <th>Ordenes</th>
                                    <th>Peso</th>
                                </tr>
                                </thead>
                                {this.paintCitiesGroups(data)}
                            </Table>
                        </>
                    ) : null}
                    <h4 className={"print-component"}>Ventas por Asesor:</h4>
                    <Table striped hover size={"sm"} responsive bordered className={"print-component"}>
                        <thead>
                        <tr className={"text-center"}>
                            <th>Asesor</th>
                            <th>N. Ordenes</th>
                            <th>Total Ventas</th>
                        </tr>
                        </thead>
                        {this.paintVendorsGroups(data)}
                    </Table>
                </div>
            )
        } else {
            return (
                <h3>No hay ordenes para este periodo</h3>
            )
        }
    }

    viewPeriodWithOrdersPDF = (orders) => {
        return undefined;
    }

    paintRowsOrders = (data) => {
        const rows = data.map((order, key) =>
            <tr key={key}
            >
                <td>
                    <FormCheck onChange={
                        (event) => {
                            if (event.target.checked) {
                                let orders_selected = this.state.orders_selected;
                                orders_selected.set(key, order);
                                this.setState({
                                    orders_selected: orders_selected
                                });
                            } else {
                                let orders_selected = this.state.orders_selected;
                                orders_selected.delete(key);
                                this.setState({
                                    orders_selected: orders_selected
                                });
                            }
                        }}
                    />
                </td>
                <td className={"text-center"}>
                    <NavLink to={"/admin/edit/orders/" + order.id}>
                        <Badge bg={this.loadColor(order)}>
                            {order.id}
                        </Badge>
                    </NavLink>
                </td>
                <td className={"text-center"}>{loadDate(order.date)}</td>
                <td>
                    {capitalizeString(order.client.name)}
                    {order.from_store ? (
                        <Badge bg={"primary"}> Tienda </Badge>
                    ) : null}
                </td>
                <td>{order.client.city} ({order.client.department})</td>
                {this.url_period !== "loads" ? (
                    <td>
                        {order.status === ACTIVE && this.url_period === "sale_periods" ?
                            this.state.loads ? (
                                <FormSelect
                                    name="load_id"
                                    defaultValue={order.load_id}
                                    onChange={(event) =>
                                        this.changeLoadOrder(event.target.value, order)
                                    }
                                    disabled={(!(order.status === ACTIVE || order.status === PROCESS))}
                                >
                                    {this.getLoads()}
                                </FormSelect>
                            ) : (
                                <Spinner animation={"border"}/>
                            )
                            : order.load}
                    </td>
                ) : null}
                <td>{capitalizeString(order.advisory)}</td>
                <td className={"text-center"}>
                    <Badge bg={this.loadColor(order)}>
                        {order.status}
                    </Badge>
                </td>
                {this.url_period === "loads" ? (
                    <td style={{textAlign: 'right'}}>{formatNumberString(parseInt(order.weight))}Kg</td>
                ) : (
                    <td style={{textAlign: 'right'}}>${formatNumberString(parseInt(order.total))}</td>
                )}

                <td className={"text-center no-print-component"}>
                    <Dropdown as={ButtonGroup} drop={"down"}>
                        <NavLink to={"/admin/edit/orders/" + order.id}
                                 className={"btn btn-primary btn-sm"}
                        >
                            {order.status === CANCELLED || order.status === INACTIVE ? (
                                <>Ver</>
                            ) : (
                                <>Editar</>
                            )}
                        </NavLink>
                        <Dropdown.Toggle split id={"dropdown-split-orders-" + key} size={"sm"}/>
                        <Dropdown.Menu>
                            {order.load ? (
                                <>
                                    <NavLink to={"/admin/print/orders/" + order.id + "/load"}
                                             className={"dropdown-item"}
                                             onClick={() => this.changeStatusProcess(order)}
                                    >
                                        {order.load && (order.status === ACTIVE || order.status === PROCESS) ? (
                                            <><i><FiPackage/></i> Alistar</>
                                        ) : null}
                                        {order.load && !(order.status === ACTIVE || order.status === PROCESS) ? (
                                            <><i><FiPrinter/></i> Imprimir Cargue</>
                                        ) : null}
                                    </NavLink>
                                    <Dropdown.Divider/>
                                </>
                            ) : null}
                            <NavLink to={"/admin/print/orders/" + order.id + "/order"}
                                     className={"dropdown-item"}
                            >
                                <><i><FiPrinter/></i> Imprimir Pedido</>
                            </NavLink>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        )
        return (
            <tbody>
            {rows}
            {this.state.orders_selected.size > 0 ? (
                <tr>
                    <td></td>
                    <td colSpan={3}>{this.state.orders_selected.size} ordenes seleccionadas</td>
                    {this.url_period === "loads" ? (
                        <>
                            <th colSpan={2} style={{textAlign: 'right'}}>Total:</th>
                            <th colSpan={3} style={{textAlign: 'right'}}>{formatNumberString(this.calculateTotal())}Kg
                            </th>
                        </>
                    ) : (
                        <>
                            <th colSpan={3} style={{textAlign: 'right'}}>Total:</th>
                            <th colSpan={3}
                                style={{textAlign: 'right'}}>${formatNumberString(parseInt(this.calculateTotal()))}</th>
                        </>
                    )}
                </tr>
            ) : null}
            </tbody>
        )
    }

    paintCitiesGroups = (data) => {
        let cities_weight = new Map();
        let cities_orders = new Map();
        data.map((pedido) => {
            let key = pedido.client.department + "_" + pedido.client.city;
            if (cities_weight.has(key)) {
                cities_weight.set(key, cities_weight.get(key) + pedido.weight);
                cities_orders.set(key, cities_orders.get(key) + ", " + pedido.id);
            } else {
                cities_weight.set(key, pedido.weight);
                cities_orders.set(key, pedido.id);
            }
        });
        let rows = []
        cities_weight.forEach((weight, city_key) => {
            rows.push(
                <tr>
                    <td>
                        <FormCheck onChange={
                            (event) => {
                                if (event.target.checked) {
                                    let cities_selected = this.state.cities_selected;
                                    cities_selected.set(city_key, weight);
                                    this.setState({
                                        cities_selected: cities_selected
                                    });
                                } else {
                                    let cities_selected = this.state.cities_selected;
                                    cities_selected.delete(city_key);
                                    this.setState({
                                        cities_selected: cities_selected
                                    });
                                }
                            }}
                        />
                    </td>
                    <td>
                        {city_key}
                    </td>
                    <td>
                        {cities_orders.get(city_key)}
                    </td>
                    <td style={{textAlign: 'right'}}>
                        {formatNumberString(parseInt(weight))}Kg
                    </td>
                </tr>
            )
        });
        return <tbody>
        {rows}
        {this.state.cities_selected.size > 0 ? (
            <tr>
                <td></td>
                <td>{this.state.cities_selected.size} ciudades seleccionadas</td>
                <th style={{textAlign: 'right'}}>Total:</th>
                <th style={{textAlign: 'right'}}>{formatNumberString(this.calculateTotalByCities())}Kg</th>
            </tr>
        ) : null}
        </tbody>
    }

    paintVendorsGroups = (data) => {
        let vendors_totals = new Map();
        let vendors_orders = new Map();
        let total = 0
        data.map((pedido) => {
            let key = pedido.advisory ? pedido.advisory : "NA";
            total += pedido.total;
            if (vendors_totals.has(key)) {
                vendors_totals.set(key, vendors_totals.get(key) + pedido.total);
                vendors_orders.set(key, vendors_orders.get(key) + 1);
            } else {
                vendors_totals.set(key, pedido.total);
                vendors_orders.set(key, 1);
            }
        });
        let rows = []
        vendors_totals.forEach((total, key) => {
            rows.push(
                <tr>
                    <td>
                        {key}
                    </td>
                    <td className={"text-center"}>
                        {vendors_orders.get(key)}
                    </td>
                    <td style={{textAlign: 'right'}}>
                        ${formatNumberString(parseInt(total))}
                    </td>
                </tr>
            )
        });
        return <tbody>
        {rows}
        <tr>
            <td></td>
            <th style={{textAlign: 'right'}}>Total:</th>
            <th style={{textAlign: 'right'}}>${formatNumberString(parseInt(total))}</th>
        </tr>
        </tbody>
    }

    getColorStatus = (property) => {
        let status_tem = status[this.state.state]
        if (status_tem) {
            return status_tem[property]
        }
        return ""
    }

    getLoads = () => {
        let loads = []
        loads.push(<option value=""></option>)
        for (let load in this.state.loads) {
            let option = this.state.loads[load]
            loads.push(<option key={load} value={option.id}>{option.name}</option>)
        }
        return loads;
    }

    changeLoadOrder = (value, order) => {
        let load = ""
        for (let i in this.state.loads) {
            let load1 = this.state.loads[i]
            if (load1.id === value) {
                load = load1.name
            }
        }
        if (value === "") {
            order.status = "active";
        }
        order.load = load;
        order.load_id = value;
        this.setOrder(order);
    }

    setOrder = (order) => {
        this.props.loadPut("/orders/" + order.id, order, this.successSaveOrderExpress, this.loadError)
    }

    successSaveOrderExpress = (order) => {
        this.loadOrders();
    }

    paintPeriodHeader = () => {
        return (
            <div>
                <h3 className={"text-center print-component"}>{this.state.name}</h3>
                <Row className={"p-3 no-print-component"}>
                    <FormGroup as={Col} md={3} controlId={"validationIdentification"}>
                        <FormLabel>Fecha de Apertura</FormLabel>
                        <FormControl readOnly value={loadDate(this.state.start)}
                                     className={"text-center"}/>
                    </FormGroup>

                    <FormGroup as={Col} md={3} controlId={"validationIdentification"}>
                        <FormLabel>Fecha de Cierre</FormLabel>
                        {this.state.end ? (
                            <FormControl readOnly value={loadDate(this.state.end)}
                                         className={"text-center"}/>
                        ) : (
                            <Row>
                                <Button variant={"outline-danger"}
                                        onClick={() => this.closePeriod()}
                                        disabled={!this.state.is_all_processed}
                                >
                                    <i><FiX/></i> Cerrar Periodo
                                </Button>
                            </Row>
                        )}
                    </FormGroup>
                    <FormGroup as={Col} md={3} controlId={"validationIdentification"}>
                        <FormLabel>Total Ordenes</FormLabel>
                        {this.state.orders_is_cancelled > 0 ? (
                            <FormControl
                                readOnly
                                value={
                                    formatNumberString(this.state.orders.length - this.state.orders_is_cancelled) + "/" +
                                    formatNumberString(this.state.orders.length)
                                }
                                style={{textAlign: 'right'}}
                            />
                        ) : (
                            <FormControl readOnly value={formatNumberString(this.state.orders.length)}
                                         style={{textAlign: 'right'}}
                            />
                        )}
                    </FormGroup>
                    {this.url_period === "loads" ? (
                        <FormGroup as={Col} md={3} controlId={"validationIdentification"}>
                            <FormLabel>Total Peso</FormLabel>
                            <FormControl readOnly value={formatNumberString(this.state.total) + "Kg"}
                                         style={{textAlign: 'right'}}
                            />
                        </FormGroup>
                    ) : (
                        <FormGroup as={Col} md={3} controlId={"validationIdentification"}>
                            <FormLabel>Total</FormLabel>
                            <FormControl readOnly value={"$" + formatNumberString(parseInt(this.state.total))}
                                         style={{textAlign: 'right'}}
                            />
                        </FormGroup>
                    )}
                </Row>
                {this.url_period !== "purchase_periods" ? (
                    <Alert variant={"info"} className={"no-print-component"}>Seleccionar las ordenes para sumar</Alert>
                ) : null}
            </div>
        )
    }

    closePeriod = () => {
        let title_var = "Quiere cerrar el periodo " + (this.state.name) + "?"
        this.setAlert(
            <SweetAlert
                warning
                title={title_var}
                onConfirm={() => this.loadClosePeriod()}
                onCancel={() => this.setAlert(null)}
                showCancel
                confirmBtnBsStyle="warning"
                confirmBtnText="Cerrar"
            >

            </SweetAlert>
        )
    }

    loadClosePeriod = () => {
        if (this.url_period !== "sale_periods" && this.state.orders && this.state.orders.length > 0 && this.state.is_all_processed) {
            let i = 0;
            let order = this.state.orders[i];
            let length = this.state.orders.length;
            while (order.status !== PROCESS && i < length) {
                order = this.state.orders[i];
                i++
            }
            if (i < length) {
                this.setAlertingSavingOrder(order.id)
                this.closeOrdersWithStatusProcess(order.id, i);
            } else {
                this.setAlertSaving();
                this.closePeriodPut(this.id)
            }
        } else {
            this.setAlertSaving();
            this.closePeriodPut(this.id)
        }
    }

    closeOrdersWithStatusProcess = (id, i) => {
    }

    loadSuccessPutOrder = (data, i) => {
        this.setAlertSavedOrder(data);
        i = i + 1;
        let length = this.state.orders.length;
        if (i < length) {
            setTimeout(() => {
                let order = this.state.orders[i];
                while (order.status !== PROCESS && i < length) {
                    order = this.state.orders[i];
                    i = i + 1
                }
                if (i < length) {
                    this.setAlertingSavingOrder(order.id)
                    this.closeOrdersWithStatusProcess(order.id, i);
                } else {
                    this.setAlertSaving();
                    this.closePeriodPut(this.id)
                }
            }, 1000);
        } else {
            setTimeout(() => {
                this.setAlertSaving();
                this.closePeriodPut(this.id)
            }, 2200);
        }
    }

    setAlertSaving = () => {
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos guardando el periodo en la base de datos
            </SweetAlert>
        );
    }


    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    closePeriodPut = (id, values) => {
    }

    loadSuccessPut = (period) => {
        if (period) {
            this.loadSuccessPostAlert()
            this.loadPeriodComponent();
        } else {
            this.loadErrorPost("Error al guardar el periodo")
        }
    }

    loadErrorPost = (onError) => {
        this.setState({ready_post: true})
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onError}
            </SweetAlert>
        )
    }

    loadSuccessPostAlert = () => {
        this.setAlert(
            <SweetAlert
                success
                title="Guardado!"
                showConfirm={false}
                timeout={2000}
                onConfirm={() => this.setAlert(null)}
            >
                Periodo Guardado
            </SweetAlert>
        );
    }

    changeStatusProcess = (order) => {
        if (order.status === "active") {
            order.status = "process";
            this.setOrder(order);
        }
    }

    setAlertingSavingOrder = (id) => {
        this.setAlert(
            <SweetAlert
                custom
                title="Guardando!"
                showConfirm={false}
                closeOnClickOutside={false}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos cerrando la orden {id}
            </SweetAlert>
        );
    }

    setAlertSavedOrder = (data) => {
        this.setAlert(
            <SweetAlert
                success
                title="Guardado!"
                showConfirm={false}
                timeout={2000}
                onConfirm={() => this.setAlert(null)}
            >
                Se cerro la orden {data.id}
            </SweetAlert>
        );
    }

    calculateTotal = () => {
        let orders = this.state.orders_selected;
        let total = 0;
        if (this.url_period === "loads") {
            for (const order of orders.entries()) {
                total += order[1].weight;
            }
        } else {
            for (const order of orders.entries()) {
                total += order[1].total;
            }
        }
        return total;
    }

    calculateTotalByCities = () => {
        let citiesSelected = this.state.cities_selected;
        let total = 0;
        for (const city_entrie of citiesSelected.entries()) {
            total += city_entrie[1];
        }
        return total;
    }
}

export default EditPeriod;