import React, {Component} from "react";
import {
    Alert, Button,
    FormControl,
    FormFloating, ListGroup,
    Modal,
    Placeholder
} from "react-bootstrap";
import * as Yup from "yup";

import {Formik, Form} from 'formik';
import utilizeFocus from "../../util/focusElement";


const schema = Yup.object().shape({
    q: Yup.string()
});

class ModalSearchProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: true,
            error: false,
            products: null,
            query: '',
        }
        this.inputProducts = []
    }

    render() {
        return (
            <Modal
                {...this.props}
                size={"xl"}
                aria-labelledby="contained-modal-title-vcenter"
                //centered
            >
                <Modal.Header //closeButton
                />
                <Modal.Body>
                    <Formik
                        initialValues={{
                            q: ''
                        }}
                        onSubmit={values => {
                            this.searchProducts(values)
                        }}
                        validationSchema={schema}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormFloating className={"mb-3"}>
                                    <FormControl
                                        type={"text"}
                                        id={"query"}
                                        placeholder={"Buscar productos"}
                                        name={"q"}
                                        onChange={handleChange}
                                        value={values.q}
                                        ref={this.props.focusSearch.ref}
                                    />
                                    <label htmlFor={"query"}>Buscar productos</label>
                                </FormFloating>
                            </Form>
                        )}
                    </Formik>
                    {this.state.ready ? (
                        <>
                            {
                                this.state.error ? (
                                    <Alert variant={"danger"}>
                                        <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                        <p>
                                            {this.state.error}
                                        </p>
                                        <hr/>
                                        <Button onClick={() => this.searchProducts(this.state.query)} variant={"dark"}>
                                            Intentar nuevamente
                                        </Button>
                                    </Alert>
                                ) : (
                                    <>
                                        {this.state.products}
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={11}/>
                                <Placeholder xs={10}/>
                                <Placeholder xs={9}/>
                                <Placeholder xs={10}/>
                            </Placeholder>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        );
    }

    searchProducts = (values) => {
        this.setState({
            query: values
        })
        let query = values.q.toUpperCase();
        if (query) {
            this.setState({
                ready: false
            });
            this.props.loadGet("/products?q=" + query, this.loadContent, this.loadError)
        } else {
            this.setState({
                ready: true,
                products: null,
                error: false
            })
        }
    }

    loadContent = (data) => {
        let isData = false;
        this.inputProducts = []
        for (const dataKey in data) {
            this.inputProducts.push(utilizeFocus())
            isData = true;
        }
        this.setState({
            ready: true,
            products: this.paintProducts(data)
        });

        if (isData) {
            setTimeout(
                () => this.inputProducts[0].setFocus(),
                300
            )
        }

    }

    loadError = (error) => {
        this.setState({
            ready: true,
            error: error
        })
    }

    paintProducts = (data) => {
        if (data && data.length > 0) {
            return (
                <div>
                    <h5>Se encontraron {data.length} resultados:</h5>
                    <ListGroup>
                        {this.paintProductsRows(data)}
                    </ListGroup>

                </div>
            )
        } else {
            return (
                <h5 className={"text-center"}>No se encontraron productos</h5>
            )
        }
    }

    paintProductsRows = (data) => {
        return data.map((product, key) =>
            <ListGroup.Item eventKey={key} key={key} action ref={this.inputProducts[key].ref}
                            onKeyPress={
                                (event) => {
                                    if (event.key === "Enter") {
                                        this.selectProduct(product)
                                    }
                                }
                            }
                            onClick={() => this.selectProduct(product)}
            >
                {product.code} {product.name}
            </ListGroup.Item>
        )
    }

    selectProduct = (product) => {
        this.props.onHide();
        this.props.setProductSelected(product);
    }
}

export default ModalSearchProduct;