import {Component} from "react";
import {Button, Card, CardImg, Row} from "react-bootstrap";
import {FiRotateCw} from "react-icons/fi";

class ImageSource extends Component{
    constructor(props) {
        super(props);
        this.state = {
            original_image: null
        }
    }

    componentDidMount() {
        if(this.props.preload){
            var img = new Image();
            img.src = this.props.image;
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = (() => {
                let canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL("image/png", 1);
                this.props.setBaseImage(dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
                this.props.setSourceImage(dataURL);
                this.setState({original_image: dataURL})
            });
        }
    }

    render() {
        return(
            <Card className={"shadow p-3 mb-3 bg-white rounded"} style={{marginBottom:'0px', alignItems:"center"}}>
                <CardImg variant={"top"}
                         src={this.props.image}
                         alt={this.props.name}
                         style={{height:'15rem', width: '15rem'}}
                />
                <Card.Body style={{padding:'0rem'}}>
                    <Row>
                        <input type="file" name={this.props.name}
                               style={{
                                   FontSize: 0+'%',
                                   position: "absolute",
                                   left: 0,
                                   bottom: 0,
                                   opacity: 0,
                                   width: 100+'%',
                                   height: 100+"%"
                               }} onChange={event => this.saveLocalFile(event)}
                               accept={"image/jpeg,image/jpg,image/png"} capture/>
                        <Button variant={"secondary"}>
                            Seleccionar Imagen
                        </Button>

                        <Button variant={"outline-info info"}
                                //id={this.props.name.replace(/ /g, "")}
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    width:'50px',
                                }}
                                onClick={() => this.rotateImage()}
                                disabled={true}
                        >
                            <i><FiRotateCw/></i>
                        </Button>
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    rotateImage = () => {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext("2d");
        var image = new Image();
        image.src = this.state.original_image;
        let cw = image.height;
        let ch = image.width;
        let cy = image.height * (-1);
        image.onload = () => {
            canvas.setAttribute('width', cw);
            canvas.setAttribute('height', ch);
            ctx.rotate(90 * Math.PI / 180);
            ctx.drawImage(image, 0, cy);
            let dataURL = canvas.toDataURL("image/png", 1);
            //this.props.setSourceImage(dataURL);
            this.setState({original_image: dataURL})
            this.resizeImage(dataURL)
        };
    }

    saveLocalFile = (event)  => {
        let reader = new FileReader();
        let image = event.target.files[0];
        reader.onload = () => {
            let base64Image = reader.result;
            this.setState({original_image: base64Image});
            this.props.setSourceImage(base64Image);
            this.resizeImage(base64Image)
        }
        if(image){
            reader.readAsDataURL(image);
        }
    }

    resizeImage = (base64Image) => {
        let tam_liv = 512;
        let img = new Image();
        img.src = base64Image;
        img.onload = () =>{
            let width =  img.width;
            let height = img.height;
            let tam = this.props.size ? this.props.size: tam_liv;
            if(img.width> tam){
                let porc = tam/width;
                height = porc * height;
                width = tam;
            }
            let canvas = document.createElement('canvas')
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            let dataURL = canvas.toDataURL("image/png", 1);
            this.props.setBaseImage(dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
        }
    }
}
export default ImageSource