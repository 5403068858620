import Migration from "./Migration";

const PROCESSING = "PROCESSING"

class MigrationsProducts extends Migration {
    headersBefore = ["id", "codigo", "nombre", "peso", "proveedor", "imp", "iva", "fecha", "costo", "lista1", "lista2",
        "lista3", "lista4", "cantidad"]
    headerAfter = ["name", "code", "weight", "cost", "iva", "iva_mandatory", "amount", "providers"]
    url = "/products"
    enable = false
    name = "Products"

    componentDidMount() {
        this.loadProviders()
    }

    processArrayData = () => {
        let arrayData = this.state.array;
        let localData = [];
        arrayData.map(row => {
            if (row.id) {
                const object = {
                    "name": row.nombre.trim(),
                    "code": row.codigo.trim(),
                    "weight": row.peso.trim(),
                    "cost": row.costo.trim(),
                    "iva": row.iva.trim(),
                    "iva_mandatory": row.imp.trim() === "OBLIGATORIO"?"true":"false",
                    "amount": row.cantidad.trim(),
                    "providers": this.searchProvider(row.proveedor.trim()),
                }
                localData.push(object);
            }
        });
        this.setState({data: localData, status: PROCESSING});
    }

    searchProvider = (identification) => {
        for (const provider of this.state.providers) {
            if(provider.identification === identification) {
                return [{"id": provider.id}]
            }
        }
        return []
    }

    loadProviders = () => {
        this.props.loadGet("/providers", this.loadContent, this.loadError);

    }
    loadContent = (data) => {
        this.setState({
            providers: data
        })
    }

    loadError = (error) => {
        this.setState({
            providers: []
        })
    }
}

export default MigrationsProducts