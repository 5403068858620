import React, {Component} from "react";
import {
    Accordion,
    Alert,
    Button,
    Card,
    Col,
    FormControl,
    FormFloating,
    Placeholder,
    Row,
    Spinner
} from "react-bootstrap";
import * as Yup from "yup";

import {Formik, Form} from 'formik';
import SweetAlert from "react-bootstrap-sweetalert";
import {Redirect} from "react-router-dom";

const schema = Yup.object().shape({
    q: Yup.string()
});

class NewPurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: true,
            error: false,
            providers: null,
            modal: null,
            query: '',
            content: null
        }
    }

    render() {
        return (
            <Card>
                <Card.Header>
                    Crear Compra
                </Card.Header>
                <Card.Body>
                    {this.state.modal}
                    {this.state.content}
                    <Formik
                        initialValues={{
                            q: ''
                        }}
                        onSubmit={values => {
                            this.searchProvidersForPurchase(values)
                        }}
                        validationSchema={schema}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormFloating className={"mb-3"}>
                                    <FormControl
                                        type={"text"}
                                        id={"query"}
                                        placeholder={"Buscar proveedor"}
                                        name={"q"}
                                        onChange={handleChange}
                                        value={values.q}
                                    />
                                    <label htmlFor={"query"}>Buscar proveedor</label>
                                </FormFloating>
                            </Form>
                        )}
                    </Formik>
                    {this.state.ready ? (
                        <>{this.state.error ? (
                            <Alert variant={"danger"}>
                                <Alert.Heading>Ha ocurrido un error</Alert.Heading>
                                <p>
                                    {this.state.error}
                                </p>
                                <hr/>
                                <Button onClick={() => this.searchProvidersForPurchase(this.state.query)} variant={"dark"}>
                                    Intentar nuevamente
                                </Button>
                            </Alert>
                        ) : (
                            <>{this.state.providers}</>
                        )}</>
                    ) : (
                        <>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={11}/>
                                <Placeholder xs={10}/>
                                <Placeholder xs={9}/>
                                <Placeholder xs={10}/>
                            </Placeholder>
                        </>
                    )}
                </Card.Body>
                <Card.Footer/>
            </Card>
        );
    }

    searchProvidersForPurchase = (values) => {
        this.setState({
            query: values,
        });
        let query = values.q.toUpperCase();
        if (query) {
            this.setState({
                ready: false
            });
            this.props.loadGet("/providers?q=" + query, this.loadContent, this.loadError)
        } else {
            this.setState({
                ready: true,
                providers: null,
                error: false
            })
        }
    }

    loadContent = (data) => {
        this.setState({
            ready: true,
            providers: this.paintProviders(data)
        })
    }

    paintProviders = (data) => {
        if (data && data.length > 0) {
            return (
                <div>
                    <h5>Se encontraron {data.length} resultados:</h5>
                    {this.paintProvidersRows(data)}
                </div>
            )
        } else {
            return (
                <h5 className={"text-center"}>No se encontraron resultados para crear la orden</h5>
            )
        }
    }

    paintProvidersRows = (data) => {
        const rows = data.map((provider, key) => (
            <Accordion.Item eventKey={key} key={key}>
                <Accordion.Header>
                    <small className="text-muted">[{provider.identification}] </small> {provider.name}
                </Accordion.Header>
                <Accordion.Body>
                    <Row className={"mb-3"}>
                        <Col md={"4"}><small className="text-muted"> Departamento:</small> {provider.department}</Col>
                        <Col md={"4"}><small className="text-muted"> Municipio:</small> {provider.city}</Col>
                        <Col md={"4"}><small className="text-muted"> Telefono:</small> {provider.phone}</Col>
                        <Col md={"6"}><small className="text-muted"> Dirección:</small> {provider.address}</Col>
                        <Col md={"6"}><small
                            className="text-muted"> Establecimiento:</small> {provider.establishment_name}</Col>
                    </Row>
                    <Row>
                        <Button variant={"outline-success"} onClick={() => this.createPurchase(provider)}>Crear compra
                            para {provider.name}</Button>
                    </Row>

                </Accordion.Body>
            </Accordion.Item>
        ));
        return (
            <Accordion>{rows}</Accordion>
        )
    }

    createPurchase = (provider) => {
        this.setAlert(
            <SweetAlert
                custom
                title="Seleccionando Proveedor!"
                showConfirm={false}
                closeOnClickOutside={false}
                onConfirm={() => {
                }}
                customIcon={(
                    <div className={"text-center"}>
                        <Spinner animation={"border"} variant={"success"}/>
                    </div>
                )}
            >
                Estamos preparando una nueva order de compra para {provider.name}
            </SweetAlert>
        );
        let values = {
            "provider": provider
        }
        this.props.loadPost("/purchases", values, this.redirectToEdit, this.loadError);
    }

    setAlert = (param) => {
        this.setState({
            modal: param
        })
    }

    redirectToEdit = (purchase) => {
        if(purchase) {
            this.setState({
                content: (<Redirect to={"/admin/edit/purchases/" + purchase.id}/>)
            });
        } else {
            this.loadError("Error al crear la orden")
        }
    }

    loadError = (onerror) => {
        this.setState({
            ready: true,
            error: onerror
        })
        this.setAlert(
            <SweetAlert
                danger
                title="Error!"
                onConfirm={() => this.setAlert(null)}
            >
                {onerror}
            </SweetAlert>
        )
    }
}

export default NewPurchase;