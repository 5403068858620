import Periods from "./Periods";

class PurchasesPeriods extends Periods {
    classPeriod = "purchase_periods";
    description = "En los periodos de compras solo se muestran las ordenes de compras asocidas a cada peridodo, cada compra " +
        "solo puede estar asociada a un perido de compras";
    loadTablePeriods = () => {
        this.props.loadGet("/purchase_periods", this.loadContent, this.loadError)
    }

    postPeriod = (values) => {
        this.props.loadPost("/purchase_periods", values, this.loadSuccessPost, this.loadErrorPost);
    }

    putPeriod = (id, values) => {
        this.props.loadPut("/purchase_periods/" + id, values, this.loadSuccessPut, this.loadErrorPost);
    }

    closePeriodPut = (id, values) => {
        this.props.loadPut("/purchase_periods/" + id + "/close", values, this.loadSuccessPut, this.loadErrorPost);
    }
    deletePeriod = (id) => {
        this.props.loadDelete("/purchase_periods/" + id, this.loadSuccessDelete, this.loadErrorPost);
    }
}

export default PurchasesPeriods;